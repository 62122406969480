import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { fetchUserById, fetchUserMe, followUser, unfollowUser, updateUser, fetchSounds, fetchUsers } from '../../api/APIManager';
import Spinner from '../../components/Spinner';
import { DEFAULT_USER_IMAGE } from '../../api/APIConfig';
import { VerifiedBadge } from '../../assets/Icons';
import EditableAvatar from './EditableAvatar';
import UserListDialog from './UserListDialog';
import SoundBrowser from '../../components/SoundBrowser/SoundBrowser';
import EditProfileDialog from './EditProfileDialog';
import SoundPacksList from '../../components/SoundPackBrowser/SoundPacksList';
import Button from '../../components/Button';
import ProfileHeader from './ProfileHeader';

const TabButton = ({ isActive, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-6 py-3 font-medium transition-all duration-200 border-b-2 text-text-secondary ${
      isActive 
        ? 'border-accent-end' 
        : 'border-transparent hover:text-text-primary hover:border-white/20'
    }`}
  >
    {children}
  </button>
);

const UserProfile = () => {
  const { userId } = useParams();
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('sounds');
  const [profileUser, setProfileUser] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [totalSounds, setTotalSounds] = useState(0);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isFollowersDialogOpen, setIsFollowersDialogOpen] = useState(false);
  const [isFollowingDialogOpen, setIsFollowingDialogOpen] = useState(false);

  const [editFormData, setEditFormData] = useState({
    username: '',
    email: '',
    verified: false,
    is_soundbank_user: false,
    whitelisted: false,
    featured: false,
    instagram: '',
    twitter: '',
    tiktok: '',
    website: '',
    location: '',
    bio: ''
  });
  const [updateError, setUpdateError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const isAdmin = user?.admin;
  const isOwnProfile = userId === 'me' || !userId;

  const loadUserData = useCallback(async () => {
    setIsLoadingUser(true);
    setProfileUser(null);
    try {
      let userData;
      if (userId === 'me' || !userId) {
        userData = await fetchUserMe();
      } else {
        userData = await fetchUserById(userId);
      }
      
      if (!userData) {
        throw new Error('Failed to load user data');
      }
      
      setProfileUser(userData);
      setEditFormData({
        username: userData.username,
        email: userData.email,
        verified: userData.verified || false,
        is_soundbank_user: userData.is_soundbank_user || false,
        whitelisted: userData.whitelisted || false,
        featured: userData.featured || false,
        instagram: userData.instagram,
        twitter: userData.twitter,
        tiktok: userData.tiktok,
        website: userData.website,
        location: userData.location || '',
        bio: userData.bio,
      });

      const soundsResponse = await fetchSounds(1, 2, { owner: userData.id });
      setTotalSounds(soundsResponse.total);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoadingUser(false);
    }
  }, [userId, user.id]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  const handleAvatarChange = async (file) => {
    if (!file) return;
    
    setIsUploadingAvatar(true);
    try {
      const updatedUser = await updateUser(profileUser.id, {
        avatarFile: file
      });
      
      if (updatedUser) {
        setProfileUser(updatedUser);
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
    } finally {
      setIsUploadingAvatar(false);
    }
  };

  const handleFollow = async () => {
    try {
      await followUser(profileUser.id);
      setProfileUser(prev => ({
        ...prev,
        followers_count: prev.followers_count + 1,
        is_followed: true
      }));
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  const handleUnfollow = async () => {
    try {
      await unfollowUser(profileUser.id);
      setProfileUser(prev => ({
        ...prev,
        followers_count: prev.followers_count - 1,
        is_followed: false
      }));
    } catch (error) {
      console.error('Error unfollowing user:', error);
    }
  };

  const handleEditClick = () => {
    setEditFormData({
      username: profileUser.username,
      email: profileUser.email,
      verified: profileUser.verified || false,
      is_soundbank_user: profileUser.is_soundbank_user || false,
      whitelisted: profileUser.whitelisted || false,
      featured: profileUser.featured || false,
      instagram: profileUser.instagram,
      twitter: profileUser.twitter,
      tiktok: profileUser.tiktok,
      website: profileUser.website,
      location: profileUser.location || '',
      bio: profileUser.bio,
    });
    setIsEditDialogOpen(true);
  };

  const handleEditFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSaveChanges = async () => {
    setUpdateError('');
    setIsSaving(true);
    try {
      await updateUser(profileUser.id, editFormData);
      setProfileUser(prevUser => ({
        ...prevUser,
        ...editFormData
      }));
      setIsEditDialogOpen(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to update user profile. Please try again.';
      setUpdateError(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const SoundsTab = () => (
    <div className="rounded-lg">
      {(isOwnProfile || isAdmin) && (
        <div className="flex justify-end items-center gap-2 mb-4 mr-2">
          <Link to="/batch-upload">
            <Button variant="secondary" className="flex items-center justify-center w-10 h-10 !p-0 !rounded-full">
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
              </svg>
            </Button>
          </Link>
          <Link to="/batch-edit">
            <Button variant="secondary" className="inline-flex items-center gap-2">
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
              </svg>
              Batch Edit
            </Button>
          </Link>
        </div>
      )}
      <SoundBrowser
        additionalFilters={{ owner: profileUser.id, sort_by_likes: true }}
        title="Sounds"
        isExplorePage={false}
      />
    </div>
  );

  const SoundPacksTab = () => (
    <div className="rounded-lg">
      <SoundPacksList
        title="Sound Packs"
        filters={{ owner_id: profileUser.id }}
        showCreateButton={isOwnProfile}
      />
    </div>
  );


  if (isLoadingUser) {
    return (
      <div className="flex justify-center items-center min-h-[calc(100vh-64px)]">
        <Spinner />
      </div>
    );
  }

  if (!profileUser) {
    return <Navigate to="/404" />;
  }

  return (
    <div className="text-text-primary">
      <div className="max-w-full mx-auto">
        <ProfileHeader
          profileUser={profileUser}
          handleAvatarChange={handleAvatarChange}
          isUploadingAvatar={isUploadingAvatar}
          totalSounds={totalSounds}
          onFollowersClick={() => setIsFollowersDialogOpen(true)}
          onFollowingClick={() => setIsFollowingDialogOpen(true)}
          handleEditClick={handleEditClick}
          handleFollow={handleFollow}
          handleUnfollow={handleUnfollow}
          isAdmin={isAdmin}
          isOwnProfile={isOwnProfile}
        />

        {/* Tabs Navigation */}
        <div className="px-2 sm:px-6">
          <div className="border-b border-white/10">
            <div className="flex">
              <TabButton 
                isActive={activeTab === 'sounds'} 
                onClick={() => setActiveTab('sounds')}
              >
                Sounds
              </TabButton>
              <TabButton 
                isActive={activeTab === 'soundpacks'} 
                onClick={() => setActiveTab('soundpacks')}
              >
                Sound Packs
              </TabButton>
            </div>
          </div>
          
          {/* Tab Content */}
          <div className="py-6">
            {activeTab === 'sounds' ? <SoundsTab /> : 
             activeTab === 'soundpacks' ? <SoundPacksTab /> : 
             <div />}
          </div>
        </div>
      </div>
      
      {/* Dialogs */}
      <EditProfileDialog
        isOpen={isEditDialogOpen}
        onClose={() => {
          setIsEditDialogOpen(false);
          setUpdateError('');
        }}
        profileUser={profileUser}
        editFormData={editFormData}
        handleEditFormChange={handleEditFormChange}
        handleSaveChanges={handleSaveChanges}
        handleAvatarChange={handleAvatarChange}
        isUploadingAvatar={isUploadingAvatar}
        isSaving={isSaving}
        updateError={updateError}
        isAdmin={isAdmin}
      />
      
      <UserListDialog
        isOpen={isFollowersDialogOpen}
        onClose={() => setIsFollowersDialogOpen(false)}
        userId={profileUser.id}
        type="followers"
        initialCount={profileUser.followers_count}
      />

      <UserListDialog
        isOpen={isFollowingDialogOpen}
        onClose={() => setIsFollowingDialogOpen(false)}
        userId={profileUser.id}
        type="following"
        initialCount={profileUser.following_count}
      />
    </div>
  );
};

export default UserProfile;