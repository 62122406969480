import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, Play, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { fetchFeaturedSounds, fetchSoundpacks, fetchUsers, fetchSoundpackById } from '../../api/APIManager';
import { DEFAULT_SOUND_IMAGE, DEFAULT_USER_IMAGE } from '../../api/APIConfig';
import { VerifiedBadge } from '../../assets/Icons';
import { AnimatePresence } from 'framer-motion';
import Spinner from '../../components/Spinner';

const FadeImage = ({ src, alt, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  
  return (
    <div className="relative w-full h-full">
      {/* Loading placeholder */}
      <AnimatePresence>
        {!isLoaded && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-black/20"
          />
        )}
      </AnimatePresence>
      
      {/* Actual image */}
      <motion.img
        src={src}
        alt={alt}
        className={className}
        initial={{ opacity: 0 }}
        animate={{ opacity: isLoaded ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

const FeaturedSoundsCarousel = ({ onPlay, onFeaturedLoaded }) => {
  const navigate = useNavigate();
  const [featuredUsers, setFeaturedUsers] = useState([]);
  const [featuredUsersIndex, setFeaturedUsersIndex] = useState(0);
  const [featuredSounds, setFeaturedSounds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isPanning, setIsPanning] = useState(false);
  const [pauseTimeout, setPauseTimeout] = useState(null);
  const [touchTimeout, setTouchTimeout] = useState(null);
  const [activeSoundId, setActiveSoundId] = useState(null);

  const getCardsToShow = () => {
    if (typeof window === 'undefined') return 2;
    if (window.innerWidth >= 2080) return 5;
    if (window.innerWidth >= 1280) return 4;
    if (window.innerWidth >= 768) return 2.5;
    return 1.5;
  };

  const [cardsToShow, setCardsToShow] = useState(getCardsToShow());

  useEffect(() => {
    const handleResize = () => {
      setCardsToShow(getCardsToShow());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = (section) => {
    if (section === 'featured') {
      const lastFeaturedIndex = Math.max(0, featuredSounds.length - cardsToShow);
      setCurrentIndex(prev => prev >= lastFeaturedIndex ? 0 : prev + 1);
    } else if (section === 'featured-users') {
      const itemsPerView = Math.floor((window.innerWidth - 32) / 180);
      const maxIndex = Math.max(0, featuredUsers.length - itemsPerView);
      setFeaturedUsersIndex(prev => prev >= maxIndex ? 0 : prev + 1);
    }
  };

  const prevSlide = (section) => {
    if (section === 'featured') {
      const lastFeaturedIndex = Math.max(0, featuredSounds.length - cardsToShow);
      setCurrentIndex(prev => prev <= 0 ? lastFeaturedIndex : prev - 1);
    } else if (section === 'featured-users') {
      const itemsPerView = Math.floor((window.innerWidth - 32) / 180);
      const maxIndex = Math.max(0, featuredUsers.length - itemsPerView);
      setFeaturedUsersIndex(prev => prev <= 0 ? maxIndex : prev - 1);
    }
  };

  const handleProfileClick = useCallback((e, ownerId) => {
    e.stopPropagation();
    navigate(`/${ownerId}`);
  }, [navigate]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const [soundsResponse, featuredUsersResponse] = await Promise.all([
          fetchFeaturedSounds(),
          fetchUsers(1, 20, { featured: true })
        ]);

        if (soundsResponse?.items) setFeaturedSounds(soundsResponse.items);
        if (featuredUsersResponse?.items) setFeaturedUsers(featuredUsersResponse.items);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load content');
      } finally {
        setIsLoading(false);
        onFeaturedLoaded?.();
      }
    };
    loadData();
  }, [onFeaturedLoaded]);

  const handlePlayClick = useCallback((e, sound) => {
    e.stopPropagation();
    if (pauseTimeout) clearTimeout(pauseTimeout);
    setIsPlaying(true);
    onPlay(sound);
    const timeout = setTimeout(() => {
      setIsPlaying(false);
    }, 10000);
    setPauseTimeout(timeout);
  }, [onPlay, pauseTimeout]);

  const handlePanStart = () => {
    setIsDragging(true);
    setIsPanning(true);
  };

  const handleClick = (e, sound) => {
    if (!isPanning) {
      handlePlayClick(e, sound);
    }
    setIsPanning(false);
  };

  const handleTouchStart = (e, sound) => {
    if (touchTimeout) clearTimeout(touchTimeout);
    setActiveSoundId(sound.id);
  };

  const handleTouchEnd = () => {
    const timeout = setTimeout(() => {
      setActiveSoundId(null);
    }, 1000); // Keep visible for 1 second after touch
    setTouchTimeout(timeout);
  };

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const staggerChildren = {
    visible: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const renderFeaturedUsersCarousel = () => (
    <motion.div 
      initial="hidden"
      animate="visible"
      variants={staggerChildren}
      className="space-y-6 pt-8"
    >
      <motion.div variants={fadeInVariants} className="px-4">
        <motion.div variants={fadeInVariants} className="flex items-center gap-3 pt-6">
          <Users className="w-6 h-6 text-white" />
          <h2 className="text-2xl font-bold text-white tracking-tight">Featured Artists</h2>
        </motion.div>
      </motion.div>
      <div className="relative w-full">
        <div className="relative">
          <button
            onClick={() => prevSlide('featured-users')}
            className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <div className="w-full h-52 overflow-x-auto no-scrollbar">
            <motion.div
              className="flex gap-6 px-4"
              animate={{ x: `-${featuredUsersIndex * 180}px` }}
              transition={{ duration: 0.5, type: "spring", stiffness: 150, damping: 20 }}
            >
              {featuredUsers.map((user) => (
                <div
                  key={user.id}
                  className="flex-none w-40 h-40"
                >
                  <div 
                    onClick={() => navigate(`/${user.username}`)}
                    className="group relative w-full h-full rounded-full  overflow-hidden cursor-pointer hover:shadow-1xl transition-all duration-300"
                  >
                    <FadeImage
                      src={user.avatar || DEFAULT_USER_IMAGE}
                      alt={user.username}
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </div>
  
                  <div className="mt-4 text-center">
                    <button 
                      onClick={() => navigate(`/${user.username}`)}
                      className="font-semibold text-white truncate text-sm hover:text-accent-end transition-colors duration-300 flex items-center justify-center mx-auto"
                    >
                      {user.username}
                      {user.verified && <VerifiedBadge className="inline-block ml-1" />}
                    </button>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
  
          <button
            onClick={() => nextSlide('featured-users')}
            className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </motion.div>
  );
  

  if (isLoading) {
    return (
      <div className="w-full space-y-8">
        <div className="w-full h-[300px] sm:h-[400px] flex items-center justify-center">
          <Spinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full space-y-8">
        <div className="w-full h-[300px] sm:h-[400px] flex items-center justify-center">
          <p className="text-lg text-gray-400 bg-black/20 px-6 py-3 rounded-lg backdrop-blur-md shadow-lg">
            {error}
          </p>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      variants={staggerChildren}
      className="w-full pb-8"
    >
      <style jsx global>{`
        .no-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .no-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>

      {/* Featured Sounds Section */}
      <motion.div variants={fadeInVariants} className="space-y-6">
        <div className="relative w-full h-[280px] sm:h-[340px]">
          <div className="absolute inset-0">
            <div className="relative h-full w-full">
              {featuredSounds.length > cardsToShow && (
                <button
                  onClick={() => prevSlide('featured')}
                  className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
                >
                  <ChevronLeft className="w-5 h-5" />
                </button>
              )}
              
              <div className="w-full h-full overflow-x-auto no-scrollbar">
                <motion.div
                  onPanStart={handlePanStart}
                  onPanEnd={(event, info) => {
                    setIsDragging(false);
                    const swipeThreshold = 50;
                    if (Math.abs(info.offset.x) > swipeThreshold) {
                      info.offset.x > 0 ? prevSlide('featured') : nextSlide('featured');
                    }
                    setTimeout(() => setIsPanning(false), 100);
                  }}
                  className="flex gap-6 px-4 h-full"
                  animate={{ x: `-${currentIndex * (100 / cardsToShow)}%` }}
                  transition={{ duration: 0.5, type: "spring", stiffness: 150, damping: 20 }}
                  variants={staggerChildren}
                >
                  {featuredSounds.map((sound) => (
                    <motion.div
                      key={sound.id}
                      variants={fadeInVariants}
                      className="flex-none h-full md:w-[500px] w-[350px]"
                    >
                      <div 
                        className="group relative w-full h-full rounded-xl overflow-hidden cursor-pointer bg-black/20 shadow-2xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1"
                        onClick={(e) => handleClick(e, sound)}
                        onTouchStart={(e) => handleTouchStart(e, sound)}
                        onTouchEnd={handleTouchEnd}
                      >
                        <div className="absolute inset-0">
                          <img 
                            src={sound.image || sound.image2 || DEFAULT_SOUND_IMAGE}
                            alt={sound.name}
                            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                          />
                        </div>
                        <div className="absolute inset-0 bg-gradient-to-t from-black/100 via-black/60 to-transparent opacity-90 group-hover:opacity-100 transition-opacity duration-300" />

                        <div className={`absolute inset-0 p-6 flex flex-col justify-end transition-opacity duration-300`}>
                          <div className="flex justify-between items-end">
                            <h3 className="text-xl font-bold text-white line-clamp-1 mr-4 transition-colors duration-300">
                              {sound.name}
                            </h3>
                            <button
                              onClick={(e) => handlePlayClick(e, sound)}
                              className="flex-shrink-0 inline-flex items-center p-3 rounded-full border border-white/50 hover:border-none text-white/50 hover:bg-accent-end hover:text-white transform hover:scale-110 transition-all duration-300 shadow-2xl"
                            >
                              <Play className="w-5 h-5" />
                            </button>
                          </div>
                          <div className="flex items-center space-x-3 mt-4">
                            <button
                              onClick={(e) => handleProfileClick(e, sound.owner?.username)}
                              className="flex-shrink-0 transform hover:scale-110 transition-transform duration-300"
                            >
                              <img
                                src={sound.owner?.avatar || DEFAULT_USER_IMAGE}
                                alt={sound.owner?.username}
                                className="w-8 h-8 rounded-full border-2 border-white/20 hover:border-accent-end transition-colors duration-300 shadow-md"
                              />
                            </button>
                            <button
                              onClick={(e) => handleProfileClick(e, sound.owner?.username)}
                              className="flex items-center group/owner"
                            >
                              <span className="text-sm font-medium text-white/90 group-hover/owner:text-accent-end truncate transition-colors duration-300">
                                {sound.owner?.username}
                              </span>
                              {sound.owner?.verified && <VerifiedBadge className="ml-1.5" />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              </div>

              {featuredSounds.length > cardsToShow && (
                <button
                  onClick={() => nextSlide('featured')}
                  className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
                >
                  <ChevronRight className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </motion.div>

      {/* Featured Users Section */}
      {featuredUsers.length > 0 && renderFeaturedUsersCarousel()}
    </motion.div>
  );
};

export default React.memo(FeaturedSoundsCarousel);