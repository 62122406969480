import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Spinner from './Spinner';

export const TermsOfService = ({ license, onAccept }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!license) return null;

  const handleAccept = async () => {
    setIsLoading(true);
    try {
      await onAccept();
    } catch (error) {
      console.error('Error accepting terms:', error);
      setIsLoading(false);
    }
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm z-[99999] flex items-center justify-center p-4">
      <div className="bg-bg-secondary rounded-lg w-full max-w-4xl h-[90vh] flex flex-col">
        <div className="p-6 border-b border-white/10">
          <h2 className="text-2xl font-bold text-text-primary">Terms of Service</h2>
          <p className="text-sm text-text-secondary mt-2">
            Please read and accept our terms of service to continue
          </p>
        </div>
        
        <div className="flex-1 overflow-hidden p-6">
          <iframe
            src={license.url}
            className="w-full h-full rounded-md"
            title="Terms of Service"
          />
        </div>

        <div className="p-6 border-t border-white/10 flex justify-between items-center">
          <p className="text-sm text-text-secondary">
            By clicking Accept, you agree to our Terms of Service
          </p>
          <button
            onClick={handleAccept}
            disabled={isLoading}
            className="px-6 py-2 bg-gradient-to-r from-accent-start to-accent-end text-white rounded-md hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
          >
            {isLoading ? (
              <>
                <Spinner />
                Processing...
              </>
            ) : (
              'Accept Terms'
            )}
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default TermsOfService;