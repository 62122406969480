import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { CustomDialog } from '../CustomComponents';
import { useAudioNavigation } from '../../context/AudioNavigationContext';
import { JUCESend, isJUCE, JUCEReceive } from '../../context/JUCE';
import { WaveformStartPointFinder } from '../WaveformStartPointFinder';
import { Music, Wand2, GripVertical, Play, Pause } from 'lucide-react';
import Spinner from '../../components/Spinner';

// Spinner component for loading states
const SpinnerFull = ({ message }) => (
  <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
    <div className="flex flex-col items-center space-y-4">
      <Spinner />
      <p className="text-white/80">{message}</p>
    </div>
  </div>
);

// Audio Sample component for playing individual samples
// Global registry to track all audio elements for coordinated playback
const audioElementsRegistry = new Set();

// Function to pause all audio except the one that should be playing
const pauseAllAudioExcept = (exceptElement) => {
  audioElementsRegistry.forEach(audioEl => {
    if (audioEl !== exceptElement && !audioEl.paused) {
      audioEl.pause();
    }
  });
};

const AudioSample = ({
  url,
  label,
  type,
  isPlaying,
  onPlayPause,
  isStreaming = false,
  sound,
  loopStartPoint = -1,
  processingState = null
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const audioRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const sourceBufferRef = useRef(null);
  
  // Handle placeholder samples
  const isPlaceholder = type === 'placeholder';

  useEffect(() => {
    const handleJUCEReceive = ({ eventName }) => {
      if (eventName === 'dragExportComplete') {
        setIsDragging(false);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);
    return () => JUCEReceive.off('JUCEReceive', handleJUCEReceive);
  }, []);

  const handleDragStart = async (e) => {
    if (!isStreaming && isJUCE()) {
      e.preventDefault();
      setIsDragging(true);

      const uniqueId = `drag_${Date.now()}_${Math.random().toString(36).substring(7)}`;
      
      const soundWithUrl = {
        ...sound,
        name: uniqueId,
        file_name: `${uniqueId}.mp3`,
        type: { name: 'Loop' },
        download_url: url,
        loop_start_point: loopStartPoint,
        loop_end_point: -1,
        bpm: sound.bpm,
        key: sound.key,
        tags: sound.tags,
        genres: sound.genres,
        instruments: sound.instruments,
        category: sound.category
      };

      JUCESend('dragExport', soundWithUrl);
    }
  };

  useEffect(() => {
    if (isStreaming && audioRef.current) {
      if (typeof MediaSource !== 'undefined') {
        const mediaSource = new MediaSource();
        mediaSourceRef.current = mediaSource;
        audioRef.current.src = URL.createObjectURL(mediaSource);

        let sourceBuffer = null;
        const queue = [];
        let isUpdating = false;

        const processQueue = () => {
          if (!sourceBuffer || queue.length === 0 || isUpdating) return;
          
          isUpdating = true;
          const buffer = queue.shift();
          try {
            sourceBuffer.appendBuffer(buffer);
          } catch (error) {
            console.error('Error appending buffer:', error);
            if (audioRef.current) {
              // Fallback to direct audio playback
              audioRef.current.src = url;
              mediaSource.endOfStream();
            }
          }
        };

        mediaSource.addEventListener('sourceopen', async () => {
          try {
            sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
            sourceBufferRef.current = sourceBuffer;
            
            // Handle updateend event to process the next chunk
            sourceBuffer.addEventListener('updateend', () => {
              isUpdating = false;
              processQueue();
            });

            const response = await fetch(url);
            const reader = response.body.getReader();

            // Set a small prefetch buffer before playing
            audioRef.current.oncanplay = () => {
              if (isPlaying) {
                audioRef.current.play().catch(err => console.error('Play error:', err));
              }
            };

            let done = false;
            while (!done) {
              const { value, done: streamDone } = await reader.read();
              if (value) {
                queue.push(value);
                processQueue();
              }
              done = streamDone;
            }

            // When all chunks are read, end the stream after the queue is processed
            const checkQueueAndEndStream = () => {
              if (queue.length === 0 && !isUpdating) {
                mediaSource.endOfStream();
              } else {
                setTimeout(checkQueueAndEndStream, 100);
              }
            };
            checkQueueAndEndStream();
          } catch (error) {
            console.error('Error streaming audio:', error);
            if (audioRef.current) {
              audioRef.current.src = url;
            }
          }
        });
      } else {
        // Fallback for browsers without MediaSource support
        audioRef.current.src = url;
      }
    } else if (audioRef.current) {
      audioRef.current.src = url;
    }

    return () => {
      if (mediaSourceRef.current) {
        if (mediaSourceRef.current.readyState === 'open') {
          mediaSourceRef.current.endOfStream();
        }
        mediaSourceRef.current = null;
      }
      if (audioRef.current) {
        audioRef.current.src = '';
      }
      sourceBufferRef.current = null;
    };
  }, [url, isStreaming]);

  useEffect(() => {
    // Register the audio element when it's created
    if (audioRef.current) {
      audioElementsRegistry.add(audioRef.current);
    }
    
    // Clean up when component is unmounted
    return () => {
      if (audioRef.current) {
        audioElementsRegistry.delete(audioRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!audioRef.current) return;
    
    if (isPlaying) {
      // Pause all other audio elements first
      pauseAllAudioExcept(audioRef.current);
      
      // Add a small timeout to ensure buffer has started filling before play
      if (isStreaming) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch(err => {
            console.error('Error during playback:', err);
            // If autoplay fails, try once more with user interaction
            if (err.name === 'NotAllowedError') {
              const userInteractionHandler = () => {
                pauseAllAudioExcept(audioRef.current);
                audioRef.current.play().catch(e => console.error('Second play attempt failed:', e));
                document.removeEventListener('click', userInteractionHandler);
              };
              document.addEventListener('click', userInteractionHandler, { once: true });
            }
          });
        }
      } else {
        audioRef.current.play().catch(err => console.error(err));
      }
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, isStreaming]);

  if (isDragging) {
    return <SpinnerFull message="Processing audio, please wait..." />;
  }

  // If this is a placeholder sample, show a special UI
  if (isPlaceholder) {
    return (
      <div className="group relative transition-all duration-200">
        <div className="absolute inset-0 bg-gradient-to-r from-violet-500/10 to-fuchsia-500/10 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        <div className="relative overflow-hidden bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-4 hover:border-white/20 transition-all">
          <div className="flex items-center justify-between space-x-4">
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 rounded-full bg-gradient-to-br from-violet-500 to-fuchsia-500 flex items-center justify-center">
                <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
              </div>
              <div className="flex flex-col space-y-1">
                <span className="text-sm font-medium text-white/90">{label}</span>
                <div className="flex items-center">
                  <div className="w-1.5 h-1.5 rounded-full bg-emerald-500 animate-pulse mr-2" />
                  <span className="text-xs text-white/60">Preparing...</span>
                </div>
              </div>
            </div>
            
            <div className="flex items-center space-x-2">
              <button
                className="flex items-center space-x-2 px-4 py-2 bg-white/10 opacity-50 rounded-lg"
                disabled={true}
              >
                <Play className="w-4 h-4 text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  // Normal sample UI
  return (
    <div className="group relative transition-all duration-200">
      <div className="absolute inset-0 bg-gradient-to-r from-violet-500/10 to-fuchsia-500/10 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      <div className="relative overflow-hidden bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-4 hover:border-white/20 transition-all">
        <div className="flex items-center justify-between space-x-4">
          <div className="flex items-center space-x-3">
            <div className="w-10 h-10 rounded-full bg-gradient-to-br from-violet-500 to-fuchsia-500 flex items-center justify-center">
              {type === 'original' ? (
                <Music className="w-5 h-5 text-white" />
              ) : (
                <Wand2 className="w-5 h-5 text-white" />
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <span className="text-sm font-medium text-white/90">{label}</span>
              {(isStreaming || processingState) && (
                <div className="flex items-center">
                  <div className="w-1.5 h-1.5 rounded-full bg-emerald-500 animate-pulse mr-2" />
                  <span className="text-xs text-white/60">
                    {processingState ? processingState : 'Generating...'}
                  </span>
                </div>
              )}
            </div>
          </div>
          
          <div className="flex items-center space-x-2">
            {!isStreaming && isJUCE() && (
              <button 
                className="p-2 rounded-lg hover:bg-white/5 transition-colors"
                draggable={true}
                onDragStart={handleDragStart}
              >
                <GripVertical className="w-5 h-5 text-white/60" />
              </button>
            )}
            <button
              onClick={onPlayPause}
              className="flex items-center space-x-2 px-4 py-2 bg-white/10 hover:bg-white/15 rounded-lg transition-colors"
            >
              {isPlaying ? (
                <Pause className="w-4 h-4 text-white" />
              ) : (
                <Play className="w-4 h-4 text-white" />
              )}
            </button>
          </div>
        </div>
      </div>
      <audio
        ref={audioRef}
        className="hidden"
        preload="auto"
        onEnded={() => {
          if (isStreaming && isPlaying) {
            onPlayPause();
          }
        }}
        onError={(e) => console.error('Audio error:', e)}
        onStalled={() => console.warn('Audio playback stalled')}
        onWaiting={() => console.warn('Audio playback waiting for data')}
        onCanPlayThrough={() => {
          // When enough data is available for uninterrupted playback
          if (isPlaying && audioRef.current && audioRef.current.paused) {
            audioRef.current.play().catch(err => console.error('Play error:', err));
          }
        }}
      />
    </div>
  );
};

// Main AISampleDialog component
const AISampleDialog = ({ isOpen, onClose, sound, initialPrompt = '', autoGenerate = false }) => {
  const {
    currentPlayingSound,
    setCurrentPlayingSound,
    isPlaying,
    setIsPlaying
  } = useAudioNavigation();

  const [formData, setFormData] = useState({
    prompt: initialPrompt,
    model: 'chirp-v4',
    styleNegative: '',
    continueAt: 10,
    style: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [generatedSamples, setGeneratedSamples] = useState(null);
  const [inProgressSamples, setInProgressSamples] = useState(null);
  const [error, setError] = useState('');
  const [taskId, setTaskId] = useState(null);
  const [pollInterval, setPollInterval] = useState({});
  const [loopStartPoint, setLoopStartPoint] = useState(-1);
  const [streamingPlayingId, setStreamingPlayingId] = useState(null);
  const [activeUrl, setActiveUrl] = useState(null);
  const [stemProcessingStates, setStemProcessingStates] = useState({});
  const [stemPollIntervals, setStemPollIntervals] = useState({});

  // Handle audio preview URL
  const audioUrl = sound?.audio_preview;
  const filename = audioUrl ? audioUrl.split('/').pop() : '';
  const encodedFilename = encodeURIComponent(filename);
  const baseUrl = audioUrl ? audioUrl.substring(0, audioUrl.lastIndexOf('/') + 1) : '';
  const encodedUrl = audioUrl ? baseUrl + encodedFilename : '';

  // Effect to auto-generate when dialog opens if autoGenerate is true
  useEffect(() => {
    if (isOpen && autoGenerate && formData.prompt.trim() && !generatedSamples && !inProgressSamples) {
      // Use small timeout to ensure UI is ready
      const timer = setTimeout(() => {
        handleGenerate();
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen, autoGenerate, formData.prompt, generatedSamples, inProgressSamples]);

  // Effect to update prompt and style when initialPrompt changes
  useEffect(() => {
    if (initialPrompt) {
      setFormData(prev => ({
        ...prev,
        prompt: initialPrompt,
        style: initialPrompt // Also set the style field with the same text
      }));
    }
  }, [initialPrompt]);
  
  // Function to handle stem separation in a non-blocking way with strict control
  const startStemSeparation = async (sample) => {
    // Hard safety: never process samples that are already stemmed
    if (sample.id.includes('_stemmed_')) {
      console.warn(`Attempted to stem separate an already stemmed sample: ${sample.id}`);
      return;
    }
  
    // Prevent duplicate stem separation requests for the same sample
    if (stemProcessingStates[sample.id]) {
      console.log(`Stem separation already in progress for sample ${sample.id}`);
      return;
    }
    
    try {
      // Mark this sample as being processed
      setStemProcessingStates(prev => ({
        ...prev,
        [sample.id]: 'Starting stem separation...'
      }));
  
      const response = await axios.post(
        'https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-50f1cf42-1fdb-4637-8a81-fca3b5953dce/default/Replicate-DEMUCS-StemSeperation',
        {
          audio_url: sample.audio_url,
          stem_type: "drums"
        }
      );
  
      if (response.status === 200) {
        // Find parent sample in the current list of samples
        const allSamples = generateFlatSamples();
        const parentSample = allSamples.find(s => s.id === sample.id);
        
        // Use a consistent label based on the parent's stable sample number
        let parentLabel = "Sample";
        if (parentSample) {
          parentLabel = parentSample.label;
        } else {
          // If parent not found for some reason, create a backup label
          console.warn(`Parent sample not found for stem separation: ${sample.id}`);
          parentLabel = `Sample ${Object.keys(sampleGroups).length}`;
        }
        
        // Create stemmed sample with all the original sample's metadata including batchId
        const stemmedSample = {
          ...sample,
          id: `${sample.id}_stemmed_${Date.now()}`,
          audio_url: response.data.no_drums,
          audio_preview: response.data.no_drums,
          name: `${parentLabel} (No Drums)`,
          label: `${parentLabel} (No Drums)`,
          type: { name: 'Loop' },
          file_name: `${sample.id}_no_drums.mp3`,
          download_url: response.data.no_drums,
          loop_start_point: sample.loop_start_point || -1,
          loop_end_point: -1,
          bpm: sample.bpm || 0,
          key: sample.key || '',
          tags: sample.tags || [],
          genres: sample.genres || [],
          instruments: sample.instruments || [],
          category: sample.category || '',
          // Keep the batch ID for organization
          batchId: sample.batchId,
          // Mark as stemmed for tracking
          stemmed: true
        };
  
        // Add the stemmed sample to the list of generated samples
        setGeneratedSamples(prev => {
          const currentSamples = prev || [];
          return [...currentSamples, stemmedSample];
        });
        
        // Remove this sample from the processing state
        setStemProcessingStates(prev => {
          const newStates = { ...prev };
          delete newStates[sample.id];
          return newStates;
        });
      } else if (response.status === 202) {
        // Retry after a delay if the service is still processing
        await new Promise(resolve => setTimeout(resolve, 3000));
        return startStemSeparation(sample);
      } else {
        throw new Error(response.data.body?.error || 'Stem separation failed');
      }
    } catch (error) {
      console.error('Failed to start stem separation:', error);
      setStemProcessingStates(prev => ({
        ...prev,
        [sample.id]: 'Stem separation failed'
      }));
      
      // Ensure we clean up any pending polling for this sample
      if (stemPollIntervals[sample.id]) {
        clearInterval(stemPollIntervals[sample.id]);
        setStemPollIntervals(prev => {
          const newIntervals = { ...prev };
          delete newIntervals[sample.id];
          return newIntervals;
        });
      }
    }
  };

  const handlePlayPause = (sample) => {
    // Don't allow playing placeholder samples (they have no audio)
    if (sample.type === 'placeholder') {
      return;
    }

    // Check if this sample has a processing state but allow playing anyway
    const isProcessing = stemProcessingStates[sample.id] !== undefined;
    
    if (sample.type === 'streaming') {
      stopGlobalPlayback();
      if (streamingPlayingId === sample.id) {
        setStreamingPlayingId(null);
      } else {
        // Pause any other playing audio elements (important for iOS Safari)
        pauseAllAudioExcept(null);
        setStreamingPlayingId(sample.id);
      }
    } else {
      setStreamingPlayingId(null);
      const isSameSample = currentPlayingSound?.audio_preview === sample.url;
      
      const soundToPlay = {
        ...sound,
        audio_preview: sample.url,
        name: sample.id.includes('_stemmed_') ? 
          sample.name || `${sound.name} (No Drums)` : 
          sample.type === 'original' ? sound.name : `${sound.name} (AI Generated)`,
        owner: sound.owner,
        type: { name: 'Loop' },
        file_name: sample.id.includes('_stemmed_') ? 
          `${sample.id.split('_stemmed_')[0]}_no_drums.mp3` : 
          sound.file_name
      };
  
      if (isSameSample) {
        if (isPlaying) {
          setIsPlaying(false);
          if (isJUCE()) {
            JUCESend('pauseSound', currentPlayingSound);
          }
        } else {
          // Pause any other playing audio elements (important for iOS Safari)
          pauseAllAudioExcept(null);
          setIsPlaying(true);
          if (isJUCE()) {
            JUCESend('playSound', currentPlayingSound);
          }
        }
      } else {
        // Pause any other playing audio elements (important for iOS Safari)
        pauseAllAudioExcept(null);
        setIsPlaying(true);
        setCurrentPlayingSound(soundToPlay);
  
        if (isJUCE()) {
          JUCESend('playSound', soundToPlay);
        }
      }
  
      if (sample.type === 'generated' || sample.id.includes('_stemmed_')) {
        setActiveUrl(sample.url);
      } else {
        setActiveUrl(null);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDurationLoad = (duration) => {
    setFormData(prev => ({
      ...prev,
      continueAt: Number((duration / 2).toFixed(5))
    }));
  };

  const handleLoopPointChange = (samplePosition) => {
    setLoopStartPoint(samplePosition);
  };

  // Track the number of poll attempts to enforce strict limits
  const [pollAttempts, setPollAttempts] = useState({});
  
  const pollTaskStatus = async (taskId, batchId) => {
    // Strict limit to 20 attempts (approximately 3.5 minutes with 10s interval)
    const MAX_POLL_ATTEMPTS = 20;
    
    // Check if we've already polled too many times for this batch
    const attempts = pollAttempts[batchId] || 0;
    
    if (attempts >= MAX_POLL_ATTEMPTS) {
      console.warn(`Exceeded maximum poll attempts (${MAX_POLL_ATTEMPTS}) for batch ${batchId}, stopping polling`);
      
      // Clear this batch's interval
      if (pollInterval && pollInterval[batchId]) {
        clearInterval(pollInterval[batchId]);
        setPollInterval(prev => {
          const updated = { ...prev };
          delete updated[batchId];
          return updated;
        });
      }
      
      // Update attempts count
      setPollAttempts(prev => {
        const updated = { ...prev };
        delete updated[batchId]; 
        return updated;
      });
      
      // Remove any placeholders for this batch
      setInProgressSamples(prev => {
        if (!prev) return null;
        return prev.filter(sample => sample.batchId !== batchId);
      });
      
      // Show error for this batch
      setError(`Generation timed out for batch ${batchId}. Please try again.`);
      return;
    }
    
    // Increment the attempt counter
    setPollAttempts(prev => ({
      ...prev,
      [batchId]: attempts + 1
    }));
    
    console.log(`Poll attempt ${attempts + 1}/${MAX_POLL_ATTEMPTS} for batch ${batchId}`);
    
    try {
      const response = await axios.post(
        'https://api.acedata.cloud/suno/tasks',
        { id: taskId, action: "retrieve" },
        {
          headers: {
            Authorization: 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      const samples = response.data.response.data;
      const allCompleted = samples.every(sample => sample.state === 'succeeded');
      
      // Tag each sample with the batch ID for identification
      const taggedSamples = samples.map(sample => ({
        ...sample,
        batchId
      }));
      
      if (allCompleted) {
        // Clear only this batch's interval
        if (pollInterval && pollInterval[batchId]) {
          clearInterval(pollInterval[batchId]);
          setPollInterval(prev => {
            const updated = { ...prev };
            delete updated[batchId];
            return updated;
          });
        }
        
        // Add completed samples to existing ones
        setGeneratedSamples(prev => {
          const currentSamples = prev || [];
          return [...currentSamples, ...taggedSamples];
        });
        
        // Remove this batch's placeholders from in-progress
        setInProgressSamples(prev => {
          if (!prev) return null;
          return prev.filter(sample => sample.batchId !== batchId);
        });

        // Process the samples in this batch
        // Find samples that can be processed (not already stemmed or processing)
        const samplesInBatch = taggedSamples.filter(sample => 
          !sample.id.includes('_stemmed_') && // Not already stemmed
          !stemProcessingStates[sample.id] &&  // Not currently processing
          sample.batchId === batchId           // From this specific batch
        );
        
        // Limit to 2 samples per batch to stay within reasonable limits
        // But don't block the user from generating more
        const samplesToProcess = samplesInBatch.slice(0, 2);
        
        console.log(`Processing ${samplesToProcess.length} samples from batch ${batchId}`);
        
        // Process the samples with minimal but effective delays to prevent API overload
        // Calculate optimal delay based on the current system load
        const activeProcessCount = Object.keys(stemProcessingStates).length;
        const baseDelay = Math.min(activeProcessCount * 1000 + 1000, 3000); // Cap at 3 seconds
        
        samplesToProcess.forEach((sample, index) => {
          // Mark that we've captured this sample for processing
          setStemProcessingStates(prev => ({
            ...prev,
            [sample.id]: 'Queued for processing...'
          }));
          
          // Start processing with adaptive delay
          setTimeout(() => {
            startStemSeparation(sample);
          }, index * baseDelay); // Adaptive delay between samples
        });
      } else {
        // Update only this batch's in-progress samples
        setInProgressSamples(prev => {
          if (!prev) return taggedSamples;
          
          // Remove old placeholders for this batch and add the updated ones
          const filteredSamples = prev.filter(sample => sample.batchId !== batchId);
          return [...filteredSamples, ...taggedSamples];
        });
      }
    } catch (err) {
      console.error('Error polling task:', err);
      setError('Failed to check generation status');
      setIsLoading(false);
    }
  };

  const handleGenerate = async () => {
    // Allow unlimited generation but provide a warning if there's already a lot in progress
    const activeGenerations = Object.keys(pollInterval || {}).length;
    const activeProcessing = Object.keys(stemProcessingStates || {}).length;
    
    if (activeGenerations >= 2 || activeProcessing >= 2) {
      // Just show a warning but don't block generation
      setError('Warning: Multiple generations in progress. This may impact performance.');
      
      // Clear the warning after 3 seconds
      setTimeout(() => {
        setError('');
      }, 3000);
    } else {
      // Clear any existing errors
      setError('');
    }
    
    // Create a timestamp for this generation batch
    const batchId = Date.now();
    
    // ALWAYS create exactly 2 placeholder samples
    const dummyPlaceholders = [
      {
        id: `placeholder-1-${batchId}`,
        state: 'loading',
        batchId
      },
      {
        id: `placeholder-2-${batchId}`,
        state: 'loading',
        batchId
      }
    ];
    
    console.log(`Creating new batch ${batchId} with 2 placeholders`);
    
    // Add new placeholders to existing in-progress samples
    setInProgressSamples(prev => {
      const currentSamples = prev || [];
      return [...currentSamples, ...dummyPlaceholders];
    });
    
    // Reset poll attempts for this batch
    setPollAttempts(prev => ({
      ...prev,
      [batchId]: 0
    }));

    try {
      const uploadResponse = await axios.post(
        'https://api.acedata.cloud/suno/upload',
        { audio_url: encodedUrl },
        {
          headers: {
            Authorization: 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );
      const { audio_id } = uploadResponse.data.data;

      const generateResponse = await axios.post(
        'https://api.acedata.cloud/suno/audios',
        {
          action: 'extend',
          prompt: formData.prompt,
          model: formData.model,
          custom: true,
          instrumental: true,
          style_negative: formData.styleNegative,
          audio_id: audio_id,
          continue_at: parseInt(formData.continueAt),
          style: formData.style,
          callback_url: 'https://'
        },
        {
          headers: {
            Authorization: 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      const newTaskId = generateResponse.data.task_id;
      setTaskId(newTaskId);
      
      // Store the batch ID with the task ID
      // Set a maximum polling time of 5 minutes per batch
      const MAX_POLL_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
      
      // Create the polling interval
      const interval = setInterval(() => pollTaskStatus(newTaskId, batchId), 10000);
      
      // Store the interval reference
      setPollInterval(prevIntervals => {
        // Store multiple poll intervals for different batches
        const updatedIntervals = { ...(prevIntervals || {}) };
        updatedIntervals[batchId] = interval;
        return updatedIntervals;
      });
      
      // Set a hard timeout to ensure polling eventually stops
      setTimeout(() => {
        if (pollInterval?.[batchId]) {
          console.log(`Enforcing maximum poll time for batch ${batchId}`);
          clearInterval(pollInterval[batchId]);
          setPollInterval(prev => {
            const updated = { ...prev };
            delete updated[batchId];
            return updated;
          });
        }
      }, MAX_POLL_TIME);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to generate samples');
      setIsLoading(false);
    }
  };

  const stopGlobalPlayback = () => {
    if (isPlaying) {
      setIsPlaying(false);
      if (isJUCE()) {
        JUCESend('pauseSound', currentPlayingSound);
      }
    }
    // Stop all HTML5 audio elements
    pauseAllAudioExcept(null);
  };

  const isSamplePlaying = (sample) => {
    if (sample.type === 'placeholder') {
      return false; // Placeholder samples can't play
    }
    if (sample.type === 'streaming') {
      return streamingPlayingId === sample.id;
    }
    return currentPlayingSound?.audio_preview === sample.url && isPlaying;
  };

  // Group all samples by their batch with enforced strict limits
  const createSampleGroups = () => {
    const groups = {};
    
    // First categorize in-progress samples (placeholders and streaming)
    if (inProgressSamples) {
      inProgressSamples.forEach(sample => {
        const batchId = sample.batchId || 'default';
        if (!groups[batchId]) groups[batchId] = [];
        
        // Strict enforcement: at most 2 samples per batch in in-progress
        // Skip if this would exceed 2 samples in this batch
        if (groups[batchId].filter(item => item.status === 'in-progress').length >= 2) {
          console.warn(`Skipping excess in-progress sample ${sample.id} in batch ${batchId}`);
          return;
        }
        
        groups[batchId].push({
          sample,
          status: 'in-progress',
          index: groups[batchId].length
        });
      });
    }
    
    // Then categorize generated samples
    if (generatedSamples) {
      generatedSamples.forEach(sample => {
        const batchId = sample.batchId || 'default';
        if (!groups[batchId]) groups[batchId] = [];
        
        // Determine if this is a stemmed sample
        const isDerivedSample = sample.id.includes('_stemmed_');
        const parentId = isDerivedSample ? sample.id.split('_stemmed_')[0] : null;
        
        // Strict enforcement: at most 2 primary samples per batch
        // Skip if this would exceed 2 primary samples in this batch
        // (Stemmed samples don't count against this limit)
        if (!isDerivedSample && 
            groups[batchId].filter(item => 
              item.status === 'generated' && 
              !item.sample.id.includes('_stemmed_')
            ).length >= 2) {
          console.warn(`Skipping excess generated sample ${sample.id} in batch ${batchId}`);
          return;
        }
        
        // Strict enforcement: at most 1 stemmed version per primary sample
        // Skip if this stemmed sample already has a version in the group
        if (isDerivedSample && 
            groups[batchId].some(item => 
              item.status === 'generated' && 
              item.sample.id.includes('_stemmed_') && 
              item.sample.id.includes(parentId)
            )) {
          console.warn(`Skipping duplicate stemmed sample for ${parentId}`);
          return;
        }
        
        groups[batchId].push({
          sample,
          status: 'generated',
          index: isDerivedSample ? null : groups[batchId].length,
          parentId
        });
      });
    }
    
    return groups;
  };
  
  const sampleGroups = createSampleGroups();
  
  // Assign stable sample numbers to each batch
  const generateStableSampleNumbers = () => {
    const batchSampleNumbers = {};
    let nextSampleNumber = 1; // Start numbering from 1 (after the original)
    
    // Order batches chronologically by batchId (timestamp)
    const sortedBatches = Object.keys(sampleGroups).sort((a, b) => {
      // Handle 'default' batch separately
      if (a === 'default') return -1;
      if (b === 'default') return 1;
      return parseInt(a) - parseInt(b);
    });
    
    // Assign sequential numbers to primary samples in each batch
    sortedBatches.forEach(batchId => {
      const batch = sampleGroups[batchId];
      const primarySamples = batch.filter(item => 
        !item.sample.id.includes('_stemmed_') && 
        item.status === 'generated'
      );
      
      // Create a mapping for this batch
      batchSampleNumbers[batchId] = {};
      
      // Assign sequential numbers to primary samples in this batch
      primarySamples.forEach(item => {
        batchSampleNumbers[batchId][item.sample.id] = nextSampleNumber++;
      });
    });
    
    return batchSampleNumbers;
  };
  
  // Flatten and properly index all samples using stable numbering
  const generateFlatSamples = () => {
    // Generate stable sample numbers across batches
    const stableSampleNumbers = generateStableSampleNumbers();
    
    // Start with the original sample
    const result = [{
      id: 'original',
      url: encodedUrl,
      label: 'Original Sample',
      type: 'original',
      loopStartPoint
    }];
    
    // Process each batch in chronological order
    const sortedBatches = Object.keys(sampleGroups).sort((a, b) => {
      // Handle 'default' batch separately
      if (a === 'default') return -1;
      if (b === 'default') return 1;
      return parseInt(a) - parseInt(b);
    });
    
    sortedBatches.forEach(batchId => {
      const items = sampleGroups[batchId];
      
      // First add all non-stemmed samples
      const mainSamples = items.filter(item => !item.sample.id.includes('_stemmed_'));
      
      // Add main samples with their stable numbers
      mainSamples.forEach(item => {
        const { sample, status } = item;
        
        // Get the sample number (use batch index for placeholders/streaming)
        let sampleNumber;
        
        if (status === 'generated') {
          // Use pre-assigned stable number for generated samples
          sampleNumber = stableSampleNumbers[batchId][sample.id] || result.length;
        } else {
          // For in-progress samples, derive their position from the stable numbers
          // This batch's starting number is the highest number in previous batches + 1
          const prevBatches = sortedBatches.filter(b => parseInt(b) < parseInt(batchId));
          let startingNumber = 1; // Default
          
          prevBatches.forEach(b => {
            const batchNumbers = Object.values(stableSampleNumbers[b] || {});
            if (batchNumbers.length > 0) {
              startingNumber = Math.max(...batchNumbers) + 1;
            }
          });
          
          // Calculate position within this batch for placeholders
          const batchIndex = mainSamples.findIndex(s => s.sample.id === sample.id);
          sampleNumber = startingNumber + batchIndex;
        }
        
        // Handle placeholders
        if (sample.state === 'loading') {
          result.push({
            id: `streaming-${sample.id}`,
            url: '',  // No URL yet since it's a placeholder
            label: `Sample ${sampleNumber}`,
            type: 'placeholder',
            isStreaming: false,
            loopStartPoint,
            batchId: sample.batchId,
            sampleNumber // Store the stable number
          });
          return;
        }
        
        // Handle streaming/generated samples
        result.push({
          id: sample.id,
          url: sample.audio_url,
          label: `Sample ${sampleNumber}`,
          type: status === 'in-progress' ? 'streaming' : 'generated',
          isStreaming: status === 'in-progress',
          loopStartPoint,
          processingState: stemProcessingStates[sample.id],
          batchId: sample.batchId,
          sampleNumber // Store the stable number
        });
      });
      
      // Then add all stemmed samples right after their parents
      const stemmedSamples = items.filter(item => item.sample.id.includes('_stemmed_'));
      
      stemmedSamples.forEach(item => {
        const { sample } = item;
        // Find the parent sample
        const parentId = sample.id.split('_stemmed_')[0];
        const parentIndex = result.findIndex(s => s.id === parentId);
        
        if (parentIndex !== -1) {
          // Insert right after the parent
          result.splice(parentIndex + 1, 0, {
            id: sample.id,
            url: sample.audio_url,
            label: `${result[parentIndex].label} (No Drums)`,
            type: 'generated',
            loopStartPoint,
            processingState: stemProcessingStates[sample.id],
            batchId: sample.batchId,
            sampleNumber: result[parentIndex].sampleNumber // Use parent's number
          });
        } else {
          // If parent not found, add to the end
          result.push({
            id: sample.id,
            url: sample.audio_url,
            label: `Sample (No Drums)`,
            type: 'generated',
            loopStartPoint,
            processingState: stemProcessingStates[sample.id],
            batchId: sample.batchId
          });
        }
      });
    });
    
    return result;
  };
  
  const allSamples = audioUrl ? generateFlatSamples() : [];

  useEffect(() => {
    if (!isOpen) {
      // Stop all audio playback
      setStreamingPlayingId(null);
      setActiveUrl(null);
      
      // Clear all stem poll intervals
      Object.values(stemPollIntervals).forEach(interval => clearInterval(interval));
      setStemPollIntervals({});
      
      // Clear all polling intervals for sample generation
      if (pollInterval) {
        Object.values(pollInterval).forEach(interval => clearInterval(interval));
        setPollInterval({});
      }
      
      // Clear processing states
      setStemProcessingStates({});
    }
    return () => {
      // Clear all polling intervals on unmount
      if (pollInterval) {
        Object.values(pollInterval).forEach(interval => clearInterval(interval));
      }
      Object.values(stemPollIntervals).forEach(interval => clearInterval(interval));
    };
  }, [isOpen, pollInterval]);

  useEffect(() => {
    if (!audioUrl) {
      console.warn('No audio preview available for this sound');
      return;
    }

    if (isOpen) {
      const audio = new Audio(encodedUrl);
      audio.addEventListener('loadedmetadata', () => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        fetch(encodedUrl)
          .then(response => response.arrayBuffer())
          .then(buffer => audioContext.decodeAudioData(buffer))
          .then(audioBuffer => {
            const exactDuration = audioBuffer.length / audioBuffer.sampleRate;
            handleDurationLoad(exactDuration);
          })
          .catch(error => {
            console.error('Error loading audio:', error);
            setError('Failed to load audio file');
          });
      });
    }
  }, [isOpen, encodedUrl, audioUrl]);

  useEffect(() => {
    const handleJUCEReceive = (event) => {
      if (event.eventName === 'audioStartPointChanged') {
        const newPoint = Number(event.data.value);
        setLoopStartPoint(newPoint);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);
    return () => JUCEReceive.off('JUCEReceive', handleJUCEReceive);
  }, []);

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={() => {
        // Stop all audio playback
        setStreamingPlayingId(null);
        
        // Clear all intervals
        if (pollInterval) {
          Object.values(pollInterval).forEach(interval => clearInterval(interval));
        }
        Object.values(stemPollIntervals).forEach(interval => clearInterval(interval));
        
        // Reset all states
        setPollInterval({});
        setStemPollIntervals({});
        setPollAttempts({});
        setStemProcessingStates({});
        
        onClose();
      }}
      title="Generate AI Sample"
      maxWidth="md:max-w-2xl"
    >
      <div className="space-y-6">
        {!audioUrl ? (
          <div className="px-4 py-3 bg-yellow-500/10 border border-yellow-500/20 rounded-lg">
            <p className="text-sm text-yellow-400">No audio preview available for this sound.</p>
          </div>
        ) : (
          <>
            <div className="grid gap-4">
              <div className="space-y-2">
                <label className="text-sm text-white/60">Style</label>
                <input
                  type="text"
                  name="style"
                  value={formData.style}
                  onChange={handleInputChange}
                  className="w-full px-4 h-12 bg-white/5 border border-white/10 rounded-lg focus:border-violet-500 focus:ring-1 focus:ring-violet-500 transition-all"
                  placeholder="e.g. trap, melodic, 808"
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm text-white/60">Avoid in Generation</label>
                <input
                  type="text"
                  name="styleNegative"
                  value={formData.styleNegative}
                  onChange={handleInputChange}
                  className="w-full px-4 h-12 bg-white/5 border border-white/10 rounded-lg focus:border-violet-500 focus:ring-1 focus:ring-violet-500 transition-all"
                  placeholder="Elements to avoid..."
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm text-white/60">Continue At (seconds)</label>
                <input
                  type="number"
                  step="0.001"
                  name="continueAt"
                  value={formData.continueAt}
                  onChange={handleInputChange}
                  className="w-full px-4 h-12 bg-white/5 border border-white/10 rounded-lg focus:border-violet-500 focus:ring-1 focus:ring-violet-500 transition-all"
                  min="0"
                />
              </div>
            </div>

            <button
              onClick={handleGenerate}
              className="w-full h-12 bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white rounded-lg font-medium hover:opacity-90 transition-opacity"
            >
              {Object.keys(pollInterval || {}).length > 0 ? (
                <div className="flex items-center justify-center space-x-2">
                  <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                  <span>Generate More Samples</span>
                </div>
              ) : (
                'Generate Samples'
              )}
            </button>

            {error && (
              <div className="px-4 py-3 bg-red-500/10 border border-red-500/20 rounded-lg">
                <p className="text-sm text-red-400">{error}</p>
              </div>
            )}

            <div className="space-y-3">
              {allSamples.map((sample) => (
                <AudioSample
                  key={sample.id}
                  url={sample.url}
                  label={sample.label}
                  type={sample.type}
                  isStreaming={sample.isStreaming}
                  isPlaying={isSamplePlaying(sample)}
                  onPlayPause={() => handlePlayPause(sample)}
                  sound={sound}
                  loopStartPoint={sample.loopStartPoint}
                  processingState={sample.processingState}
                />
              ))}
            </div>

            {activeUrl && (
              <div className="h-40 bg-white/5 rounded-lg overflow-hidden">
                <WaveformStartPointFinder 
                  audioUrl={activeUrl}
                  onLoopPointChange={handleLoopPointChange}
                />
              </div>
            )}
          </>
        )}
      </div>
    </CustomDialog>
  );
};

export default AISampleDialog;