import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { 
  X, 
  PlayIcon, 
  PauseIcon, 
  Upload, 
  Music, 
  Image as ImageIcon, 
  Heart, 
  Share2, 
  Download,
  ChevronLeft,
  Star,
  Plus
} from 'lucide-react';
import Spinner from '../components/Spinner';
import { createSoundpack, updateSoundpack, uploadFileToServer, fetchSoundpackById } from '../api/APIManager';
import SoundBrowser from '../components/SoundBrowser/SoundBrowser';
import { AuthContext } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { useAudioNavigation } from '../context/AudioNavigationContext';
import { CustomDialog } from '../components/CustomComponents';

const CreateSoundPackPage = ({ viewMode = false }) => {
  const navigate = useNavigate();
  const { id: soundpackId } = useParams();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    avatar: null,
    audioPreview: null,
    sounds: [],
    owner: null,
    featured: false
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [includedSounds, setIncludedSounds] = useState([]);
  const isEditMode = !!soundpackId;
  const { user } = useContext(AuthContext);
  const { 
    currentPlayingSound, 
    setCurrentPlayingSound,
    isPlaying,
    setIsPlaying 
  } = useAudioNavigation();
  const { 
    setCurrentPlayingSound: setGlobalCurrentPlayingSound, 
    setIsPlaying: setGlobalIsPlaying 
  } = useOutletContext();
  const [soundpackData, setSoundpackData] = useState(null);
  const [soundBrowserOpen, setSoundBrowserOpen] = useState(false);

  const handlePlay = useCallback((sound) => {
    if (currentPlayingSound && currentPlayingSound.id === sound.id) {
      setIsPlaying(!isPlaying);
      setGlobalIsPlaying(!isPlaying);
    } else {
      setGlobalCurrentPlayingSound(sound);
      setCurrentPlayingSound(sound);
      setIsPlaying(true);
      setGlobalIsPlaying(true);
    }
  }, [currentPlayingSound, isPlaying, setGlobalCurrentPlayingSound, setGlobalIsPlaying]);

  useEffect(() => {
    if (isEditMode) {
      const loadSoundpack = async () => {
        try {
          const soundpack = await fetchSoundpackById(soundpackId);
          setFormData({
            name: soundpack.name,
            avatar: null,
            audioPreview: null,
            sounds: soundpack.sounds?.map(sound => ({ id: sound.id })) || [],
            owner: soundpack.owner,
            featured: soundpack.featured
          });
          setPreviewImage(soundpack.avatar);
          setIncludedSounds(soundpack.sounds || []);
          setSoundpackData(soundpack);
        } catch (error) {
          console.error('Failed to fetch soundpack:', error);
        }
      };
      loadSoundpack();
    }
  }, [soundpackId, isEditMode]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({ ...prev, avatar: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setFormData(prev => ({ ...prev, avatar: null }));
    setPreviewImage(null);
  };

  const handleFeatureToggle = () => {
    setFormData(prev => ({
      ...prev,
      featured: !prev.featured
    }));
  };

  const handleSoundSelect = (sound) => {
    setFormData(prev => {
      const currentSounds = prev.sounds || [];
      const soundIndex = currentSounds.findIndex(s => s.id === sound.id);
      
      if (soundIndex >= 0) {
        // Remove sound
        setIncludedSounds(prevIncludedSounds => 
          prevIncludedSounds.filter(s => s.id !== sound.id)
        );
        return {
          ...prev,
          sounds: currentSounds.filter(s => s.id !== sound.id)
        };
      } else {
        // Add sound - keep full sound object in includedSounds
        setIncludedSounds(prevIncludedSounds => [
          ...prevIncludedSounds,
          sound // Store full sound object
        ]);
        return {
          ...prev,
          sounds: [...currentSounds, { id: sound.id }] // Store just the ID
        };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const uniqueFolder = uuidv4();
  
    try {
      let avatarUrl = previewImage;
      if (formData.avatar) {
        const avatarFileName = `soundpacks/${uniqueFolder}/${formData.avatar.name}`;
        const uploadResult = await uploadFileToServer(
          null,
          null,
          null,
          formData.avatar,
          avatarFileName
        );
        avatarUrl = uploadResult.image_file_url;
      }
  
      let audioPreviewUrl = null;
      if (formData.audioPreview) {
        const previewFileName = `soundpacks/${uniqueFolder}/${formData.audioPreview.name}`;
        const uploadResult = await uploadFileToServer(
          formData.audioPreview,
          previewFileName,
          null,
          null,
          null
        );
        audioPreviewUrl = uploadResult.original_file_url;
      }
  
      const soundpackData = {
        name: formData.name,
        avatar: avatarUrl,
        sounds: formData.sounds,
        audio_preview: audioPreviewUrl,
        featured: formData.featured
      };
  
      if (isEditMode) {
        await updateSoundpack(soundpackId, soundpackData);
      } else {
        await createSoundpack(soundpackData);
      }
      navigate('/soundpacks');
    } catch (error) {
      console.error(`Failed to ${isEditMode ? 'update' : 'create'} sound pack:`, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <div className="container mx-auto px-4 py-6">
        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Header Section */}
          <div className="relative mb-8">
            {!viewMode && (
              <button 
                type="button"
                onClick={() => navigate(-1)}
                className="absolute left-0 top-1/2 -translate-y-1/2 flex items-center gap-1 text-text-secondary hover:text-text-primary transition-colors"
              >
                <ChevronLeft className="h-5 w-5" />
                <span>Back</span>
              </button>
            )}
            <div className="text-center">
              <h1 className="text-3xl md:text-4xl font-bold text-text-primary mb-2">
                {viewMode ? '' : (isEditMode ? 'Edit Sound Pack' : 'Create New Sound Pack')}
              </h1>
            </div>
          </div>

          {/* Pack Details Section */}
          <div className="p-6">
            <div className="flex flex-col md:flex-row gap-6">
              {/* Cover Image */}
              <div className="h-32 w-32 flex-shrink-0">
                {viewMode ? (
                  previewImage ? (
                    <img
                      src={previewImage}
                      alt="Pack Cover"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-full h-full rounded-lg bg-bg-secondary/30 flex items-center justify-center">
                      <ImageIcon className="h-8 w-8 text-text-secondary" />
                    </div>
                  )
                ) : (
                  previewImage ? (
                    <div className="relative h-full rounded-lg overflow-hidden group">
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <button
                          type="button"
                          onClick={removeImage}
                          className="p-2 bg-red-500/80 rounded-full text-white hover:bg-red-600 transition-colors"
                        >
                          <X className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <label className="block cursor-pointer h-full">
                      <div className="h-full border-2 border-dashed border-gray-700 rounded-lg flex flex-col items-center justify-center bg-bg-secondary/30 hover:bg-bg-secondary/50 transition-colors">
                        <ImageIcon className="h-8 w-8 text-text-secondary mb-2" />
                        <span className="text-sm text-text-secondary">Upload Cover</span>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="hidden"
                        />
                      </div>
                    </label>
                  )
                )}
              </div>

              {/* Pack Info & Audio Preview */}
              <div className="flex-1 space-y-4">
                {viewMode ? (
                  <div>
                    <h2 className="text-2xl font-bold text-text-primary">{formData.name}</h2>
                    <p className="text-text-secondary">
                      <a 
                        href={`/${formData.owner?.username}`} 
                        className="hover:text-text-primary transition-colors"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/${formData.owner?.username}`);
                        }}
                      >
                        {formData.owner?.username}
                      </a>
                    </p>
                  </div>
                ) : (
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <label className="block text-sm font-medium text-text-primary">Pack Name</label>
                      {user?.admin && (
                        <button
                          type="button"
                          onClick={handleFeatureToggle}
                          className={`flex items-center gap-2 px-3 py-1.5 rounded-lg transition-colors ${
                            formData.featured 
                              ? 'bg-accent-end text-white' 
                              : 'bg-bg-secondary/50 text-text-secondary hover:bg-accent-end/20'
                          }`}
                        >
                          <Star className={`w-4 h-4 ${formData.featured ? 'fill-current' : ''}`} />
                          <span>{formData.featured ? 'Featured' : 'Feature'}</span>
                        </button>
                      )}
                    </div>
                    <input
                      type="text"
                      required
                      value={formData.name}
                      onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                      placeholder="Give your sound pack a name"
                      className="w-full px-4 py-3 bg-bg-secondary/50 border border-gray-700 rounded-lg text-text-primary placeholder-text-secondary/70 focus:ring-2 focus:ring-accent-end focus:border-transparent transition-all"
                    />
                  </div>
                )}
                
                {/* Audio Preview Player */}
                {viewMode ? (
                  (formData.audioPreview || soundpackData?.audio_preview) && (
                    <div className="flex items-center gap-4 pt-3 rounded-lg">
                      <button
                        type="button"
                        onClick={() => handlePlay({
                          id: formData.audioPreview ? 'preview' : soundpackData.id,
                          audio_preview: formData.audioPreview 
                            ? URL.createObjectURL(formData.audioPreview)
                            : soundpackData.audio_preview,
                          name: 'Pack Preview'
                        })}
                        className="p-2 rounded-full bg-accent-end/20 hover:bg-accent-end/30 transition-colors"
                      >
                        {(currentPlayingSound?.id === 'preview' || currentPlayingSound?.id === soundpackData?.id) && isPlaying ? (
                          <PauseIcon className="h-6 w-6 text-accent-end" />
                        ) : (
                          <PlayIcon className="h-6 w-6 text-accent-end" />
                        )}
                      </button>
                      <span className="text-sm text-text-primary">Preview Pack</span>
                    </div>
                  )
                ) : (
                  <div className="pt-2">
                    <label className="block text-sm font-medium text-text-primary mb-2">Audio Preview</label>
                    {formData.audioPreview || (isEditMode && soundpackData?.audio_preview) ? (
                      <div className="flex items-center gap-4">
                        <button
                          type="button"
                          onClick={() => handlePlay({
                            id: formData.audioPreview ? 'preview' : soundpackData.id,
                            audio_preview: formData.audioPreview 
                              ? URL.createObjectURL(formData.audioPreview)
                              : soundpackData.audio_preview,
                            name: 'Sound Pack Preview'
                          })}
                          className="p-2 rounded-full bg-accent-end/20 hover:bg-accent-end/30 transition-colors"
                        >
                          {(currentPlayingSound?.id === 'preview' || currentPlayingSound?.id === soundpackData?.id) && isPlaying ? (
                            <PauseIcon className="h-6 w-6 text-accent-end" />
                          ) : (
                            <PlayIcon className="h-6 w-6 text-accent-end" />
                          )}
                        </button>
                        <div>
                          <span className="text-sm text-text-primary block">{formData.audioPreview?.name || 'Current Preview'}</span>
                          <button
                            type="button"
                            onClick={() => {
                              setFormData(prev => ({ ...prev, audioPreview: null }));
                              if (isEditMode) {
                                setSoundpackData(prev => ({ ...prev, audio_preview: null }));
                              }
                            }}
                            className="text-xs text-red-500 hover:text-red-600 transition-colors"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ) : (
                      <label className="cursor-pointer flex items-center gap-3 p-2 border border-dashed border-gray-700 rounded-lg bg-bg-secondary/30 hover:bg-bg-secondary/50 transition-colors">
                        <Music className="h-5 w-5 text-text-secondary" />
                        <span className="text-sm text-text-secondary">Upload Audio Preview</span>
                        <input
                          type="file"
                          accept="audio/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setFormData(prev => ({ ...prev, audioPreview: file }));
                            }
                          }}
                          className="hidden"
                        />
                      </label>
                    )}
                  </div>
                )}  
              </div>
            </div>
          </div>

          {/* Submit Button */}
          {!viewMode && (
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                disabled={loading || !formData.name}
                className={`py-2.5 px-8 rounded-lg text-base font-medium transition-all ${
                  loading || !formData.name 
                    ? 'bg-gray-700 text-gray-400 cursor-not-allowed' 
                    : 'bg-accent-end text-white hover:bg-accent-end/90'
                }`}
              >
                {loading ? (
                  <div className="flex items-center justify-center gap-2">
                    <Spinner size="sm" />
                    <span>{isEditMode ? 'Updating...' : 'Creating...'}</span>
                  </div>
                ) : (
                  isEditMode ? 'Update Sound Pack' : 'Create Sound Pack'
                )}
              </button>
            </div>
          )}
        </form>

        {/* Sound Browsers Section */}
        {viewMode ? (
          includedSounds.length > 0 && (
            <div className="mt-8">
              <div className="mb-6">
                <div className="px-4 py-3">
                  <h2 className="text-2xl font-bold text-text-primary">Pack Sounds</h2>
                </div>
                <SoundBrowser 
                  key={includedSounds.map(s => s.id).join(',')}
                  title=""
                  isExplorePage={false}
                  isSelectable={false}
                  selectedSounds={formData.sounds}
                  onSoundSelect={handleSoundSelect}
                  additionalFilters={{ sound_pack_ids: soundpackId, sort_by_likes: true }}
                />
              </div>
            </div>
          )
        ) : (
          <div className="mt-8">
            {/* Selected Sounds Browser - Full Width */}
            <div className="mb-6">
              <div className="flex justify-between items-center px-4 py-3">
                <h2 className="text-2xl font-bold text-text-primary">Selected Sounds</h2>
                <button
                  type="button"
                  onClick={() => setSoundBrowserOpen(true)}
                  className="flex items-center gap-2 px-4 py-2 rounded-lg bg-accent-end text-white hover:bg-accent-end/90 transition-colors"
                >
                  <Plus className="w-4 h-4" />
                  <span>Add Sounds</span>
                </button>
              </div>
              
              {includedSounds.length > 0 ? (
                <SoundBrowser 
                  key={includedSounds.map(s => s.id).join(',')}
                  title=""
                  isExplorePage={false}
                  isSelectable={true}
                  selectedSounds={formData.sounds}
                  onSoundSelect={handleSoundSelect}
                  initialSounds={includedSounds}
                  hideSearchRow={true}
                  customRowRenderer={(sound) => ({
                    ...sound,
                    customActions: (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSoundSelect(sound);
                        }}
                        className="px-6 py-2 rounded-full text-sm font-medium bg-accent-end text-white hover:bg-accent-end/90 transition-colors"
                      >
                        Remove
                      </button>
                    )
                  })}
                />
              ) : (
                <div className="flex flex-col items-center justify-center py-16 px-4 text-center">
                  <Music className="w-12 h-12 text-text-secondary mb-4" />
                  <h3 className="text-xl font-medium text-text-primary mb-2">No sounds selected yet</h3>
                  <p className="text-text-secondary mb-6">Add sounds to your pack to get started</p>
                  <button
                    type="button"
                    onClick={() => setSoundBrowserOpen(true)}
                    className="flex items-center gap-2 px-6 py-3 rounded-lg bg-accent-end text-white hover:bg-accent-end/90 transition-colors"
                  >
                    <Plus className="w-5 h-5" />
                    <span>Browse Sounds</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        
        {/* Sound Browser Dialog */}
        <CustomDialog 
          isOpen={soundBrowserOpen} 
          onClose={() => setSoundBrowserOpen(false)} 
          title="Browse and Select Sounds"
          maxWidth="md:max-w-4xl"
        >
          <SoundBrowser 
            additionalFilters={{owner: user?.id}}
            isExplorePage={false}
            title=""
            isSelectable={true}
            selectedSounds={formData.sounds}
            onSoundSelect={handleSoundSelect}
            customRowRenderer={(sound) => {
              const isSelected = formData.sounds.some(s => s.id === sound.id);
              return {
                ...sound,
                customActions: (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSoundSelect(sound);
                    }}
                    className={`px-6 py-2 rounded-full text-sm font-medium transition-colors ${
                      isSelected 
                        ? 'bg-accent-end text-white hover:bg-accent-end/90' 
                        : 'bg-bg-secondary/50 text-text-primary hover:bg-accent-end/20'
                    }`}
                  >
                    {isSelected ? 'Remove' : 'Add to Pack'}
                  </button>
                )
              };
            }}
          />
        </CustomDialog>
      </div>
    </div>
  );
};

export default CreateSoundPackPage;