// components/Button/Button.jsx
import React from 'react';

const buttonVariants = {
  premium: {
    default: "bg-gradient-to-r from-accent-start to-accent-end text-white font-semibold px-3 py-1.5 sm:px-5 sm:py-1.5 text-sm sm:text-base rounded-full transition-all duration-200",
    active: "opacity-90"
  },
  primary: {
    default: "px-6 py-2 rounded-full text-sm font-medium bg-accent-end text-text-primary transition-all duration-200",
    active: "opacity-90"
  },
  secondary: {
    default: "px-6 py-2 bg-black text-white font-medium text-sm border border-white/20 rounded-full transition-all duration-200",
    active: "bg-[#2E1F40]"
  }
};

const Button = ({
  variant = 'primary',
  children,
  className,
  isLoading,
  ...props
}) => {
  const baseClasses = buttonVariants[variant]?.default;
  const hoverClasses = buttonVariants[variant]?.active;
  
  return (
    <button
      className={`${baseClasses} hover:${hoverClasses} ${className || ''}`}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
        </div>
      ) : children}
    </button>
  );
};

export default Button;