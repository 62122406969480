import React, { useState, useRef, useEffect } from 'react';
import { JUCESend, isJUCE, JUCEReceive } from '../../context/JUCE';
import { MoreHorizontal } from 'lucide-react';

const scales = [
    { major: 'C', minor: 'A' },
    { major: 'Db', minor: 'Bb' },
    { major: 'D', minor: 'B' },
    { major: 'Eb', minor: 'C' },
    { major: 'E', minor: 'Db' },
    { major: 'F', minor: 'D' },
    { major: 'Gb', minor: 'Eb' },
    { major: 'G', minor: 'E' },
    { major: 'Ab', minor: 'F' },
    { major: 'A', minor: 'Gb' },
    { major: 'Bb', minor: 'G' },
    { major: 'B', minor: 'Ab' }
];

const LockIcon = ({ locked }) => (
  <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
    {locked ? (
      <path d="M12 1C8.676 1 6 3.676 6 7v2H4v14h16V9h-2V7c0-3.324-2.676-6-6-6zm0 2c2.276 0 4 1.724 4 4v2H8V7c0-2.276 1.724-4 4-4z" />
    ) : (
      <path d="M12 1C8.676 1 6 3.676 6 7v2h4V7c0-1.276.724-2 2-2s2 .724 2 2v2h2V7c0-3.324-2.676-6-6-6zm-8 8v14h16V9H4zm8 7c1.276 0 2 .724 2 2s-.724 2-2 2-2-.724-2-2 .724-2 2-2z" />
    )}
  </svg>
);

const CustomSelect = ({ value, onChange, options, disabled = false, width = '120px', mobileFullWidth = false, dropdownPosition = 'bottom' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [dropdownStyle, setDropdownStyle] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      
      if (dropdownPosition === 'auto') {
        // Check if there's more space below or above
        const openBelow = spaceBelow >= 200 || spaceBelow >= spaceAbove;
        
        setDropdownStyle({
          position: 'absolute',
          [openBelow ? 'top' : 'bottom']: '100%',
          left: '0',
          marginTop: openBelow ? '4px' : '',
          marginBottom: !openBelow ? '4px' : '',
          maxHeight: '200px',
          overflowY: 'auto',
          width: '100%'
        });
      } else {
        setDropdownStyle({
          position: 'absolute',
          [dropdownPosition]: '100%',
          left: '0',
          marginTop: dropdownPosition === 'top' ? '' : '4px',
          marginBottom: dropdownPosition === 'bottom' ? '' : '4px',
          maxHeight: '200px',
          overflowY: 'auto',
          width: '100%'
        });
      }
    }
  }, [isOpen, dropdownPosition]);

  return (
    <div 
      className={`relative inline-block ${mobileFullWidth ? 'w-full sm:w-auto' : ''}`} 
      ref={selectRef} 
      style={{ width: mobileFullWidth ? undefined : width }}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
        className={`w-full bg-transparent backdrop-blur-sm border border-white/20 hover:border-accent-end/50 text-text-primary rounded-full px-4 py-1.5 text-xs focus:outline-none focus:ring-2 focus:ring-accent-end/50 transition-all duration-300 ease-in-out flex items-center justify-between shadow-md hover:shadow-lg ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        style={{ height: '36px' }}
      >
        <span>{options.find(opt => opt.value === value)?.label}</span>
        <svg className="w-4 h-4 text-text-secondary ml-1" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>
      {isOpen && (
        <div 
          className="bg-bg-secondary/95 backdrop-blur-md border border-white/20 rounded-2xl shadow-xl overflow-hidden z-[100]"
          style={dropdownStyle}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className={`p-2 hover:bg-accent-end hover:text-white cursor-pointer text-xs transition-colors duration-150 ease-in-out ${value === option.value ? 'bg-accent-end text-white' : 'text-text-primary'}`}
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CustomKeySelect = ({ value, onChange, options, disabled = false, width = '120px', mobileFullWidth = false, dropdownPosition = 'bottom' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [dropdownStyle, setDropdownStyle] = useState({});
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  // Add a constant for the scrollbar width
  const SCROLLBAR_WIDTH = 16; // Typical width for modern browsers

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      
      if (dropdownPosition === 'auto') {
        const openBelow = spaceBelow >= 200 || spaceBelow >= spaceAbove;
        
        setDropdownStyle({
          position: 'absolute',
          [openBelow ? 'top' : 'bottom']: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          marginTop: openBelow ? '4px' : '',
          marginBottom: !openBelow ? '4px' : '',
          maxHeight: '300px',
          overflowY: 'auto',
          width: options === scales ? '240px' : '100%'
        });
      } else {
        setDropdownStyle({
          position: 'absolute',
          [dropdownPosition]: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          marginTop: dropdownPosition === 'top' ? '' : '4px',
          marginBottom: dropdownPosition === 'bottom' ? '' : '4px',
          maxHeight: '300px',
          overflowY: 'auto',
          width: options === scales ? '240px' : '100%'
        });
      }
    }
  }, [isOpen, dropdownPosition, options]);


  // Handle scales dropdown
  const selectedScale = scales[value];
  const buttonLabel = selectedScale ? `${selectedScale.major} / ${selectedScale.minor}m` : 'Select Key';

  return (
    <div 
      className={`relative inline-block ${mobileFullWidth ? 'w-full sm:w-auto' : ''}`} 
      ref={selectRef} 
      style={{ width: mobileFullWidth ? undefined : width }}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
        className={`w-full bg-transparent backdrop-blur-sm border border-white/20 hover:border-accent-end/50 text-text-primary rounded-full px-4 py-1.5 text-xs focus:outline-none focus:ring-2 focus:ring-accent-end/50 transition-all duration-300 ease-in-out flex items-center justify-between shadow-md hover:shadow-lg ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        style={{ height: '36px' }}
      >
        <span>{buttonLabel}</span>
        <svg className="w-4 h-4 text-text-secondary ml-1" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>
      {isOpen && (
        <div 
          className="fixed bg-bg-secondary/95 backdrop-blur-md rounded-xl overflow-hidden z-[100000] flex flex-col"
          style={{
            ...dropdownStyle,
            maxHeight: '300px',
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.5), 0px 6px 16px rgba(0, 0, 0, 0.4)"
          }}
        >
          <div className="sticky top-0 z-10 bg-bg-secondary/95 backdrop-blur-md border-b border-white/10">
            <div className="grid grid-cols-2 divide-x divide-white/10" style={{ paddingRight: SCROLLBAR_WIDTH }}>
              <div className="px-6 py-2.5 text-center text-xs font-medium text-text-primary">
                Major
              </div>
              <div className="px-6 py-2.5 text-center text-xs font-medium text-text-primary">
                Minor
              </div>
            </div>
          </div>
          <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent">
            {scales.map((scale, index) => (
              <div 
                key={index}
                className="grid grid-cols-2 divide-x divide-white/5"
                onMouseEnter={() => setHoveredRow(index)}
                onMouseLeave={() => setHoveredRow(null)}
              >
                <div
                  className={`px-6 py-2.5 cursor-pointer text-xs transition-all duration-150 ease-in-out ${
                    value === index 
                      ? 'bg-accent-end text-white font-medium' 
                      : hoveredRow === index 
                      ? 'bg-white/5 text-white' 
                      : 'text-text-primary hover:bg-white/5'
                  }`}
                  onClick={() => {
                    onChange(index);
                    setIsOpen(false);
                  }}
                >
                  <div className="flex items-center justify-center space-x-2">
                    <span>{scale.major}</span>
                  </div>
                </div>
                <div
                  className={`px-6 py-2.5 cursor-pointer text-xs transition-all duration-150 ease-in-out ${
                    value === index 
                      ? 'bg-accent-end text-white font-medium' 
                      : hoveredRow === index 
                      ? 'bg-white/5 text-white' 
                      : 'text-text-primary hover:bg-white/5'
                  }`}
                  onClick={() => {
                    onChange(index);
                    setIsOpen(false);
                  }}
                >
                  <div className="flex items-center justify-center space-x-2">
                    <span>{scale.minor}m</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const MobileMenu = ({ speed, onSpeedChange, octave, onOctaveChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="h-9 w-9 bg-bg-secondary/80 backdrop-blur-sm border border-white/20 rounded-full hover:border-accent-end/50 flex items-center justify-center transition-all duration-200"
      >
        <MoreHorizontal className="w-5 h-5 text-text-primary" />
      </button>
      
      {isOpen && (
        <div className="fixed bottom-[56px] left-0 right-0 mx-4 bg-bg-secondary/95 backdrop-blur-md border border-white/20 rounded-md shadow-lg overflow-visible p-3 space-y-2 z-[100]">
          <div>
            <div className="text-xs text-text-secondary mb-1">Playback Speed</div>
            <CustomSelect
              value={speed}
              onChange={onSpeedChange}
              options={[
                { value: 0.5, label: 'Speed: 0.5x' },
                { value: 1, label: 'Speed: 1x' },
                { value: 2, label: 'Speed: 2x' }
              ]}
              mobileFullWidth
              dropdownPosition="top"
            />
          </div>
          <div>
            <div className="text-xs text-text-secondary mb-1">Octave</div>
            <CustomSelect
              value={octave}
              onChange={onOctaveChange}
              options={[
                { value: 1, label: 'Octave: +1' },
                { value: 0, label: 'Octave: 0' },
                { value: -1, label: 'Octave: -1' }
              ]}
              mobileFullWidth
              dropdownPosition="top"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const AudioControls = () => {
  const [selectedScale, setSelectedScale] = useState(0);
  const [isKeyLocked, setIsKeyLocked] = useState(false);
  const [tempo, setTempo] = useState(120);
  const [octave, setOctave] = useState(0);
  const [speed, setSpeed] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [isBpmLocked, setIsBpmLocked] = useState(false);
  const dragStartY = useRef(0);
  const dragStartTempo = useRef(0);

  const handleSpeedChange = (value) => {
    setSpeed(parseFloat(value));
    JUCESend('speedChanged', {value});
  };

  const handleOctaveChange = (value) => {
    setOctave(parseInt(value));
    JUCESend('octaveChanged', {value});
  };

  const handleScaleChange = (value) => {
    if (!isKeyLocked) {
      setSelectedScale(parseInt(value));
      JUCESend('scaleChanged', {value});
    }
  };

  const handleTempoChange = (value) => {
    if (!isBpmLocked) {
      const tempo = Math.max(40, Math.min(240, value));
      setTempo(tempo);
      JUCESend('tempoChanged', {value: tempo});
    }
  };

  const handleMouseDown = (e) => {
    if (!isBpmLocked) {
      setIsDragging(true);
      dragStartY.current = e.clientY;
      dragStartTempo.current = tempo;
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && !isBpmLocked) {
      const dragDelta = dragStartY.current - e.clientY;
      const tempoDelta = Math.round(dragDelta / 2);
      handleTempoChange(dragStartTempo.current + tempoDelta);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      // console.log(eventData);
      if (eventName === 'bpm') {
        setTempo(parseInt(eventData));
        setIsBpmLocked(true);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
      
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
    };
  }, [isDragging]);

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50">
      <div className="relative h-16 pt-2 bg-bg-secondary/80 backdrop-blur-sm border-t border-white/10 shadow-lg">
        <div className="h-full w-full flex items-center">
          {/* BPM Controls - Far Left Side */}
          <div className="flex items-center h-9 pl-4 sm:pl-6">
            <div 
              className={`flex items-center justify-center bg-bg-secondary/95 ${isBpmLocked ? 'border-0' : 'border border-white/20'} text-text-primary ${!isBpmLocked ? 'rounded-l-full' : 'rounded-full'} h-full px-4 shadow-md hover:shadow-lg ${isBpmLocked ? 'cursor-not-allowed' : 'cursor-ns-resize hover:border-accent-end/50'} select-none transition-all duration-200`}
              onMouseDown={handleMouseDown}
            >
              <span className="text-sm font-medium mr-1 w-8 text-right">{tempo}</span>
              <span className="text-xs text-text-secondary whitespace-nowrap">BPM</span>
            </div>
            {!isBpmLocked && (
              <>
                <button
                  onClick={() => handleTempoChange(tempo + 1)}
                  className={`h-full w-8 bg-bg-secondary/95 border-t border-b border-white/20 text-text-primary hover:bg-accent-end hover:text-white hover:border-accent-end/50 focus:ring-2 focus:ring-accent-end transition-all duration-200 text-xs shadow-md hover:shadow-lg flex items-center justify-center`}
                >
                  ▲
                </button>
                <button
                  onClick={() => handleTempoChange(tempo - 1)}
                  className={`h-full w-8 bg-bg-secondary/95 border border-white/20 text-text-primary hover:bg-accent-end hover:text-white hover:border-accent-end/50 focus:ring-2 focus:ring-accent-end transition-all duration-200 text-xs rounded-r-full shadow-md hover:shadow-lg flex items-center justify-center`}
                >
                  ▼
                </button>
              </>
            )}
          </div>

          {/* Center Section with Dropdowns - Left on mobile */}
          <div className="flex flex-grow sm:flex-grow-0 items-center space-x-3 sm:space-x-4 ml-4 sm:mx-auto sm:justify-center">
            {/* Key/Scale Controls - First Dropdown */}
            <div className="flex items-center">
              <CustomKeySelect
                value={selectedScale}
                onChange={handleScaleChange}
                options={scales}
                disabled={isKeyLocked}
                width="140px"
              />
              <button
                onClick={() => setIsKeyLocked(!isKeyLocked)}
                className={`hidden ml-1 h-9 w-9 ${
                  isKeyLocked 
                    ? 'bg-accent-end text-white border-accent-end/50' 
                    : 'bg-transparent text-text-primary border-white/20'
                } rounded-full hover:bg-accent-end focus:ring-2 focus:ring-accent-end transition-all duration-200 flex items-center justify-center border backdrop-blur-sm shadow-md hover:shadow-lg`}
              >
                <LockIcon locked={isKeyLocked} />
              </button>
            </div>

            {/* Mobile Settings Menu - Hidden on Desktop */}
            <div className="block sm:hidden">
              <MobileMenu
                speed={speed}
                onSpeedChange={handleSpeedChange}
                octave={octave}
                onOctaveChange={handleOctaveChange}
              />
            </div>

            {/* Speed Control - Hidden on Mobile */}
            <div className="hidden sm:block">
              <CustomSelect
                value={speed}
                onChange={handleSpeedChange}
                options={[
                  { value: 0.5, label: 'Speed: 0.5x' },
                  { value: 1, label: 'Speed: 1x' },
                  { value: 2, label: 'Speed: 2x' }
                ]}
                width="120px"
              />
            </div>

            {/* Octave Control - Hidden on Mobile */}
            <div className="hidden sm:block">
              <CustomSelect
                value={octave}
                onChange={handleOctaveChange}
                options={[
                  { value: 1, label: 'Octave: +1' },
                  { value: 0, label: 'Octave: 0' },
                  { value: -1, label: 'Octave: -1' }
                ]}
                width="120px"
              />
            </div>
          </div>

          {/* Right Side - Empty for balance */}
          <div className="pr-4 sm:pr-6">
            {/* This is intentionally empty to balance the layout */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioControls;