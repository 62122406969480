import React, { useState, useRef, useMemo, useCallback, useEffect, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { LucidePlay, LucideSquare, LucideUpload,LucideStar } from 'lucide-react';
import { VALID_KEYS, VALID_STATUS } from './constants';
import { CustomMultiSelect } from '../../components/CustomMultiSelect';
import  Button  from '../../components/Button';
import { AuthContext } from '../../context/AuthContext';
import { AlertCircle } from 'lucide-react';

const RequiredFieldsWarning = ({ files, editMode }) => {
    if (editMode) return null;
  
    // Check which required fields are missing from any file
    const missingFields = files.reduce((acc, file) => {
      if (!file.image && !file.imagePreview && !acc.includes('Image')) acc.push('Image');
      if (!file.name && !acc.includes('Name')) acc.push('Name');
      if (!file.typeId && !acc.includes('Type')) acc.push('Type');
      if (!file.license && !acc.includes('License')) acc.push('License');
      return acc;
    }, []);
  
    if (missingFields.length === 0) return null;
  
    return (
    <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4 mb-4">
      <div className="flex items-start gap-3">
        <AlertCircle className="text-red-500 w-5 h-5 mt-0.5" />
        <div className="flex-1">
          <h3 className="text-red-500 font-semibold mb-2">Required Fields</h3>
          <div className="text-sm space-y-1">
            <p className="text-gray-300">The following fields must be filled out before uploading:</p>
            <ul className="list-disc list-inside text-gray-400 ml-1">
              {missingFields.map(field => (
                <li key={field}>{field}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
  const RequiredIndicator = () => (
    <span className="text-red-500 font-bold text-lg ml-0.5 align-top">*</span>
  );
  
  
const ProgressBar = ({ files = [], selectedFiles = [], isUploading, editMode = false }) => {
  // Only process selected files
  const selectedFileIds = new Set(selectedFiles.map(f => f.id));
  const filesToProcess = files.filter(f => selectedFileIds.has(f.id));
  
  // Count files by status (only for selected files)
  const successCount = filesToProcess.filter(f => f.uploadStatus === 'success').length;
  const errorCount = filesToProcess.filter(f => f.uploadStatus === 'error').length;
  const cancelledCount = filesToProcess.filter(f => f.uploadStatus === 'cancelled').length;
  const uploadingCount = filesToProcess.filter(f => f.uploadStatus === 'uploading').length;
  const pendingCount = filesToProcess.filter(f => f.uploadStatus === 'pending').length;
  const totalCount = filesToProcess.length;
  const totalFiles = files.length;

  // Calculate percentages for progress bar segments
  const successPercent = totalCount ? (successCount / totalCount) * 100 : 0;
  const errorPercent = totalCount ? (errorCount / totalCount) * 100 : 0;
  const uploadingPercent = totalCount ? (uploadingCount / totalCount) * 100 : 0;
  const cancelledPercent = totalCount ? (cancelledCount / totalCount) * 100 : 0;

  return (
    <div className="w-full space-y-2">
      {/* Progress bar */}
      <div className="h-2 w-full rounded-full overflow-hidden">
        <div className="h-full flex">
          <div 
            style={{ width: `${successPercent}%` }}
            className="bg-green-500 transition-all duration-300"
          />
          <div 
            style={{ width: `${uploadingPercent}%` }}
            className="bg-accent-end transition-all duration-300"
          />
          <div 
            style={{ width: `${errorPercent}%` }}
            className="bg-red-500 transition-all duration-300"
          />
          <div 
            style={{ width: `${cancelledPercent}%` }}
            className="bg-yellow-500 transition-all duration-300"
          />
        </div>
      </div>

      {/* Status text */}
      <div className="flex justify-between items-center text-sm">
        <div className="flex gap-4">
          <span>Total: {totalFiles}</span>
          <span>Selected: {totalCount}</span>
          <span className="text-green-500">
            {editMode ? 'Updated: ' : 'Success: '}{successCount}
          </span>
          {(uploadingCount > 0 || isUploading) && (
            <span className="text-accent-end">
              {editMode ? 'Updating: ' : 'Uploading: '}{uploadingCount}
            </span>
          )}
          {(errorCount > 0 || isUploading) && (
            <span className="text-red-500">Failed: {errorCount}</span>
          )}
          {(cancelledCount > 0 || isUploading) && (
            <span className="text-yellow-500">
              Cancelled: {cancelledCount}
            </span>
          )}
          {(pendingCount > 0 || isUploading) && (
            <span className="text-gray-500">
              Pending: {pendingCount}
            </span>
          )}
        </div>
        {isUploading && totalCount > 0 && (
          <span className="text-accent-end">
            {Math.round((successCount / totalCount) * 100)}% Complete
          </span>
        )}
      </div>
    </div>
  );
};

  
const BatchUploadTable = ({
    files,
    setFiles,
    soundTypes,
    availableTags,
    setAvailableTags,       // Add these setState functions
    availableGenres,
    setAvailableGenres,     // Add these setState functions
    availableInstruments,
    setAvailableInstruments, // Add these setState functions
    soundpacks,
    setSoundpacks,          // Add these setState functions
    licenses,
    createSound,
    createTag,          // Add these props
    createGenre,        // Add these props
    createInstrument,   // Add these props
    createSoundpack,    // Add new prop for soundpack creation
    setErrorMessage,
    editMode = false,
    handleCancelUpload,
    uploadCancelRef,
    handleUpload,
    setCurrentPlayingSound,
    setIsPlaying,
    currentPlayingSound,
    isPlaying
}) => {
    const gridRef = useRef();
    const [isUploading, setIsUploading] = useState(false);
    const [uploadCancelled, setUploadCancelled] = useState(false);
    const filesRef = useRef(files);
    const [refreshKey, setRefreshKey] = useState(0);
    // Add this state to BatchUploadTable component
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { user } = useContext(AuthContext);
    // Add window width state for responsive behavior
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 768; // Common breakpoint for mobile
    
    // Add resize listener for responsive behavior
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        filesRef.current = files;
    }, [files]);

    const [batchEditRow, setBatchEditRow] = useState({
        id: 'batch-edit',
        name: '',
        description: '',
        tags: [],
        genres: [],
        instruments: [],
        typeId: '',
        bpm: '',
        key: '',
        status: '',
        costInCredits: '',
        license: '',
        sound_packs: [],
        uploadStatus: 'batch-edit'
    });

    const createOptions = (items, valueKey = 'id', labelKey = 'name') => {
        return items.map(item => ({
            id: item[valueKey] || item,
            name: item[labelKey] || item
        }));
    };

    // Create wrapper functions for creation that update the available options
    const handleTagCreate = async (tagData) => {
        try {
            const newTag = await createTag(tagData);
            if (newTag) {
                setAvailableTags(prev => [...prev, newTag]);
            }
            return newTag;
        } catch (error) {
            setErrorMessage(`Failed to create tag: ${error.message}`);
            return null;
        }
    };

    const handleGenreCreate = async (genreData) => {
        try {
            const newGenre = await createGenre(genreData);
            if (newGenre) {
                setAvailableGenres(prev => [...prev, newGenre]);
            }
            return newGenre;
        } catch (error) {
            setErrorMessage(`Failed to create genre: ${error.message}`);
            return null;
        }
    };

    const handleInstrumentCreate = async (instrumentData) => {
        try {
            const newInstrument = await createInstrument(instrumentData);
            if (newInstrument) {
                setAvailableInstruments(prev => [...prev, newInstrument]);
            }
            return newInstrument;
        } catch (error) {
            setErrorMessage(`Failed to create instrument: ${error.message}`);
            return null;
        }
    };

    const handleSoundpackCreate = async (soundpackData) => {
        try {
            const newSoundpack = await createSoundpack(soundpackData);
            if (newSoundpack) {
                setSoundpacks(prev => [...prev, newSoundpack]);
            }
            return newSoundpack;
        } catch (error) {
            setErrorMessage(`Failed to create soundpack: ${error.message}`);
            return null;
        }
    };

    const handleBatchEdit = (field, newValue) => {
        setBatchEditRow(prev => ({ ...prev, [field]: newValue }));
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
            setFiles(prevFiles => prevFiles.map(file => {
                if (selectedNodes.some(node => node.data.id === file.id)) {
                    return { ...file, [field]: newValue };
                }
                return file;
            }));
        }
    };

    const handleIndividualEdit = (id, field, newValue) => {
        setFiles(prevFiles => prevFiles.map(file => {
            if (file.id === id) {
                return { ...file, [field]: newValue };
            }
            return file;
        }));
        // Select the row that was just edited
        const node = gridRef.current.api.getRowNode(id);
        if (node && !node.isSelected()) {
            node.setSelected(true);
        }

    };

    const handleValueChange = (params, newValue, field) => {
        if (params.data.id === 'batch-edit') {
            handleBatchEdit(field, newValue);
        } else {
            handleIndividualEdit(params.data.id, field, newValue);
        }
    };
    const onCellFocused = useCallback((event) => {
        const focusedCell = event.api.getFocusedCell();
        if (!focusedCell) return;
        
        // Check if the focused cell is in the audio preview column
        if (focusedCell.column.getColId() === 'preview') {
            const node = event.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
            if (node?.data && node.data.id !== 'batch-edit') {
                // Create sound object and trigger play
                const soundObj = {
                    id: node.data.id,
                    name: node.data.name,
                    audio_preview: node.data.preview,
                    image: node.data.imagePreview
                };
                
                if (currentPlayingSound?.id !== node.data.id) {
                    setCurrentPlayingSound(soundObj);
                    setIsPlaying(true);
                }
            }
        }
    }, [currentPlayingSound, setCurrentPlayingSound, setIsPlaying]);

// Add this callback function to handle selection changes
const onSelectionChanged = useCallback(() => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes
        .map(node => node.data)
        .filter(data => data.id !== 'batch-edit');
    setSelectedFiles(selectedData);
}, []);

const onGridReady = useCallback((params) => {
    if (!editMode) {
        params.api.selectAll();
        // Immediately trigger selection changed to update selectedFiles
        const selectedNodes = params.api.getSelectedNodes();
        const selectedData = selectedNodes
            .map(node => node.data)
            .filter(data => data.id !== 'batch-edit');
        setSelectedFiles(selectedData);
    }
    
    params.api.addEventListener('rowSelected', (event) => {
        if (event.node.data.id === 'batch-edit') {
            event.node.setSelected(false);
        }
    });
    
    params.api.addEventListener('cellFocused', onCellFocused);
    params.api.addEventListener('selectionChanged', onSelectionChanged);
    
    params.api.sizeColumnsToFit();
}, [editMode, onCellFocused, onSelectionChanged]);

// In BatchUploadTable.js, add this useEffect to handle file changes:
useEffect(() => {
    if (!editMode && gridRef.current?.api) {
        gridRef.current.api.selectAll();
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedData = selectedNodes
            .map(node => node.data)
            .filter(data => data.id !== 'batch-edit');
        setSelectedFiles(selectedData);
    }
}, [files, editMode]);


    const AudioPreviewRenderer = useCallback(({ data }) => {
        if (data.id === 'batch-edit') return null;

        const isCurrentlyPlaying = currentPlayingSound?.id === data.id && isPlaying;
        
        const handlePlayPause = (e) => {
            e.stopPropagation();
            
            if (currentPlayingSound?.id === data.id) {
                setIsPlaying(!isPlaying);
            } else {
                const soundObj = {
                    id: data.id,
                    name: data.name,
                    audio_preview: data.preview,
                    image: data.imagePreview
                };
                setCurrentPlayingSound(soundObj);
                setIsPlaying(true);
            }
        };

        return (
            <div className="flex items-center gap-2">
                <button
                    onClick={handlePlayPause}
                    className="p-1 hover:bg-accent-start/20 rounded"
                >
                    {isCurrentlyPlaying ? <LucideSquare size={16} /> : <LucidePlay size={16} />}
                </button>
            </div>
        );
    }, [currentPlayingSound, isPlaying, setCurrentPlayingSound, setIsPlaying]);

    const ImageRenderer = useCallback(({ data }) => {
        const handleImageChange = (e) => {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (data.id === 'batch-edit') {
                        const selectedNodes = gridRef.current.api.getSelectedNodes();
                        selectedNodes.forEach(node => {
                            handleIndividualEdit(node.data.id, 'image', file);
                            handleIndividualEdit(node.data.id, 'imagePreview', reader.result);
                        });
                    } else {
                        handleIndividualEdit(data.id, 'image', file);
                        handleIndividualEdit(data.id, 'imagePreview', reader.result);
                    }
                };
                reader.readAsDataURL(file);
            }
        };
    
        if (data.id === 'batch-edit') {
            return (
                <div className="flex items-center justify-center h-full w-full">
                    <button 
                        onClick={() => document.getElementById('batch-image-upload').click()}
                        className="p-2 bg-accent-end hover:bg-accent-start/80 rounded flex items-center gap-2"
                    >
                        <LucideUpload size={16} />
                    </button>
                    <input
                        id="batch-image-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="hidden"
                    />
                </div>
            );
        }
    
        return (
            <div 
                className="h-full w-full flex items-center justify-center cursor-pointer relative group"
                onClick={() => document.getElementById(`image-upload-${data.id}`).click()}
            >
                {data.imagePreview ? (
                    <div className="relative flex items-center justify-center w-full h-full">
                        <img 
                            src={data.imagePreview} 
                            alt="Preview" 
                            className="max-h-8 w-auto object-contain"
                        />
                        <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity">
                            <LucideUpload size={10} />
                        </div>
                    </div>
                ) : (
                    <button className="p-2 bg-accent-end hover:bg-accent-start/80 rounded">
                        <LucideUpload size={10} />
                    </button>
                )}
                <input
                    id={`image-upload-${data.id}`}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                />
            </div>
        );
    }, []);

    const FeaturedRenderer = useCallback(({ data }) => {
        if (!editMode || data.id === 'batch-edit') return null;

        const handleToggleFeatured = (e) => {
            e.stopPropagation();
            handleIndividualEdit(data.id, 'featured', !data.featured);
        };

        return (
            <button
                onClick={handleToggleFeatured}
                className={`p-2 rounded-full transition-colors duration-200 ${
                    data.featured 
                        ? 'text-yellow-400 hover:bg-yellow-400/20' 
                        : 'text-gray-400 hover:bg-gray-400/20'
                }`}
            >
                <LucideStar size={16} fill={data.featured ? 'currentColor' : 'none'} />
            </button>
        );
    }, [editMode, handleIndividualEdit]);

    const StatusRenderer = useCallback(({ data }) => {
        const getStatusColor = (status, isAnalyzing) => {
            if (isAnalyzing) return 'text-blue-500';
            switch (status) {
                case 'success': return 'text-green-500';
                case 'error': return 'text-red-500';
                case 'uploading': return 'text-accent-end';
                case 'cancelled': return 'text-yellow-500';
                default: return 'text-gray-500';
            }
        };

        const handleRetryClick = async () => {
            await handleRetry(data.id);
        };

        return (
            <div className={`flex items-center text-xs gap-2 ${getStatusColor(data.uploadStatus, data.isAnalyzing)}`}>
                {data.isAnalyzing ? (
                    <span>Analyzing...</span>
                ) : (
                    <>
                        {(data.uploadStatus === 'error' || data.uploadStatus === 'cancelled') && (
                            <button
                                onClick={handleRetryClick}
                                className="p-1 hover:bg-accent-start/20 rounded"
                            >
                                Retry
                            </button>
                        )}
                        <span className="capitalize">{data.uploadStatus}</span>
                    </>
                )}
            </div>
        );
    }, []);

    const columnDefs = useMemo(() => [
        {
            headerName: '',
            field: 'selected',
            width: 22,
            checkboxSelection: params => params.data.id !== 'batch-edit',
            headerCheckboxSelection: true,
            pinned: isMobile ? null : 'left',
            suppressSizeToFit: true,
            suppressAutoSize: true,
            resizable: false,
            cellStyle: {
                justifyContent: 'start',
            }
        },
        {
            headerName: 'Image *',
            headerComponent: () => (
                <div className="flex items-center gap-1 font-semibold">
                    <span>Image</span>
                    <RequiredIndicator />
                </div>
            ),
            field: 'image',
            width: 80,
            cellRenderer: ImageRenderer,
            sortable: false,
            filter: false,
            pinned: isMobile ? null : 'left',
            suppressSizeToFit: true,
            cellStyle: params => {
                if (params.data.id === 'batch-edit') return {};
                if (!params.data.image && !params.data.imagePreview) {
                    return { 
                        backgroundColor: 'transparent',
                        border: '2px solid rgba(220, 38, 38, 0.5)',
                        boxShadow: 'inset 0 0 0 1px rgba(220, 38, 38, 0.2)'
                    };
                }
                return {};
            },
        },
        {
            headerName: 'Name',
            field: 'name',
            width: 200,
            pinned: isMobile ? null : 'left',
            suppressSizeToFit: true,
            cellRenderer: params => (
                <input
                    type="text"
                    value={params.value || ''}
                    onChange={(e) => handleValueChange(params, e.target.value, 'name')}
                    className="w-full bg-transparent border-none focus:outline-none grid-cell-input"
                    placeholder={params.data.id === 'batch-edit' ? 'Bulk Edit' : ''}
                />
            )
        },
        {
            headerName: 'Audio',
            field: 'preview',
            width: 44,
            cellRenderer: AudioPreviewRenderer,
            pinned: isMobile ? null : 'left',
            sortable: false,
            filter: false,
            suppressSizeToFit: true,
            resizable: true
        },
        {
            headerName: 'Upload Status',
            field: 'uploadStatus',
            width: 70,
            cellRenderer: StatusRenderer,
            pinned: isMobile ? null : 'left',
            suppressSizeToFit: true
        },
        {
            headerName: 'Type *',
            headerComponent: () => (
                <div className="flex items-center gap-1 font-semibold">
                    <span>Type</span>
                    <RequiredIndicator />
                </div>
            ),
            field: 'typeId',
            width: 170,
            suppressSizeToFit: true,
            cellRenderer: params => (
                <div className="w-full h-full flex items-center p-2">
                    <CustomMultiSelect
                        options={soundTypes}
                        value={params.value && soundTypes ? [soundTypes.find(type => type.id === params.value)].filter(Boolean) : []}
                        onChange={(newValue) => handleValueChange(params, newValue[0]?.id || '', 'typeId')}
                        placeholder={params.data.id === 'batch-edit' ? 'Type' : 'Type *'}
                        isMulti={false}
                        className="text-sm"
                    />
                </div>
            ),
            cellStyle: params => {
                if (params.data.id === 'batch-edit') return {};
                if (!params.data.typeId) {
                    return { 
                        backgroundColor: 'transparent',
                        border: '2px solid rgba(220, 38, 38, 0.5)',
                        boxShadow: 'inset 0 0 0 1px rgba(220, 38, 38, 0.2)'
                    };
                }
                return {};
            },
        },
        {
            headerName: 'License *',
            headerComponent: () => (
                <div className="flex items-center gap-1 font-semibold">
                    <span>License</span>
                    <RequiredIndicator />
                </div>
            ),
            field: 'license',
            width: 190,
            suppressSizeToFit: true,
            cellRenderer: params => (
                <div className="w-full h-full flex items-center p-2">
                    <CustomMultiSelect
                        options={licenses}
                        value={params.value && licenses ? [licenses.find(license => license.id === params.value)].filter(Boolean) : []}
                        onChange={(newValue) => handleValueChange(params, newValue[0]?.id || '', 'license')}
                        placeholder={params.data.id === 'batch-edit' ? 'License' : 'Select license *'}
                        isMulti={false}
                        className="text-sm"
                    />
                </div>
            ),
            cellStyle: params => {
                if (params.data.id === 'batch-edit') return {};
                if (!params.data.license) {
                    return { 
                        backgroundColor: 'transparent',
                        border: '2px solid rgba(220, 38, 38, 0.5)',
                        boxShadow: 'inset 0 0 0 1px rgba(220, 38, 38, 0.2)'
                    };
                }
                return {};
            },
        },
        {
            headerName: 'Featured',
            field: 'featured',
            width: 60,
            cellRenderer: FeaturedRenderer,
            hide: !editMode,
            sortable: true,
            filter: true,
            suppressSizeToFit: true
        },
        {
            headerName: 'Key',
            field: 'key',
            width: 170,
            suppressSizeToFit: true,
            cellRenderer: params => (
<div className="w-full h-full flex items-center p-2">
    <CustomMultiSelect
        options={VALID_KEYS.map(key => ({
            id: key,
            name: key === ' ' ? 'None' : key
        }))}
        value={[{
            id: params.value || ' ',
            name: (params.value === ' ' || !params.value) ? 'None' : params.value
        }]}
        onChange={(newValue) => handleValueChange(params, newValue[0]?.id || ' ', 'key')}
        placeholder={params.data.id === 'batch-edit' ? 'Key' : 'Key'}
        isMulti={false}
        className="text-sm"
    />
</div>
            )
        },
        {
            headerName: 'BPM',
            field: 'bpm',
            width: 72,
            suppressSizeToFit: true,
            cellRenderer: params => (
                <input
                    type="number"
                    value={params.value || ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (Number(value) >= 0 && !isNaN(value))) {
                            handleValueChange(params, value, 'bpm');
                        }
                    }}
                    className="w-full bg-transparent border-none focus:outline-non p-2"
                    placeholder={params.data.id === 'batch-edit' ? 'BPM' : ''}
                />
            )
        },
        {
            headerName: 'Instruments',
            field: 'instruments',
            width: 300,
            suppressSizeToFit: true,
            cellRenderer: params => (
                <div className="w-full h-full flex items-center p-2">
                    <CustomMultiSelect
                        options={availableInstruments}
                        value={(params.value || []).filter(Boolean)}
                        onChange={(newValue) => handleValueChange(params, newValue, 'instruments')}
                        placeholder={params.data.id === 'batch-edit' ? 'Instruments' : 'Instruments'}
                        isMulti={true}
                        className="text-sm"
                        creatable={user?.admin}
                        onCreate={handleInstrumentCreate}
                    />
                </div>
            )
        },
        {
            headerName: 'Genres',
            field: 'genres',
            width: 300,
            suppressSizeToFit: true,
            cellRenderer: params => (
                <div className="w-full h-full flex items-center p-2">
                    <CustomMultiSelect
                        options={availableGenres}
                        value={(params.value || []).filter(Boolean)}
                        onChange={(newValue) => handleValueChange(params, newValue, 'genres')}
                        placeholder={params.data.id === 'batch-edit' ? 'Genres' : 'Genres'}
                        isMulti={true}
                        className="text-sm"
                        creatable={user?.admin}
                        onCreate={handleGenreCreate}
                    />
                </div>
            )
        },
        {
            headerName: 'Tags',
            field: 'tags',
            width: 300,
            suppressSizeToFit: true,
            cellRenderer: params => (
                <div className="w-full h-full flex items-center p-2">
                    <CustomMultiSelect
                        options={availableTags}
                        value={(params.value || []).filter(Boolean)}
                        onChange={(newValue) => handleValueChange(params, newValue, 'tags')}
                        placeholder={params.data.id === 'batch-edit' ? 'Tags' : 'Tags'}
                        isMulti={true}
                        className="text-sm"
                        creatable={user?.admin}
                        onCreate={handleTagCreate}
                    />
                </div>
            )
        },
        {
            headerName: 'Soundpack',
            field: 'sound_packs',
            width: 190,
            suppressSizeToFit: true,
            hide: !editMode, // Hide the column in batch upload mode
            cellRenderer: params => (
                <div className="w-full h-full flex items-center p-2">
                    <CustomMultiSelect
                        options={soundpacks}
                        value={(params.value || []).filter(Boolean)}
                        onChange={(newValue) => handleValueChange(params, newValue, 'sound_packs')}
                        placeholder={params.data.id === 'batch-edit' ? 'Soundpack' : 'Select soundpack'}
                        isMulti={true}
                        className="text-sm"
                        creatable={!!createSoundpack}
                        onCreate={handleSoundpackCreate}
                    />
                </div>
            )
        },
        {
            headerName: 'Cost',
            field: 'costInCredits',
            width: 100,
            suppressSizeToFit: true,
            cellRenderer: params => (
                <input
                    type="number"
                    value={params.value || ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (Number(value) >= 0 && !isNaN(value))) {
                            handleValueChange(params, value, 'costInCredits');
                        }
                    }}
                    className="w-full bg-transparent border-none focus:outline-none p-4"
                    placeholder={params.data.id === 'batch-edit' ? 'Cost' : ''}
                />
            )
        },
        {
            headerName: 'Status',
            field: 'status',
            width: 160,
            suppressSizeToFit: true,
            cellRenderer: params => (
                <div className="w-full h-full flex items-center p-2">
                    <CustomMultiSelect
                        options={createOptions(VALID_STATUS)}
                        value={params.value ? [{ id: params.value, name: params.value }].filter(Boolean) : []}
                        onChange={(newValue) => handleValueChange(params, newValue[0]?.id || '', 'status')}
                        placeholder={params.data.id === 'batch-edit' ? 'Status' : 'Status'}
                        isMulti={false}
                        className="text-sm"
                    />
                </div>
            )
        },
        {
            headerName: 'Description',
            field: 'description',
            width: 200,
            suppressSizeToFit: true,
            cellRenderer: params => (
                <input
                    type="text"
                    value={params.value || ''}
                    onChange={(e) => handleValueChange(params, e.target.value, 'description')}
                    className="w-full bg-transparent border-none focus:outline-none"
                    placeholder={params.data.id === 'batch-edit' ? 'Description' : ''}
                />
            )
        }
    ], [availableTags, availableGenres, availableInstruments, soundTypes, licenses, currentPlayingSound, isPlaying, AudioPreviewRenderer, ImageRenderer, StatusRenderer, soundpacks, isMobile]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        resizable: true,
        suppressMovable: true,
        enableRangeSelection: true,
        rangeSelection: true,
        suppressSizeToFit: true,
        minWidth: 10,
    }), []);

    const validateFile = useCallback((file) => {
        if (!file.typeId) {
            setErrorMessage(`Sound type is required for ${file.name}`);
            return false;
        }
        if (!file.license) {
            setErrorMessage(`License is required for ${file.name}`);
            return false;
        }
        if (!file.image && !file.imagePreview) {
            setErrorMessage(`Image is required for ${file.name}`);
            return false;
        }
        return true;
    }, [setErrorMessage]);

    const handleRetry = useCallback(async (id) => {
        uploadCancelRef.current = false;
        setRefreshKey(prevKey => prevKey + 1);
    
        const file = filesRef.current.find(f => f.id === id);
        if (!file) return;
    
        // Check if the file has exceeded retry attempts
        if (file.retryCount >= 3) {
            setErrorMessage(`Maximum retry attempts exceeded for ${file.name}`);
            return;
        }
    
        if (!validateFile(file)) {
            setErrorMessage(`Please fill in required fields for ${file.name}`);
            return;
        }
    
        setFiles(prevFiles =>
            prevFiles.map(f =>
                f.id === id 
                    ? { 
                        ...f, 
                        uploadStatus: 'uploading',
                        retryCount: (f.retryCount || 0) + 1 
                    } 
                    : f
            )
        );
    
        try {
            const result = await handleUpload(file);
    
            if (result && (result.success || result.id)) {
                setFiles(prevFiles =>
                    prevFiles.map(f =>
                        f.id === id ? { ...f, uploadStatus: 'success' } : f
                    )
                );
                return;
            }
            
            throw new Error("Update/Upload failed");
        } catch (error) {
            console.error(`Retry error for ${file.name}:`, error);
    
            setFiles(prevFiles =>
                prevFiles.map(f =>
                    f.id === id ? { 
                        ...f, 
                        uploadStatus: 'error',
                        error: error.message || 'Upload failed'
                    } : f
                )
            );
            setErrorMessage(`Retry failed for ${file.name}`);
        } finally {
            setRefreshKey(prevKey => prevKey + 1);
        }
    }, [filesRef, handleUpload, setErrorMessage, setFiles, uploadCancelRef, validateFile]);
    
    const handleUploadSelected = useCallback(async () => {
        uploadCancelRef.current = false;
        let failureCount = 0;
        let processedFiles = new Set();
        
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedFiles = selectedNodes
            .map(node => node.data)
            .filter(file => 
                file.uploadStatus !== 'success' && 
                !processedFiles.has(file.id) && 
                (file.retryCount || 0) < 3
            );
    
        // Reset statuses for cancelled/error files
        setFiles(prevFiles => prevFiles.map(f => {
            if (selectedFiles.some(sf => sf.id === f.id)) {
                if (f.uploadStatus === 'cancelled' || f.uploadStatus === 'error') {
                    return { 
                        ...f, 
                        uploadStatus: 'pending',
                        retryCount: 0 // Reset retry count for new upload attempt
                    };
                }
                return f;
            }
            return f;
        }));
    
        setIsUploading(true);
    
        // Process files in chunks of 2
        for (let i = 0; i < selectedFiles.length; i += 2) {
            if (uploadCancelRef.current || failureCount >= 3) break;
    
            const currentBatch = selectedFiles.slice(i, i + 2);
            const uploadPromises = currentBatch
                .filter(file => !processedFiles.has(file.id))
                .map(async (file) => {
                    try {
                        if (!validateFile(file)) {
                            throw new Error(`Validation failed for ${file.name}`);
                        }
    
                        processedFiles.add(file.id);
                        setFiles(prevFiles => prevFiles.map(f =>
                            f.id === file.id 
                                ? { 
                                    ...f, 
                                    uploadStatus: 'uploading',
                                    retryCount: (f.retryCount || 0) + 1
                                } 
                                : f
                        ));
    
                        const result = await handleUpload(file);
    
                        if (result && (result.success || result.id)) {
                            setFiles(prevFiles => prevFiles.map(f =>
                                f.id === file.id ? { ...f, uploadStatus: 'success' } : f
                            ));
                            return true;
                        } else {
                            throw new Error(`Upload failed for ${file.name}`);
                        }
                    } catch (error) {
                        console.error(`Error uploading ${file.name}:`, error);
                        failureCount++;
                        setFiles(prevFiles => prevFiles.map(f =>
                            f.id === file.id 
                                ? { 
                                    ...f, 
                                    uploadStatus: 'error',
                                    error: error.message || 'Upload failed'
                                } 
                                : f
                        ));
                        return false;
                    }
                });
    
            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error('Batch upload error:', error);
            }
    
            if (failureCount >= 3) {
                handleCancelUpload();
                setErrorMessage('Upload cancelled automatically after 3 failures');
                break;
            }
        }
    
        setIsUploading(false);
    }, [gridRef, handleCancelUpload, handleUpload, setErrorMessage, setFiles, uploadCancelRef, validateFile]);
    
    const getRowStyle = useCallback((params) => {
        if (params.data?.id === 'batch-edit') {
            return { 
                backgroundColor: 'transparent',
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
            };
        }
        return { 
            backgroundColor: 'transparent'
        };
    }, []);

    return (
        <div className="flex flex-col h-full bg-transparent rounded-lg">
            <div className="flex-none sticky top-0 z-30 flex flex-col gap-4 p-4 bg-transparent rounded-t-lg">
                <RequiredFieldsWarning files={files} editMode={editMode} />
                
                <div className="flex justify-between items-center">
                    <div className="flex gap-4 items-center">
                        <Button
                            variant="primary"
                            onClick={handleUploadSelected}
                            disabled={isUploading}
                            className={`
                                ${isUploading 
                                    ? 'bg-gray-600 cursor-not-allowed' 
                                    : ''}`}
                        >
                            {editMode ? 'Update Selected' : 'Upload Selected'}
                        </Button>
                        {isUploading && handleCancelUpload && (
                            <Button
                                 variant="primary"
                                onClick={() => {
                                    setUploadCancelled(true);
                                    handleCancelUpload();
                                }}
                            >
                                Cancel {editMode ? 'Update' : 'Upload'}
                            </Button>
                        )}
                    </div>
                </div>
                
                <ProgressBar 
                    files={files}
                    selectedFiles={selectedFiles}
                    isUploading={isUploading}
                    editMode={editMode}
                />
</div>
            
            <div 
                className="flex-1 min-h-0 ag-theme-material-dark ag-theme-transparent"
                style={{
                    backgroundColor: 'transparent',
                    '--ag-background-color': 'transparent',
                    '--ag-odd-row-background-color': 'transparent',
                    '--ag-header-background-color': 'transparent',
                    '--ag-control-panel-background-color': 'transparent',
                    '--ag-row-hover-color': 'transparent',
                    '--ag-selected-row-background-color': 'rgba(255, 255, 255, 0.05)',
                    '--ag-row-border-color': 'rgba(255, 255, 255, 0.05)',
                    '--ag-header-column-separator-display': 'none',
                    '--ag-borders': 'none',
                    '--ag-borders-critical': 'none',
                    '--ag-cell-horizontal-border': 'none'
                }}
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={files}
                    pinnedTopRowData={[batchEditRow]}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    suppressRowDeselection={false}
                    getRowId={params => params.data.id || params.data.file?.name}
                    isRowSelectable={params => params.data?.id !== 'batch-edit'}
                    getRowStyle={params => {
                        const baseStyle = getRowStyle(params);
                        return { 
                            ...baseStyle,
                            background: 'transparent',
                            backgroundColor: params.node.isSelected() ? 'rgba(255, 255, 255, 0.05)' : 'transparent'
                        };
                    }}
                    onGridReady={onGridReady}
                    maintainColumnOrder={true}
                    suppressColumnVirtualisation={true}
                    ensureDomOrder={true}
                    backgroundColor="transparent"
                    rowClassRules={{
                        'transparent-row': () => true
                    }}
                    rowHeight={56}
                    headerHeight={28}
                    getRowClass={params => {
                        if (params.data?.id === 'batch-edit') return 'pinned-row';
                        const isSelected = params.node.isSelected();
                        return isSelected ? 'selected-row' : '';
                    }}
                />
            </div>
        </div>
    );
};

export default BatchUploadTable;