import React, { useState, useEffect, useRef } from 'react';

export const PlayIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="white">
    <path d="M8 5v14l11-7z" />
  </svg>
);

export const PauseIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="white">
    <path d="M6 4h4v16H6zM14 4h4v16h-4z" />
  </svg>
);

export const LikeIcon = ({ filled }) => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill={filled ? "currentColor" : "none"} stroke="currentColor" strokeWidth="2">
    <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
  </svg>
);

export const MoreIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
  </svg>
);

export const DownloadIcon = () => {
  const [strokeColor, setStrokeColor] = useState('#000');

  useEffect(() => {
    const tempElement = document.createElement('div');
    tempElement.className = 'text-text-primary hidden';
    document.body.appendChild(tempElement);
    const primaryColor = getComputedStyle(tempElement).color;
    setStrokeColor(primaryColor);
    document.body.removeChild(tempElement);
  }, []);

  return (
    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke={strokeColor} strokeWidth="2">
      <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const DragIcon = ({ onDragStart, onDragEnd }) => (
  <div
    className="drag-icon flex flex-col items-center justify-center cursor-grab active:cursor-grabbing w-full h-full"
    draggable="true"
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
  >
    <div className="flex flex-col items-center">
      <svg
        className="w-6 h-6"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M4 4C4 3.44772 4.44772 3 5 3H14.5858C14.851 3 15.1054 3.10536 15.2929 3.29289L19.7071 7.70711C19.8946 7.89464 20 8.149 20 8.41421V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4Z" />
        <path d="M10 10.5V16.5" className="stroke-current" strokeWidth="1.5" />
        <path d="M12 9V18" className="stroke-current" strokeWidth="1.5" />
        <path d="M14 12V15" className="stroke-current" strokeWidth="1.5" />
        <path d="M14 3V8H19" className="stroke-current" strokeWidth="1.5" />
      </svg>
    </div>
  </div>
);

export const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
  </svg>
);

export const TokenBadge = () => (
  <svg className="w-5 h-5 inline-block ml-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" fill="#FFD700"/>
    <text x="12" y="16" textAnchor="middle" fill="#000" fontSize="12" fontWeight="bold">₮</text>
  </svg>
);

export const CashBadge = () => (
  <svg className="w-5 h-5 inline-block ml-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" fill="#4CAF50"/>
    <text x="12" y="16" textAnchor="middle" fill="#fff" fontSize="14" fontWeight="bold">$</text>
  </svg>
);

export const VerifiedBadge = () => (
  <svg className="w-5 h-5 ml-1 inline-block" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* Background */}
    <path
      d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484z"
      fill="#0095F6"
    />
    {/* White Checkmark */}
    <path
      d="M15.884 9.166l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"
      fill="white"
    />
  </svg>
);

export const MaximizeIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
    {/* Minimalist, elegant maximize icon inspired by Apple's design principles */}
    <path fillRule="evenodd" clipRule="evenodd" d="M9 4H5C4.44772 4 4 4.44772 4 5V9H6V6H9V4Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15 4H19C19.5523 4 20 4.44772 20 5V9H18V6H15V4Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15 20H19C19.5523 20 20 19.5523 20 19V15H18V18H15V20Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 20H5C4.44772 20 4 19.5523 4 19V15H6V18H9V20Z" fill="white" />
  </svg>
);