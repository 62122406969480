import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { isJUCE } from '../context/JUCE';
import InviteDialog from './InviteDialog';
import { Mail } from 'lucide-react';

// Constants for navigation widths
const MAX_NAV_WIDTH = 240; // Increased from 192 to 240
const MIN_NAV_WIDTH = 90; // Increased from 80 to 90 to prevent scrollbar in Safari

// Modified NavItem to handle collapsed state
const NavItem = ({ icon, label, to, onClick, isCollapsed }) => (
  <Link 
    to={to} 
    onClick={onClick}
    className={`
      flex ${isCollapsed ? 'flex-col items-center justify-center relative' : 'flex-row items-center space-x-3'} 
      text-white hover:text-white/90 transition-all duration-200 
      rounded-lg ${isCollapsed ? 'p-2.5' : 'p-2.5'} hover:bg-white/10 ${isCollapsed ? 'w-14 mx-auto' : 'w-[calc(100%-4px)] mx-[2px]'} group
      ${isCollapsed ? 'h-14' : 'min-h-[44px]'}
    `}
  >
    <div className="flex items-center justify-center opacity-70 group-hover:opacity-100 flex-shrink-0" style={{ width: isCollapsed ? '32px' : '20px', height: isCollapsed ? '32px' : '20px' }}>
      {React.cloneElement(icon, { 
        className: isCollapsed ? "w-6 h-6" : "w-5 h-5"
      })}
    </div>
    {!isCollapsed ? (
      <span className="text-sm font-medium flex-1 overflow-hidden leading-tight">
        {label}
      </span>
    ) : (
      <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 -bottom-8 left-1/2 transform -translate-x-1/2 text-xs font-medium text-center w-28 px-3 py-2 rounded-lg bg-bg-primary/90 backdrop-blur-sm shadow-lg whitespace-normal">
        {label}
      </div>
    )}
  </Link>
);

// Modified NavSection to handle collapsed state
const NavSection = ({ title, children, isCollapsed }) => (
  <div className={`space-y-2 ${isCollapsed ? 'flex flex-col items-center w-full' : ''}`}>
    {title && !isCollapsed && (
      <h3 className="text-xs font-semibold uppercase tracking-wider text-white/50 px-0">
        {title}
      </h3>
    )}
    {title && isCollapsed && (
      <div className="h-[1px] bg-white/10 w-12 my-4"></div>
    )}
    <div className={`space-y-2 ${isCollapsed ? 'w-full' : ''}`}>
      {children}
    </div>
  </div>
);

export const Navigation = ({ isOpen, onClose }) => {
  const { user } = useContext(AuthContext);
  const [navWidth, setNavWidth] = useState(MIN_NAV_WIDTH);
  const [isDragging, setIsDragging] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false); // Track hover state
  const dragHandleRef = useRef(null);
  const navRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  // Calculate if navigation is collapsed based on width
  // This only applies to desktop view
  const isCollapsed = navWidth <= MIN_NAV_WIDTH + 30;

  // Toggle navigation collapse state
  const toggleCollapse = () => {
    const newWidth = isCollapsed ? MAX_NAV_WIDTH : MIN_NAV_WIDTH;
    const newCollapsedState = !isCollapsed;
    
    // Dispatch a custom event BEFORE changing the state
    // This ensures other components can react immediately
    const navStateChangeEvent = new CustomEvent('navStateChange', {
      detail: { width: newWidth, isCollapsed: newCollapsedState }
    });
    document.dispatchEvent(navStateChangeEvent);
    
    // Then update the state
    setNavWidth(newWidth);
    setIsHovering(!newCollapsedState); // Update hover state to match new state
  };

  // Handle resize on window size changes
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth >= 768 && isOpen) onClose();
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen, onClose]);

  // Setup drag functionality
  useEffect(() => {
    const dragHandle = dragHandleRef.current;
    const nav = navRef.current;
    
    if (!dragHandle || !nav) return;
    
    const handleDragStart = (e) => {
      e.preventDefault();
      setIsDragging(true);
      document.body.style.userSelect = 'none';
      document.body.style.cursor = 'ew-resize';
    };

    const handleDrag = (e) => {
      if (!isDragging) return;
      
      // Get mouse position
      const mouseX = e.clientX;
      
      // Calculate new width based on mouse position
      let newWidth = mouseX;
      
      // Clamp width between MIN and MAX
      newWidth = Math.max(MIN_NAV_WIDTH, Math.min(newWidth, MAX_NAV_WIDTH));
      
      // Update width
      setNavWidth(newWidth);
    };

    const handleDragEnd = () => {
      if (!isDragging) return;
      
      setIsDragging(false);
      document.body.style.userSelect = '';
      document.body.style.cursor = '';
      
      // Snap to minimum or maximum width
      if (navWidth < (MIN_NAV_WIDTH + MAX_NAV_WIDTH) / 2) {
        setNavWidth(MIN_NAV_WIDTH);
      } else {
        setNavWidth(MAX_NAV_WIDTH);
      }
    };

    // Add event listeners
    dragHandle.addEventListener('mousedown', handleDragStart);
    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
    
    // Cleanup
    return () => {
      dragHandle.removeEventListener('mousedown', handleDragStart);
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [isDragging, navWidth]);

  const navigationItems = [
    {
      section: null,
      items: [
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 10.9c-.61 0-1.1.49-1.1 1.1s.49 1.1 1.1 1.1c.61 0 1.1-.49 1.1-1.1s-.49-1.1-1.1-1.1zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm2.19 12.19L6 18l3.81-8.19L18 6l-3.81 8.19z"/>
          </svg>,
          label: 'Explore',
          to: '/'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M15 6H3v2h12V6zm0 4H3v2h12v-2zM3 16h8v-2H3v2zM17 6v8.18c-.31-.11-.65-.18-1-.18-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3V8h3V6h-5z"/>
          </svg>,
          label: 'Sound Packs',
          to: '/soundpacks'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
          </svg>,
          label: 'Users',
          to: '/users'
        }
      ]
    },
    {
      section: 'Library',
      items: [
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
          </svg>,
          label: 'Purchased',
          to: '/purchased'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
          </svg>,
          label: 'Liked',
          to: '/likes'
        }
      ]
    }
  ];


  if (user?.admin) {
    navigationItems.push({
      section: 'Admin',
      items: [
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
          </svg>,
          label: 'Batch Edit All',
          to: '/batch-edit-full'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
          </svg>,
          label: 'Manage Tags',
          to: '/admin/tags'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
          </svg>,
          label: 'Manage Instruments',
          to: '/admin/instruments'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
          </svg>,
          label: 'Manage Genres',
          to: '/admin/genres'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
          </svg>,
          label: 'AI Generator',
          to: '/ai'
        }
      ]
    });
  }

  // Handle mouse enter event - expand the navigation
  const handleMouseEnter = () => {
    if (windowWidth >= 768 && !isDragging) {
      setIsHovering(true);
      setNavWidth(MAX_NAV_WIDTH);

      // Dispatch event to notify other components
      const navStateChangeEvent = new CustomEvent('navStateChange', {
        detail: { width: MAX_NAV_WIDTH, isCollapsed: false }
      });
      document.dispatchEvent(navStateChangeEvent);
    }
  };

  // Handle mouse leave event - collapse the navigation
  const handleMouseLeave = () => {
    if (windowWidth >= 768 && !isDragging) {
      setIsHovering(false);
      setNavWidth(MIN_NAV_WIDTH);

      // Dispatch event to notify other components
      const navStateChangeEvent = new CustomEvent('navStateChange', {
        detail: { width: MIN_NAV_WIDTH, isCollapsed: true }
      });
      document.dispatchEvent(navStateChangeEvent);
    }
  };

  return (
    <>
      {/* Mobile overlay */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black/70 backdrop-blur-md z-[59] md:hidden"
          onClick={onClose}
        />
      )}
      
      {/* Navigation sidebar */}
      <nav
        ref={navRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`
          fixed md:sticky top-0 left-0 ${isJUCE() ? 'h-[calc(100%-64px)]' : 'h-full'}
          ${windowWidth < 768 ? 'bg-bg-primary/60 backdrop-blur-sm' : ''} z-[70]
          transition-all duration-300 ease-in-out
          ${isOpen ? 'translate-x-0' : '-translate-x-full'}
          md:translate-x-0 md:relative
          flex flex-col
        `}
        style={{
          width: windowWidth < 768 
            ? isOpen ? '80%' : '0px' 
            : `${navWidth}px`,
          maxWidth: '280px'
        }}
      >
        <div className="flex-1 overflow-hidden flex flex-col">
          <div className="flex-1 overflow-y-auto overflow-x-hidden py-6 px-2 space-y-6">
            {navigationItems.map((group, idx) => (
              <NavSection 
                key={idx} 
                title={group.section} 
                isCollapsed={windowWidth < 768 ? false : isCollapsed}
              >
                {group.items.map((item, itemIdx) => (
                  <NavItem
                    key={itemIdx}
                    to={item.to}
                    icon={item.icon}
                    label={item.label}
                    onClick={onClose}
                    isCollapsed={windowWidth < 768 ? false : isCollapsed}
                  />
                ))}
              </NavSection>
            ))}
          </div>
          
          <div className={`p-6 bg-transparent ${windowWidth < 768 ? '' : (isCollapsed ? 'flex flex-col items-center' : '')}`}>
            {windowWidth < 768 ? false : isCollapsed && (
              <div className="h-[1px] bg-white/10 w-10 mb-6"></div>
            )}
            <div className={`${windowWidth < 768 ? 'space-y-6' : (isCollapsed ? 'flex justify-center' : 'space-y-6')}`}>
              {(windowWidth < 768 || !isCollapsed) && (
                <div className="space-y-3">
                  <Link 
                    to="/batch-upload" 
                    onClick={onClose}
                  >
                    <button className="w-full bg-gradient-to-r from-accent-end to-accent-end hover:brightness-75 text-white px-4 py-2.5 rounded-full font-medium transition-all duration-200 text-sm">
                      Upload Sounds
                    </button>
                  </Link>
                  
                  <button 
                    onClick={() => setIsInviteOpen(true)}
                    className="w-full flex items-center justify-center bg-gradient-to-r hover:brightness-75 text-white px-4 py-2.5 rounded-full font-medium transition-all duration-200 text-sm"
                  >
                    <Mail size={16} className="mr-1.5" />
                    Invite Users
                  </button>
                </div>
              )}
              
              {windowWidth < 768 ? false : isCollapsed && (
                <div className="flex flex-col space-y-3 items-center w-full">
                  <Link 
                    to="/batch-upload" 
                    onClick={onClose}
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gradient-to-r from-accent-end to-accent-end hover:brightness-75 text-white transition-all duration-200"
                    title="Upload Sounds"
                  >
                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
                    </svg>
                  </Link>
                  
                  <button
                    onClick={() => setIsInviteOpen(true)}
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full hover:brightness-75 text-white transition-all duration-200"
                    title="Invite Users"
                  >
                    <Mail size={16} />
                  </button>
                </div>
              )}
              
              {!isJUCE() && !isCollapsed && windowWidth >= 768 && (
                <div className="space-y-3">
                  <h3 className="text-xs font-semibold uppercase tracking-wider text-white/50 text-center">
                    Download Plugin
                  </h3>
                  <div className="grid grid-cols-2 gap-2">
                    <button
                      onClick={() => window.location.href = "https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Win%20Installer.exe"}
                      className="flex flex-col items-center justify-center p-3 rounded-lg bg-bg-primary/10 hover:bg-bg-primary/20 border border-white/10 transition-colors duration-200 text-white"
                    >
                      <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801"/>
                      </svg>
                      <span className="text-xs mt-1 font-medium">Windows</span>
                    </button>

                    <button
                      onClick={() => window.location.href = "https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Mac%20Installer_signed.pkg"}
                      className="flex flex-col items-center justify-center p-3 rounded-lg bg-bg-primary/10 hover:bg-bg-primary/20 border border-white/10 transition-colors duration-200 text-white"
                    >
                      <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
                      </svg>
                      <span className="text-xs mt-1 font-medium">macOS</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Drag handle - only visible on desktop */}
        <div 
          ref={dragHandleRef}
          className="absolute top-0 right-0 h-full w-1 cursor-ew-resize hidden md:block overflow-visible"
          style={{
            background: isDragging ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
          }}
        >
          <div className="absolute inset-y-0 right-0 w-4 -mr-2" />
        </div>

        {/* Toggle button for collapsing/expanding - circular style */}
        <div className="absolute right-0 top-[65px] translate-x-1/2 z-[51] hidden md:flex">
          <button
            onClick={toggleCollapse}
            className="w-8 h-8 flex items-center justify-center bg-[#171720] rounded-full shadow-lg hover:bg-[#1d1d28] focus:outline-none focus:ring-0 transition-all duration-150 relative overflow-hidden border border-[#2a2a38]"
            title={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
            aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            {/* Inner glow effect */}
            <div className="absolute inset-0 bg-accent-end/10 rounded-full blur-[5px] scale-75 opacity-70"></div>
            
            <div 
              className="transition-transform duration-150 flex items-center justify-center relative z-10"
              style={{ transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)' }}
            >
              <svg 
                className="w-4 h-4 text-white/90"
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </button>
        </div>
      </nav>
      {/* Mobile backdrop */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 backdrop-blur-sm md:hidden z-40"
          onClick={onClose}
        />
      )}

      <InviteDialog
        isOpen={isInviteOpen}
        onClose={() => setIsInviteOpen(false)}
      />
    </>
  );
};

export default Navigation;