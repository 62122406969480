// Layout.jsx
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Outlet, Link } from 'react-router-dom';
import Header from './Header';
import Navigation from './Navigation';
import AudioPlayer from './AudioPlayer/AudioPlayer';
import AudioControls from './AudioPlayer/AudioControls';
import { JUCESend, isJUCE } from '../context/JUCE';
import { useAudioNavigation } from '../context/AudioNavigationContext';
import { AuthContext } from '../context/AuthContext';
import PricingModal from './PricingSection';

// Constants for fixed heights
const HEADER_HEIGHT = 80; // 20rem
const AUDIO_PLAYER_HEIGHT = 80; // Standard height for audio player
const AUDIO_CONTROLS_HEIGHT = 56; // Height for JUCE controls
const BOTTOM_EXTRA_PADDING = 50; // Extra padding to ensure content is visible

const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [isAudioPlayerMinimized, setIsAudioPlayerMinimized] = useState(false);
  const { user, hasAcceptedTerms, isAuthenticated } = useContext(AuthContext);
  const {
    currentPlayingSound,
    setCurrentPlayingSound,
    isPlaying,
    setIsPlaying,
    navigateToSound
  } = useAudioNavigation();
  const contentRef = useRef(null);
  const hasShownPricingRef = useRef(false);

  // Load Zendesk widget script
  useEffect(() => {
    // Create Zendesk script element
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=f25be871-f704-4e90-adf7-7fe6a7d87afe';
    script.async = true;
    
    // Append to document body
    document.body.appendChild(script);
    
    // Cleanup function to remove script when component unmounts
    return () => {
      // Check if the script is still in the document before removing
      if (document.getElementById('ze-snippet')) {
        document.body.removeChild(script);
      }
    };
  }, []);

  // Effect to show pricing modal after login and terms acceptance if user is not subscribed
  useEffect(() => {
    // Check if user is authenticated, has accepted terms, and hasn't been shown the pricing modal yet
    const isSubscribed = user?.active_subscriptions?.some(sub => sub.toLowerCase().includes('soundbank')) || false;
    
    if (isAuthenticated && hasAcceptedTerms && !isSubscribed && !hasShownPricingRef.current) {
      // Set a timeout to show the pricing modal to ensure it happens after the layout is fully rendered
      const timer = setTimeout(() => {
        setShowPricingModal(true);
        hasShownPricingRef.current = true; // Mark that we've shown the pricing modal
      }, 500); // Short delay for better UX
      
      return () => clearTimeout(timer);
    }
  }, [isAuthenticated, hasAcceptedTerms, user]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      JUCESend('pauseSound', '');
    } else {
      JUCESend('playSound', currentPlayingSound);
    }
  };

  const handleNext = () => {
    const nextSound = navigateToSound('next');
    if (nextSound) {
      setCurrentPlayingSound(nextSound);
      JUCESend('playSound', nextSound);
      setIsPlaying(true);
    }
  };

  const handlePrevious = () => {
    const previousSound = navigateToSound('previous');
    if (previousSound) {
      setCurrentPlayingSound(previousSound);
      JUCESend('playSound', previousSound);
      setIsPlaying(true);
    }
  };

  // Calculate bottom padding based on whether audio player and JUCE controls are visible
  const bottomPadding = currentPlayingSound
    ? isJUCE()
      ? AUDIO_PLAYER_HEIGHT + AUDIO_CONTROLS_HEIGHT + BOTTOM_EXTRA_PADDING
      : AUDIO_PLAYER_HEIGHT + BOTTOM_EXTRA_PADDING
    : 0;

  return (
    <div className="flex flex-col h-screen bg-bg-primary text-text-primary font-sans relative">
      {/* Global gradient background */}
      <div className="fixed inset-0 pointer-events-none z-0">
        {/* Enhanced gradient with purple accent */}
        <div className="absolute inset-0 bg-gradient-to-tr from-bg-primary via-bg-primary to-accent-end/10" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,var(--bg-primary)_30%,rgba(161,0,255,0.06)_70%,transparent_90%)]" />
      </div>
      
      <Header className="fixed top-0 left-0 right-0 z-30 h-20" onMenuToggle={() => setIsMenuOpen(!isMenuOpen)} />
      
      <div className="flex flex-1 w-full mt-20">
        {/* Content container with navigation and main content */}
        <div 
          className="absolute inset-0 top-20 flex"
        >
          {/* Navigation */}
          <Navigation 
            isOpen={isMenuOpen} 
            onClose={() => setIsMenuOpen(false)} 
            className="h-full overflow-y-auto flex-shrink-0 relative z-10 navigation" 
          />
          
          {/* Main content */}
          <main className="flex-1 min-w-0 relative z-10">
            <div 
              ref={contentRef}
              className="h-full overflow-y-auto overscroll-contain w-full"
            >
              <div className="min-h-full">
                <Outlet context={{ setCurrentPlayingSound, setIsPlaying }} />
                {/* Add significant padding at the bottom to ensure content is not hidden behind the audio player */}
   
                  <div style={{ height: bottomPadding }} className="w-full mb-16" aria-hidden="true"></div>

              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Audio player and controls - positioned lower */}
      {currentPlayingSound && (
        <div 
          className="fixed left-0 right-0 bottom-0 z-40 mx-auto max-w-screen-2xl"
          style={{ 
            height: isJUCE() ? `${AUDIO_PLAYER_HEIGHT + AUDIO_CONTROLS_HEIGHT}px` : `${AUDIO_PLAYER_HEIGHT}px`
          }}
        >
          <div className="relative z-10">
            <AudioPlayer
              sound={currentPlayingSound}
              isPlaying={isPlaying}
              onPlayPause={handlePlayPause}
              onNext={handleNext}
              onPrevious={handlePrevious}
              isMinimized={isAudioPlayerMinimized}
              setIsMinimized={setIsAudioPlayerMinimized}
            />
          </div>
          {isJUCE() && (
            <div className={`relative ${isAudioPlayerMinimized ? 'z-0' : 'z-20'}`}>
              <AudioControls 
                className="bg-bg-secondary border-t border-black border-opacity-25"
                style={{ height: `${AUDIO_CONTROLS_HEIGHT}px` }}
              />
            </div>
          )}
        </div>
      )}
      
      {/* Upload button has been moved to the AudioPlayer component */}
      
      {/* Pricing Modal */}
      <PricingModal 
        isOpen={showPricingModal}
        setIsOpen={setShowPricingModal}
      />
    </div>
  );
};

export default Layout;