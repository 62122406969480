import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export const CustomPopover = ({ trigger, content, isOpen, setIsOpen }) => {
  const popoverRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div className="relative" ref={popoverRef}>
      {trigger}
      {isOpen && (
        <div className="absolute right-0 top-full mt-2 bg-bg-secondary rounded-lg shadow-lg py-1 z-50">
          {content}
        </div>
      )}
    </div>
  );
};


export const CustomDialog = ({ isOpen, onClose, title, children, maxWidth = 'md:max-w-2xl' }) => {
  const dialogRef = useRef(null);
  
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
      
      // Animation effect on open
      if (dialogRef.current) {
        dialogRef.current.classList.add('animate-dialog-in');
      }
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [onClose, isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div 
      className="fixed inset-0 bg-black/30 backdrop-blur-md z-[99999] md:p-6 flex items-center justify-center"
      onClick={onClose}
    >
      <div 
        ref={dialogRef}
        className={`
          bg-bg-primary/80 backdrop-blur-xl w-full relative text-text-primary
          md:rounded-2xl ${maxWidth} md:my-auto shadow-2xl
          flex flex-col h-full md:h-auto md:max-h-[85vh]
          border border-white/10 animate-dialog-in
        `}
        onClick={e => e.stopPropagation()}
        style={{
          boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(255, 255, 255, 0.05)'
        }}
      >
        {/* Header */}
        <div className="flex justify-between items-center px-6 py-4">
          <h2 className="text-xl font-medium tracking-tight pr-4 text-text-primary">{title}</h2>
          <button 
            onClick={onClose}
            className="text-text-secondary hover:text-text-primary transition-all duration-300 p-2 -mr-2 hover:bg-white/10 rounded-full"
            aria-label="Close dialog"
          >
            <svg 
              className="w-5 h-5" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto px-6 py-5 custom-scrollbar">
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export const StyledSelect = ({ 
  value, 
  onChange, 
  options, 
  placeholder, 
  required, 
  className = '',
  error,
  label
}) => {
  return (
    <div className="relative">
      {label && (
        <label className="block text-sm font-medium text-text-secondary mb-2">
          {label}
        </label>
      )}
      <div className="relative">
        <select
          value={value}
          onChange={onChange}
          required={required}
          className={`
            w-full rounded-md bg-bg-secondary 
            border-white/20 border-2
            text-text-primary
            appearance-none
            px-4 h-12
            focus:border-accent-end focus:ring focus:ring-accent-end focus:ring-opacity-50
            transition-all duration-300
            cursor-pointer
            ${className}
          `}
        >
          {placeholder && (
            <option value="" disabled={required}>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option 
              key={option.value || option.id} 
              value={option.value || option.id}
            >
              {option.label || option.name}
            </option>
          ))}
        </select>
        
        {/* Custom arrow indicator */}
        <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none">
          <svg 
            className="h-4 w-4 text-text-secondary" 
            fill="none" 
            strokeWidth="2"
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
      
      {error && (
        <p className="mt-1 text-sm text-red-500">{error}</p>
      )}
    </div>
  );
};
