import React, { useState, useEffect } from 'react';
import { fetchUsers } from '../../api/APIManager';
import { DEFAULT_USER_IMAGE } from '../../api/APIConfig';
import { VerifiedBadge } from '../../assets/Icons';
import { CustomDialog } from '../../components/CustomComponents';
import { Link } from 'react-router-dom';

const UserListDialog = ({ 
  isOpen, 
  onClose, 
  userId, 
  type, // 'followers' or 'following'
  initialCount 
}) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsersList = async () => {
      if (!isOpen) return;
      
      setIsLoading(true);
      try {
        var filter = {followed_by: userId};

        if (type == 'followers') {
          filter = {following: userId};
        } 
        const response = await fetchUsers(1, 100, filter);
          setUsers(response.items || []);
      } catch (error) {
        console.error('Error fetching users:', error);
        setUsers([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsersList();
  }, [userId, type, isOpen]);

  const UserCard = ({ user }) => (
    <Link
      to={`/${user.username}`}
      onClick={onClose}
      className="flex items-center p-2 hover:bg-white/5 transition-colors rounded-lg"
    >
      <img
        src={user.avatar || DEFAULT_USER_IMAGE}
        alt={user.username}
        className="w-12 h-12 rounded-full object-cover"
      />
      <div className="ml-4 flex-grow">
        <div className="flex items-center">
          <h3 className="font-medium text-text-primary">{user.username}</h3>
          {user.verified && <VerifiedBadge className="ml-2" />}
        </div>
      </div>
    </Link>
  );

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      title={`${type === 'followers' ? 'Followers' : 'Following'} (${initialCount || 0})`}
    >
      <div className="space-y-2">
        {isLoading ? (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-accent-end"></div>
          </div>
        ) : users.length > 0 ? (
          users.map((user) => (
            <UserCard key={user.id} user={user} />
          ))
        ) : (
          <div className="text-center py-8 text-text-secondary">
            {type === 'followers' 
              ? 'No followers yet' 
              : 'Not following anyone yet'}
          </div>
        )}
      </div>
    </CustomDialog>
  );
};

export default UserListDialog;