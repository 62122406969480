import React, {
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  MoreVertical,
  Star
} from 'lucide-react';
import Spinner from '../components/Spinner';
import {
  fetchUsers,
  updateUser
} from '../api/APIManager';
import {
  VerifiedBadge
} from '../assets/Icons';
import {
  DEFAULT_SOUND_IMAGE,
  DEFAULT_USER_IMAGE
} from '../api/APIConfig';
import {
  AuthContext
} from '../context/AuthContext';

const UserCard = ({
  user,
  onUpdate
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    user: currentUser
  } = useContext(AuthContext);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (!user) return null;

  return (
    <div
      onClick={() => navigate(`/${user.username}`)}
      className="groupborder-text-primary/5 hover:border-accent-end/20 rounded-xl transition-all duration-200 cursor-pointer overflow-hidden relative transform hover:-translate-y-1 hover:shadow-xl"
    >
      <div className="p-6">
        {/* Avatar Section */}
        <div className="relative aspect-square mb-4 overflow-hidden rounded-full border-4 border-transparent group-hover:border-accent-end transition-all">
          <img
            src={user.avatar || DEFAULT_USER_IMAGE}
            alt={user.username}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
            onError={(e) => {
              e.target.src = DEFAULT_SOUND_IMAGE;
            }}
          />
        </div>

        {/* User Info Section */}
        <div className="space-y-2 text-center">
          <div className="flex items-center justify-center gap-1">
            <h4 className="font-bold text-lg truncate text-text-primary">
              {user.username}
            </h4>
            {user.verified && (
              <VerifiedBadge className="w-5 h-5 text-accent-end" />
            )}
          </div>
          
          {/* Followers Count */}
          <p className="text-sm text-text-secondary">
            {user.followers_count?.toLocaleString() || '0'} followers
          </p>
        </div>
      </div>
    </div>
  );
};

const UsersPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState({
    query: '',
    featured: '',
    verified: '',
  });
  const pageSize = 25;
  const loadingRef = useRef(false);
  const searchTimeout = useRef(null);

  const fetchUsersData = async (newPage, currentFilter = filter) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);

    try {
      const response = await fetchUsers(newPage, pageSize, currentFilter);
      setUsers(response.items || []);
      setTotalResults(response.total || 0);
      setTotalPages(Math.ceil((response.total || 0) / pageSize));
      setPage(newPage);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  useEffect(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      const newFilter = {
        ...filter,
        query: searchQuery
      };
      setFilter(newFilter);
      fetchUsersData(1, newFilter);
    }, 500);

    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, [searchQuery]);

  const handleFilterChange = (name, value) => {
    const newFilter = {
      ...filter,
      [name]: value
    };
    setFilter(newFilter);
    fetchUsersData(1, newFilter);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages && !loading) {
      fetchUsersData(newPage);
    }
  };

  const handleDirectPageInput = (e) => {
    if (e.key === 'Enter') {
      const pageNumber = parseInt(e.target.value);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        handlePageChange(pageNumber);
      }
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    pageNumbers.push(1);

    let startPage = Math.max(2, page - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(2, endPage - maxVisiblePages + 2);
    }

    if (startPage > 2) {
      pageNumbers.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push('...');
    }

    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  if (loading && users.length === 0) {
    return (
      <div className="min-h-screen">
        <div className="container mx-auto px-4 py-12">
          <div className="mb-12 text-center">
            <h1 className="text-3xl font-bold text-text-primary mb-4">
              Discover Amazing People
            </h1>
            <p className="text-text-secondary text-lg max-w-2xl mx-auto">
              Connect with creators, artists, and innovators from around the world
            </p>
          </div>

          <div className="max-w-sm mx-auto mb-10">
            <div className="relative">
              <input
                type="text"
                placeholder="Search users..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-3 py-1.5 rounded-full bg-transparent border border-text-primary/10 focus:outline-none focus:border-accent-end focus:ring-1 focus:ring-accent-end/20 text-sm shadow-md"
              />
              <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-1">
                {searchQuery && (
                  <button
                    onClick={() => {
                      setSearchQuery('');
                      setFilter(prev => ({ ...prev, query: '' }));
                    }}
                    className="p-0.5 hover:bg-bg-secondary rounded-full text-text-secondary"
                    aria-label="Clear search"
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                )}
                <svg className="w-5 h-5 text-text-secondary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center min-h-[400px]">
            <Spinner />
            <p className="text-text-secondary">Finding amazing people...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen ">
      <div className="container mx-auto px-4 py-12">
        <div className="mb-12 text-center">
          <h1 className="text-3xl font-bold text-text-primary mb-4">
            Discover Amazing People
          </h1>
          <p className="text-text-secondary text-lg max-w-2xl mx-auto">
            Connect with creators, artists, and innovators from around the world
          </p>
        </div>

        <div className="max-w-sm mx-auto mb-10">
          <div className="relative">
            <input
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-3 py-1.5 rounded-full bg-transparent border border-text-primary/10 focus:outline-none focus:border-accent-end focus:ring-1 focus:ring-accent-end/20 text-sm shadow-md"
            />
            <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-1">
              {searchQuery && (
                <button
                  onClick={() => {
                    setSearchQuery('');
                    setFilter(prev => ({ ...prev, query: '' }));
                  }}
                  className="p-0.5 hover:bg-bg-secondary rounded-full text-text-secondary"
                  aria-label="Clear search"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              )}
              <svg className="w-5 h-5 text-text-secondary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
          </div>
        </div>

        <div className="relative">
          {loading && page === 1 ? (
            <div className="flex flex-col items-center justify-center min-h-[400px]">
              <Spinner />
              <p className="text-text-secondary">Finding amazing people...</p>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
                {users.map((user) => (
                  <UserCard 
                    key={user.id} 
                    user={user} 
                    onUpdate={() => fetchUsersData(page)}
                  />
                ))}
              </div>

              {users.length === 0 && !loading && (
                <div className="text-center py-20">
                  <div className="text-6xl mb-4">🔍</div>
                  <h3 className="text-xl font-semibold text-text-primary mb-2">No users found</h3>
                  <p className="text-text-secondary">Try adjusting your search criteria</p>
                </div>
              )}
            </>
          )}
        </div>

        {users.length > 0 && (
          <div className="mt-12 flex flex-col items-center justify-center gap-6 pt-8 border-t border-text-primary/5">
            <div className="flex items-center gap-4 text-sm text-text-secondary">
              <span>{totalResults.toLocaleString()} users found</span>
              <span className="w-1.5 h-1.5 rounded-full bg-text-secondary/30" />
              <span>Page {page} of {totalPages}</span>
            </div>
            
            <div className="flex items-center gap-2">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1 || loading}
                className="px-4 py-2 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end hover:text-white hover:border-accent-end transition-all duration-200"
              >
                Previous
              </button>

              <div className="flex items-center gap-1">
                {getPageNumbers().map((pageNum, index) => (
                  <React.Fragment key={index}>
                    {pageNum === '...' ? (
                      <span className="px-2 text-text-secondary">...</span>
                    ) : (
                      <button
                        onClick={() => handlePageChange(pageNum)}
                        disabled={loading}
                        className={`min-w-[40px] h-[40px] rounded-lg border transition-all duration-200 ${
                          pageNum === page
                            ? 'bg-accent-end text-white border-accent-end'
                            : 'border-text-primary/15 hover:bg-accent-end/10'
                        }`}
                      >
                        {pageNum}
                      </button>
                    )}
                  </React.Fragment>
                ))}
              </div>

              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages || loading}
                className="px-4 py-2 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end hover:text-white hover:border-accent-end transition-all duration-200"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersPage;