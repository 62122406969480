// MySoundPacksPage.jsx
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import SoundPacksList from '../components/SoundPackBrowser/SoundPacksList';

const MySoundPacksPage = () => {
  const { user } = useContext(AuthContext);

  return (
    <SoundPacksList 
      title="My Sound Packs"
      filters={{ owner_id: user?.id }}
      showCreateButton={true}
    />
  );
};

export default MySoundPacksPage;