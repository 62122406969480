// SoundPacksList.jsx
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { fetchSoundpacks } from '../../api/APIManager';
import SoundPackCard from './SoundPackCard';
import Spinner from '../../components/Spinner';
import  Button  from  '../../components/Button';

const SoundPacksList = ({ 
  filters = {}, 
  showCreateButton = false,
  title = "Sound Packs"
}) => {
  const navigate = useNavigate();
  const [soundpacks, setSoundpacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pageSize = 24;
  const loadingRef = useRef(false);

  const fetchSoundpacksData = async (newPage) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);
    
    try {
      const response = await fetchSoundpacks({ 
        ...filters,
        page_number: newPage,
        page_size: pageSize 
      });
      
      setSoundpacks(response.items || []);
      setTotalResults(response.total || 0);
      setTotalPages(Math.ceil((response.total || 0) / pageSize));
      setPage(newPage);
    } catch (error) {
      console.error('Error fetching sound packs:', error);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  useEffect(() => {
    fetchSoundpacksData(1);
  }, [JSON.stringify(filters)]); // Re-fetch when filters change

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages && !loading) {
      fetchSoundpacksData(newPage);
    }
  };

  const handleDirectPageInput = (e) => {
    if (e.key === 'Enter') {
      const pageNumber = parseInt(e.target.value);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        handlePageChange(pageNumber);
      }
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    
    pageNumbers.push(1);
    
    let startPage = Math.max(2, page - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);
    
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(2, endPage - maxVisiblePages + 2);
    }
    
    if (startPage > 2) {
      pageNumbers.push('...');
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    
    if (endPage < totalPages - 1) {
      pageNumbers.push('...');
    }
    
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  const handleCreateClick = () => {
    navigate('/soundpacks/create');
  };

  if (loading && page === 1) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 min-h-screen">
      {/* Header with Create Button */}
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold text-text-primary">{title}</h1>
          {totalResults > 0 && (
            <span className="text-sm text-text-secondary">
              {totalResults.toLocaleString()} total
            </span>
          )}
        </div>
        {showCreateButton && (
          <Button 
            onClick={handleCreateClick}
            variant = 'secondary'
            className="flex items-center space-x-2 px-4 py-2"
          >
            <Plus className="w-5 h-5" />  Create Sound Pack
          </Button>
        )}
      </div>

      {/* Sound Packs Grid */}
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex justify-center items-center z-10">
            <Spinner />
          </div>
        )}
        
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-6">
          {soundpacks.map((soundpack) => (
            <SoundPackCard 
              key={soundpack.id} 
              soundpack={soundpack} 
              onUpdate={() => fetchSoundpacksData(page)}
            />
          ))}

          {soundpacks.length === 0 && !loading && (
            <div className="col-span-full text-center py-12 text-text-secondary">
              No sound packs found. {showCreateButton ? "Create your first sound pack!" : ""}
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        {soundpacks.length > 0 && (
          <div className="mt-4 flex flex-col sm:flex-row items-center justify-between gap-4 border-t border-text-primary/10 pt-4 px-4 sm:px-8 pb-4">
            <div className="flex items-center gap-2 text-sm">
              <span>Total: {totalResults} items</span>
              <span className="text-text-primary/50">|</span>
              <span>Page {page} of {totalPages}</span>
            </div>
            
            <div className="flex items-center gap-2 flex-wrap justify-center">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1 || loading}
                className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
              >
                Previous
              </button>
              
              <div className="flex items-center gap-1">
                {getPageNumbers().map((pageNum, index) => (
                  <React.Fragment key={index}>
                    {pageNum === '...' ? (
                      <span className="px-2">...</span>
                    ) : (
                      <button
                        onClick={() => handlePageChange(pageNum)}
                        disabled={loading}
                        className={`px-3 py-1 rounded-lg border ${
                          pageNum === page
                            ? 'bg-accent-end text-text-primary border-accent-end'
                            : 'border-text-primary/15 hover:bg-accent-end/10'
                        }`}
                      >
                        {pageNum}
                      </button>
                    )}
                  </React.Fragment>
                ))}
              </div>

              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages || loading}
                className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
              >
                Next
              </button>

              <input
                type="number"
                min="1"
                max={totalPages}
                className="w-16 px-2 py-1 rounded-lg border border-text-primary/15 bg-transparent text-center"
                placeholder={page.toString()}
                onKeyDown={handleDirectPageInput}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SoundPacksList;