import React, { useEffect, useState } from 'react';
import { FastAverageColor } from 'fast-average-color';
import EditableAvatar from './EditableAvatar';
import { DEFAULT_USER_IMAGE } from '../../api/APIConfig';
import { VerifiedBadge } from '../../assets/Icons';
import { ChevronDown, ChevronUp } from 'lucide-react';

const ProfileHeader = ({
  profileUser,
  handleAvatarChange,
  isUploadingAvatar,
  totalSounds,
  onFollowersClick,
  onFollowingClick,
  handleEditClick,
  handleFollow,
  handleUnfollow,
  isAdmin,
  isOwnProfile,
}) => {
  // Removed gradient colors state and generation
  const [isExpandedBio, setIsExpandedBio] = useState(false);

  const headerStyle = profileUser?.background_image
    ? {
        backgroundImage: `url(${profileUser.background_image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    : {
        background: 'transparent'
      };

  const BIO_CHARACTER_LIMIT = 280;

  const renderBio = () => {
    if (!profileUser.bio) return null;

    const shouldShowMore = profileUser.bio.length > BIO_CHARACTER_LIMIT;
    const displayedBio = isExpandedBio 
      ? profileUser.bio 
      : profileUser.bio.slice(0, BIO_CHARACTER_LIMIT) + (shouldShowMore ? '...' : '');

    return (
      <div className="max-w-2xl">
        <div className="relative">
          <p className="text-white text-center sm:text-left whitespace-pre-wrap">
            {displayedBio}
          </p>
          {shouldShowMore && (
            <button
              onClick={() => setIsExpandedBio(!isExpandedBio)}
              className="text-white/60 hover:text-white flex items-center gap-1 mt-1 transition-colors duration-200"
            >
              {isExpandedBio ? 'Show less' : 'Show more'}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div 
      className="relative overflow-hidden transition-all duration-300 min-h-[320px]"
      style={headerStyle}
    >
      {/* Removed gradient overlay to use global gradient from Layout */}
      
      <div className="relative z-10 p-4 sm:p-6 h-full">
        <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4 max-w-5xl mx-auto rounded-xl p-4 sm:p-6 backdrop-blur-sm bg-black/15 border border-white/5 shadow-xl hover:shadow-2xl transition-shadow duration-300">
          {!profileUser?.background_image && (
            <div className="flex-shrink-0 sm:pt-2">
              <EditableAvatar
                currentAvatar={profileUser.avatar || DEFAULT_USER_IMAGE}
                onAvatarChange={handleAvatarChange}
                isEditable={isOwnProfile}
                username={profileUser.username}
                uploading={isUploadingAvatar}
                className="w-36 h-36 sm:w-52 sm:h-52 shadow-2xl"
              />
            </div>
          )}

          <div className="flex-grow space-y-3 text-center sm:text-left">
            <h1 className="text-3xl sm:text-4xl font-bold text-white flex items-center justify-center sm:justify-start gap-2 flex-wrap text-shadow">
              {profileUser.username}
              {profileUser.verified && <VerifiedBadge className="w-6 h-6" />}
            </h1>

            <div className="flex items-center justify-center sm:justify-start gap-6 text-white text-shadow-sm">
              <div className="flex items-center gap-1">
                <span className="font-medium text-lg">{totalSounds}</span>
                <span>Sounds</span>
              </div>

              <button 
                onClick={onFollowersClick}
                className="flex items-center gap-1 hover:text-white transition-colors"
              >
                <span className="font-medium text-lg">
                  {profileUser.followers_count || 0}
                </span>
                <span>Followers</span>
              </button>

              <button
                onClick={onFollowingClick}
                className="flex items-center gap-1 hover:text-white transition-colors"
              >
                <span className="font-medium text-lg">
                  {profileUser.following_count || 0}
                </span>
                <span>Following</span>
              </button>
            </div>

            {renderBio()}

            <div className="flex flex-wrap justify-center sm:justify-start gap-4 mt-2">
              {profileUser?.location && (
                <div className="flex items-center text-white/60">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                  </svg>
                  <span className="text-sm font-medium">{profileUser.location}</span>
                </div>
              )}

              {profileUser?.instagram && (
                <a 
                  href={`https://instagram.com/${profileUser.instagram.replace('@', '')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-white/80 hover:text-white transition-colors duration-200 text-shadow-sm"
                >
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                  </svg>
                  <span className="text-sm">{profileUser.instagram.replace('@', '')}</span>
                </a>
              )}
              
              {profileUser?.twitter && (
                <a 
                  href={`https://x.com/${profileUser.twitter.replace('@', '')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-white/80 hover:text-white transition-colors duration-200 text-shadow-sm"
                >
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                  </svg>
                  <span className="text-sm">{profileUser.twitter.replace('@', '')}</span>
                </a>
              )}
              
              {profileUser?.tiktok && (
                <a 
                  href={`https://tiktok.com/${profileUser.tiktok.replace('@', '')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-white/80 hover:text-white transition-colors duration-200 text-shadow-sm"
                >
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M19.59 6.69a4.83 4.83 0 01-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 01-5.2 1.74 2.89 2.89 0 012.31-4.64 2.93 2.93 0 01.88.13V9.4a6.84 6.84 0 00-1-.05A6.33 6.33 0 005 20.1a6.34 6.34 0 0011.14-4.02v-6.95a8.16 8.16 0 004.65 1.49v-3.93a4.84 4.84 0 01-1.2 0z"/>
                  </svg>
                  <span className="text-sm">{profileUser.tiktok.replace('@', '')}</span>
                </a>
              )}
              
              {profileUser?.website && (
                <a 
                  href={profileUser.website.startsWith('http') ? profileUser.website : `https://${profileUser.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-white/80 hover:text-white transition-colors duration-200 text-shadow-sm"
                >
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
                  </svg>
                  <span className="text-sm">{profileUser.website}</span>
                </a>
              )}
            </div>

            <div className="flex flex-wrap justify-center sm:justify-start gap-3">
              {(isAdmin || isOwnProfile) && (
                <button
                  onClick={handleEditClick}
                  className="px-6 py-2 bg-white/20 hover:bg-white/30 text-white rounded-full transition-all duration-200 text-shadow-sm"
                >
                  Edit Profile
                </button>
              )}
              {!isOwnProfile && (
                <button
                  onClick={profileUser.is_followed ? handleUnfollow : handleFollow}
                  className={`px-6 py-2 rounded-full transition-all duration-200 ${
                    profileUser.is_followed
                      ? 'bg-white/10 hover:bg-white/20 text-white'
                      : 'bg-white text-black hover:bg-white/90'
                  }`}
                >
                  {profileUser.is_followed ? 'Following' : 'Follow'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader; 