import React, { useState } from 'react';
import { CustomDialog } from './CustomComponents';
import { sendInvites } from '../api/APIManager';

const InviteDialog = ({ isOpen, onClose }) => {
  const [emails, setEmails] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    // Split emails by commas, newlines, or spaces and trim whitespace
    const emailList = emails
      .split(/[,\n\s]+/)
      .map(email => email.trim())
      .filter(email => email !== '');
    
    // Basic email validation
    const invalidEmails = emailList.filter(email => !validateEmail(email));
    if (invalidEmails.length > 0) {
      setError(`Invalid email format: ${invalidEmails.join(', ')}`);
      return;
    }
    
    if (emailList.length === 0) {
      setError('Please enter at least one email address');
      return;
    }
    
    setIsSubmitting(true);
    try {
      await sendInvites(emailList);
      setSuccess('Invites sent successfully!');
      setEmails('');
    } catch (error) {
      setError('Failed to send invites. Please try again.');
      console.error('Error sending invites:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Invite Users"
      maxWidth="md:max-w-lg"
    >
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="emails" className="block text-sm font-medium text-text-secondary mb-2">
            Email Addresses
          </label>
          <textarea
            id="emails"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            placeholder="Enter email addresses (separate multiple emails with commas, spaces, or new lines)"
            className="w-full h-32 rounded-md bg-bg-secondary border-white/20 border-2 text-text-primary px-4 py-2 focus:border-accent-end focus:ring focus:ring-accent-end focus:ring-opacity-50 transition-all duration-300"
            disabled={isSubmitting}
          />
          <p className="mt-1 text-xs text-text-secondary">
            Example: user@example.com, another@example.com
          </p>
        </div>

        {error && (
          <div className="p-3 bg-red-500/20 border border-red-500/30 rounded-md text-sm text-red-300">
            {error}
          </div>
        )}

        {success && (
          <div className="p-3 bg-green-500/20 border border-green-500/30 rounded-md text-sm text-green-300">
            {success}
          </div>
        )}

        <div className="flex justify-end space-x-3 pt-2">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 rounded-full border border-white/20 text-text-primary hover:bg-white/5 transition-colors"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 rounded-full bg-accent-end hover:bg-accent-end/60 text-white transition-colors flex items-center"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sending...
              </>
            ) : 'Send Invites'}
          </button>
        </div>
      </form>
    </CustomDialog>
  );
};

export default InviteDialog;
