import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { createSound, fetchSoundTypes, fetchTags, fetchGenres, fetchInstruments, analyzeAudio, createTag, createGenre, createInstrument, createSoundpack, fetchLicenses, fetchSoundpacks, uploadFileToServer } from '../../api/APIManager';
import { useToast } from '../../context/ToastContext';
import BatchUploadTable from './BatchUploadTable';
import { Upload, Music, Image as ImageIcon } from 'lucide-react';
import { AuthContext } from '../../context/AuthContext';
import { CustomDialog } from '../../components/CustomComponents';
import { v4 as uuidv4 } from 'uuid';
import PricingModal from '../../components/PricingSection';

const convertSharpToFlat = (key) => {
    const sharpToFlatMap = {
        'C#': 'Db', 'D#': 'Eb', 'F#': 'Gb', 'G#': 'Ab', 'A#': 'Bb'
    };
    const [note, ...modeParts] = key.split(' ');
    const mode = modeParts.join(' ');
    const upperNote = note.toUpperCase();
    for (const [sharp, flat] of Object.entries(sharpToFlatMap)) {
        if (upperNote === sharp) {
            return mode ? `${flat} ${mode}` : flat;
        }
    }
    return key;
};

const generateUniqueId = () => `file-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

const BatchUpload = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const { showToast } = useToast();
    const [files, setFiles] = useState([]);
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const uploadCancelRef = useRef(false);
    const { setCurrentPlayingSound, setIsPlaying, currentPlayingSound, isPlaying } = useOutletContext();
    const abortControllerRef = useRef(null);
    const [soundpacks, setSoundpacks] = useState([]);
    const [selectedSoundpack, setSelectedSoundpack] = useState(null);
    const [showSoundPackDialog, setShowSoundPackDialog] = useState(false);
    const [newSoundpackName, setNewSoundpackName] = useState('');
    const [soundpackImage, setSoundpackImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [creatingNewSoundpack, setCreatingNewSoundpack] = useState(false);
    const [isPricingOpen, setIsPricingOpen] = useState(false);
    
    // Check if the user has an active Soundbank subscription or is admin/whitelisted/verified
    const isSubscribed = user?.active_subscriptions?.some(sub => sub.toLowerCase().includes('soundbank')) || 
        user?.admin === true || 
        user?.whitelisted === true || 
        user?.verified === true;

    // Use a ref to track if we've shown the dialog in this session
    const hasShownDialogRef = useRef(false);

    useEffect(() => {
        const loadData = async () => {
            try {
                const [types, fetchedTags, fetchedGenres, fetchedInstruments, fetchedLicenses, fetchedSoundpacks] = await Promise.all([
                    fetchSoundTypes(),
                    fetchTags(),
                    fetchGenres(),
                    fetchInstruments(),
                    fetchLicenses(),
                    fetchSoundpacks({
                        page_size: 200,
                        owner_id: user.id 
                    }) 
                    
                ]);
                
                setSoundTypes(types);
                setAvailableTags(fetchedTags);
                setAvailableGenres(fetchedGenres);
                setAvailableInstruments(fetchedInstruments);
                setLicenses(fetchedLicenses);
                setSoundpacks(fetchedSoundpacks.items);

                // If there's exactly one soundpack, auto-select it
                if (fetchedSoundpacks.items.length === 1) {
                    setSelectedSoundpack(fetchedSoundpacks.items[0]);
                    hasShownDialogRef.current = true; // Mark as shown since we auto-selected
                }
            } catch (error) {
                console.error("Error loading initial data:", error);
                showToast("Failed to load initial data. Please try again.", "error");
            } finally {
                setIsLoading(false);
                // Only show the dialog if we haven't shown it yet and no soundpack is selected
                if (!hasShownDialogRef.current && !selectedSoundpack) {
                    setShowSoundPackDialog(true);
                    hasShownDialogRef.current = true; // Mark as shown
                }
            }
        };
        loadData();
    }, []);  // Empty dependency array to run only once on mount

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.types.includes('Files')) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX;
        const y = e.clientY;
        
        if (x <= rect.left || x >= rect.right || y <= rect.top || y >= rect.bottom) {
            setIsDragging(false);
        }
    };

    const handleFileDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        // First ensure a soundpack is selected
        if (!selectedSoundpack) {
            showToast("Please select a sound pack before uploading files", "error");
            setShowSoundPackDialog(true);
            return;
        }

        let droppedFiles = [];
        if (e.dataTransfer.items) {
            droppedFiles = Array.from(e.dataTransfer.items)
                .filter(item => item.kind === 'file' && item.type.startsWith('audio/'))
                .map(item => item.getAsFile());
        } else {
            droppedFiles = Array.from(e.dataTransfer.files)
                .filter(file => file.type.startsWith('audio/'));
        }

        if (droppedFiles.length === 0) {
            showToast("Please drop WAV audio files only.", "error");
            return;
        }
        await addFiles(droppedFiles);
    };

    const handleFileInput = async (e) => {
        e.preventDefault();
        
        // First ensure a soundpack is selected
        if (!selectedSoundpack) {
            showToast("Please select a sound pack before uploading files", "error");
            setShowSoundPackDialog(true);
            e.target.value = '';
            return;
        }
        
        const selectedFiles = Array.from(e.target.files).filter(file => file.type.startsWith('audio/'));
        e.target.value = '';
        
        if (selectedFiles.length === 0) {
            showToast("Please select WAV audio files only.", "error");
            return;
        }
        await addFiles(selectedFiles);
    };

    const addFiles = async (newFiles) => {
        const filesWithPreviews = newFiles.map(file => ({
            id: generateUniqueId(),
            file,
            name: file.name.split('.').slice(0, -1).join('.'),
            preview: URL.createObjectURL(file),
            description: '',
            tags: [],
            genres: [],
            instruments: [],
            typeId: '',
            bpm: '',
            key: '',
            status: 'active',
            costInCredits: '1',
            license: '',
            isAnalyzed: false,
            isAnalyzing: true,
            uploadStatus: 'pending',
            image: null,
            imagePreview: null
        }));

        setFiles(prevFiles => [...prevFiles, ...filesWithPreviews]);

        // Analyze files sequentially
        for (const fileWithPreview of filesWithPreviews) {
            try {
                const analysis = await analyzeAudio(fileWithPreview.file);
                
                setFiles(prevFiles => prevFiles.map(file => {
                    if (file.id !== fileWithPreview.id) return file;
                    
                    return {
                        ...file,
                        bpm: Math.round(analysis.bpm).toString(),
                        key: convertSharpToFlat(analysis.key),
                        genres: analysis.genres
                            .filter(genreName => genreName.trim() !== '')
                            .map(genreName => ({
                                name: genreName,
                                id: availableGenres.find(g => g.name.toLowerCase() === genreName.toLowerCase())?.id
                            })),
                        instruments: analysis.instruments
                            .filter(instrumentName => instrumentName.trim() !== '')
                            .map(instrumentName => ({
                                name: instrumentName,
                                id: availableInstruments.find(i => i.name.toLowerCase() === instrumentName.toLowerCase())?.id
                            })),
                        tags: analysis.moods.split(', ')
                            .filter(moodName => moodName.trim() !== '')
                            .map(moodName => ({
                                name: moodName,
                                id: availableTags.find(t => t.name.toLowerCase() === moodName.toLowerCase())?.id
                            })),
                        isAnalyzed: true,
                        isAnalyzing: false
                    };
                }));
            } catch (error) {
                console.error(`Error analyzing file ${fileWithPreview.name}:`, error);
                showToast(`Failed to analyze ${fileWithPreview.name}`, "error");
                setFiles(prevFiles => prevFiles.map(file => {
                    if (file.id !== fileWithPreview.id) return file;
                    
                    return {
                        ...file,
                        isAnalyzed: false,
                        isAnalyzing: false,
                        uploadStatus: 'error',
                    };
                }));
            }
        }
    };

    const handleBrowseClick = (e) => {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
   
    const handleUpload = async (file) => {
        if (uploadCancelRef.current) {
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'cancelled' } : f
            ));
            return { success: false };
        }
    
        if (!file.typeId || !file.license || (!file.image && !file.imagePreview)) {
            showToast(`Please fill in required fields for ${file.name}`, "error");
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'error' } : f
            ));
            return { success: false };
        }

        // Check if we have a sound pack selected - no need to show dialog again
        if (!selectedSoundpack) {
            showToast("Please select a sound pack for your uploads", "error");
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'error' } : f
            ));
            return { success: false };
        }
    
        abortControllerRef.current = new AbortController();
    
        try {
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'uploading' } : f
            ));
            
            // Add the sound pack to the file object
            const fileWithSoundpack = {
                ...file,
                sound_packs: [{ id: selectedSoundpack.id }]
            };
    
            const result = await createSound(fileWithSoundpack, abortControllerRef.current.signal);
    
            if (uploadCancelRef.current) {
                setFiles(prevFiles => prevFiles.map(f =>
                    f.id === file.id ? { ...f, uploadStatus: 'cancelled' } : f
                ));
                return { success: false };
            }
    
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'success' } : f
            ));
    
            // Show success message without triggering dialog reopening
            showToast(`Successfully uploaded ${file.name}`, "success");
            return { success: true, soundpack: selectedSoundpack };
        } catch (error) {
            console.error(`Error uploading ${file.name}:`, error);
    
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'error' } : f
            ));
            showToast(`Failed to upload ${file.name}: ${error.message}`, "error");
            return { success: false };
        }
    };

    const handleCancelUpload = () => {
        uploadCancelRef.current = true; // Set the cancel flag
    
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
        }
    
        // Only cancel pending or uploading files
        setFiles(prevFiles => prevFiles.map(f => {
            if (f.uploadStatus === 'uploading' || f.uploadStatus === 'pending') {
                return { ...f, uploadStatus: 'cancelled' };
            }
            return f; // Leave successful and error files unchanged
        }));
    
        setIsUploading(false);
    };

    // Handler for selecting existing sound pack
    const handleSelectSoundpack = (soundpack) => {
        setSelectedSoundpack(soundpack);
        setCreatingNewSoundpack(false);
    };

    // Handler for creating new sound pack
    const handleCreateNewSoundpack = async (e) => {
        e.preventDefault();
        
        if (!newSoundpackName.trim()) {
            showToast("Please enter a name for your sound pack", "error");
            return;
        }

        try {
            setIsLoading(true);
            const uniqueFolder = uuidv4();
            let avatarUrl = null;

            // Upload image if provided
            if (soundpackImage) {
                const imageFileName = `soundpacks/${uniqueFolder}/${soundpackImage.name}`;
                const uploadResult = await uploadFileToServer(
                    null,
                    null,
                    null,
                    soundpackImage,
                    imageFileName
                );
                avatarUrl = uploadResult.image_file_url;
            }

            // Create the soundpack
            const soundpackData = {
                name: newSoundpackName,
                avatar: avatarUrl,
                sounds: []
            };

            const newSoundpack = await createSoundpack(soundpackData);
            
            // Add the new soundpack to the list and select it
            setSoundpacks(prev => [newSoundpack, ...prev]);
            setSelectedSoundpack(newSoundpack);
            
            // Close the dialog
            setShowSoundPackDialog(false);
            showToast("Sound pack created successfully", "success");
        } catch (error) {
            console.error("Error creating sound pack:", error);
            showToast("Failed to create sound pack. Please try again.", "error");
        } finally {
            setIsLoading(false);
        }
    };

    // Handler for image upload in new sound pack creation
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSoundpackImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Handler for closing the dialog and continuing with the selected pack
    const handleConfirmSoundpackSelection = () => {
        // If we're creating a new soundpack but haven't saved it yet
        if (creatingNewSoundpack && newSoundpackName.trim()) {
            showToast("Please create the sound pack first by clicking 'Create Sound Pack'", "error");
            return;
        }
        
        // If no soundpack is selected at all
        if (!selectedSoundpack) {
            showToast("Please select an existing sound pack or create a new one", "error");
            return;
        }
        
        setShowSoundPackDialog(false);
    };

    return (
        <div className="flex flex-col h-[calc(100vh-100px)] overflow-hidden bg-transparent">
                        {/* Pricing Modal */}
                        <PricingModal 
                isOpen={isPricingOpen} 
                setIsOpen={setIsPricingOpen} 
            />

            {/* Sound Pack Selection Dialog */}
            <CustomDialog
                isOpen={showSoundPackDialog}
                onClose={() => {
                    // Only allow closing if a soundpack is selected
                    if (selectedSoundpack) {
                        setShowSoundPackDialog(false);
                    } else {
                        showToast("Please select a sound pack before continuing", "error");
                    }
                }}
                title="Select a Sound Pack"
                maxWidth="md:max-w-3xl"
            >
                <div className="space-y-6">
                    <p className="text-sm text-text-secondary">
                        Please select an existing sound pack or create a new one for your batch upload.
                    </p>
                    
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                        {/* Existing Sound Packs */}
                        <div className="space-y-4">
                            <h3 className="text-md font-medium">Your Sound Packs</h3>
                            
                            <div className="max-h-96 overflow-y-auto pr-2 custom-scrollbar space-y-3">
                                {soundpacks.length > 0 ? soundpacks.map(pack => (
                                    <div 
                                        key={pack.id}
                                        onClick={() => handleSelectSoundpack(pack)}
                                        className={`flex items-center space-x-3 p-3 rounded-lg cursor-pointer transition-all ${selectedSoundpack?.id === pack.id 
                                            ? 'bg-accent-end/20 border-2 border-accent-end' 
                                            : 'bg-bg-secondary hover:bg-white/10 border-2 border-transparent'}`}
                                    >
                                        <div className="w-12 h-12 rounded-md overflow-hidden flex-shrink-0">
                                            {pack.avatar ? (
                                                <img 
                                                    src={pack.avatar} 
                                                    alt={pack.name} 
                                                    className="w-full h-full object-cover"
                                                />
                                            ) : (
                                                <div className="w-full h-full bg-bg-primary flex items-center justify-center">
                                                    <Music className="w-6 h-6 text-text-secondary" />
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex-1 min-w-0">
                                            <h4 className="text-sm font-medium truncate">{pack.name}</h4>
                                            <p className="text-xs text-text-tertiary">
                                                {pack.sounds?.length || 0} sounds
                                            </p>
                                        </div>
                                    </div>
                                )) : (
                                    <p className="text-sm text-text-tertiary py-4 text-center">
                                        You don't have any sound packs yet.
                                    </p>
                                )}
                            </div>
                        </div>
                        
                        {/* Create New Sound Pack */}
                        <div className="space-y-4">
                            <h3 className="text-md font-medium">Create New Sound Pack</h3>
                            
                            <div className={`p-4 rounded-lg border-2 ${creatingNewSoundpack 
                                ? 'border-accent-end bg-accent-end/10' 
                                : 'border-white/20 bg-bg-secondary'}`}
                            >
                                <div className="flex flex-col space-y-4">
                                    <div className="flex items-start space-x-4">
                                        {/* Image upload */}
                                        <div className="w-20 h-20 rounded-md overflow-hidden flex-shrink-0 border-2 border-dashed border-white/20 relative group">
                                            {previewImage ? (
                                                <div className="relative w-full h-full">
                                                    <img 
                                                        src={previewImage} 
                                                        alt="Sound pack preview" 
                                                        className="w-full h-full object-cover"
                                                    />
                                                    <button
                                                        onClick={() => {
                                                            setSoundpackImage(null);
                                                            setPreviewImage(null);
                                                        }}
                                                        className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-all"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            ) : (
                                                <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer hover:bg-white/10 transition-all">
                                                    <ImageIcon className="w-6 h-6 text-text-secondary mb-1" />
                                                    <span className="text-[10px] text-text-tertiary text-center px-1">Add image</span>
                                                    <input 
                                                        type="file" 
                                                        className="hidden" 
                                                        accept="image/*" 
                                                        onChange={handleImageChange}
                                                        onClick={() => setCreatingNewSoundpack(true)}
                                                    />
                                                </label>
                                            )}
                                        </div>
                                        
                                        {/* Name input */}
                                        <div className="flex-1 space-y-2">
                                            <input
                                                type="text"
                                                placeholder="Sound Pack Name"
                                                value={newSoundpackName}
                                                onChange={(e) => {
                                                    setNewSoundpackName(e.target.value);
                                                    setCreatingNewSoundpack(true);
                                                }}
                                                className="w-full bg-bg-primary rounded-md border-2 border-white/20 px-3 py-2 text-sm focus:border-accent-end focus:ring-1 focus:ring-accent-end transition-all"
                                            />
                                            <p className="text-xs text-text-tertiary">
                                                Create a new sound pack for your uploaded sounds.
                                            </p>
                                        </div>
                                    </div>
                                    
                                    <button
                                        onClick={handleCreateNewSoundpack}
                                        disabled={!newSoundpackName.trim() || isLoading}
                                        className={`w-full py-2 rounded-md text-sm font-medium 
                                            ${newSoundpackName.trim() && !isLoading 
                                                ? 'bg-accent-end text-white hover:bg-accent-end/90' 
                                                : 'bg-white/10 text-text-secondary cursor-not-allowed'} 
                                            transition-all`}
                                    >
                                        {isLoading ? 'Creating...' : 'Create Sound Pack'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex justify-end pt-4 border-t border-white/10">
                        <button
                            onClick={handleConfirmSoundpackSelection}
                            disabled={!selectedSoundpack && !(creatingNewSoundpack && newSoundpackName.trim())}
                            className={`px-6 py-2 rounded-md text-sm font-medium 
                                ${(selectedSoundpack && !creatingNewSoundpack) 
                                    ? 'bg-accent-end text-white hover:bg-accent-end/90' 
                                    : 'bg-white/10 text-text-secondary cursor-not-allowed'} 
                                transition-all`}
                        >
                            {selectedSoundpack 
                                ? `Continue with ${selectedSoundpack.name}` 
                                : (creatingNewSoundpack && newSoundpackName.trim()) 
                                    ? 'Save Sound Pack First' 
                                    : 'Select a Sound Pack'}
                        </button>
                    </div>
                </div>
            </CustomDialog>

            <div className="flex-none p-4 sm:p-8 sm:pb-4">
                <div className="flex flex-col md:flex-row md:justify-between md:items-center space-y-2 md:space-y-0">
                    <h1 className="text-3xl font-bold">Batch Upload Sounds</h1>
                    {selectedSoundpack && (
                        <div className="bg-bg-secondary rounded-lg p-3 border border-white/10 flex items-center space-x-3 max-w-md">
                            <div className="w-10 h-10 rounded-md overflow-hidden flex-shrink-0">
                                {selectedSoundpack.avatar ? (
                                    <img 
                                        src={selectedSoundpack.avatar} 
                                        alt={selectedSoundpack.name} 
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    <div className="w-full h-full bg-bg-primary flex items-center justify-center">
                                        <Music className="w-5 h-5 text-text-secondary" />
                                    </div>
                                )}
                            </div>
                            <div className="flex-1 min-w-0">
                                <p className="text-xs text-text-secondary">Sound Pack:</p>
                                <h4 className="text-sm font-medium truncate">{selectedSoundpack.name}</h4>
                            </div>
                            <button 
                                onClick={() => setShowSoundPackDialog(true)}
                                className="text-xs text-accent-end hover:text-accent-end/80 transition-all"
                            >
                                Change
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {!isSubscribed ? (
                <div className="flex-1 flex flex-col items-center justify-center px-4 sm:px-8 gap-6">
                    <div className="text-center max-w-lg">
                        <h2 className="text-2xl font-semibold mb-4">Subscription Required</h2>
                        <p className="mb-6 text-text-secondary">You need a Soundbank subscription to upload audio files. Upgrade your account to access this feature and more.</p>
                        <button
                            onClick={() => setIsPricingOpen(true)}
                            className="bg-gradient-to-r from-accent-start to-accent-end text-white font-semibold px-6 py-3 text-base rounded-full hover:opacity-90 transition-all"
                        >
                            View Pricing Plans
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex-1 min-h-0 px-4 sm:px-8 overflow-hidden flex flex-col gap-6">
                {files.length === 0 ? (
                    <div 
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        className={`
                            flex-1
                            relative
                            border-2 border-dashed rounded-lg
                            transition-all duration-300 ease-in-out
                            flex flex-col items-center justify-center
                            ${isDragging 
                                ? 'border-accent-end bg-accent-end/10 scale-[1.02]' 
                                : 'border-gray-300 hover:border-accent-end hover:bg-accent-end/5'
                            }
                        `}
                    >
                        <Upload 
                            className={`w-16 h-16 mb-4 transition-all duration-300 ${
                                isDragging ? 'text-accent-end scale-110' : 'text-gray-400'
                            }`}
                        />
                        <div className="space-y-2 text-center">
                            <p className="text-xl font-medium">
                                {isDragging ? 'Drop your files here' : 'Drag & drop your WAV files'}
                            </p>
                            <button
                                onClick={() => {
                                    // Check if soundpack is selected before allowing to browse
                                    if (!selectedSoundpack) {
                                        showToast("Please select a sound pack before uploading files", "error");
                                        setShowSoundPackDialog(true);
                                        return;
                                    }
                                    handleBrowseClick();
                                }}
                                className="text-sm text-secondary hover:text-accent-end transition-colors hover:underline underline-offset-4 hover:font-medium focus:outline-none"
                            >
                                or click here to browse
                            </button>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileInput}
                            multiple
                            accept="audio/wav"
                            className="hidden"
                            style={{ display: 'none' }}
                        />
                    </div>
                ) : (
                    <div className="h-[calc(100vh-250px)] bg-transparent">
<BatchUploadTable
    files={files}
    setFiles={setFiles}
    soundTypes={soundTypes}
    availableTags={availableTags}
    setAvailableTags={setAvailableTags}       // Add these
    availableGenres={availableGenres}
    setAvailableGenres={setAvailableGenres}   // Add these
    availableInstruments={availableInstruments}
    setAvailableInstruments={setAvailableInstruments} // Add these
    soundpacks={soundpacks}
    setSoundpacks={setSoundpacks}             // Add these
    licenses={licenses}
    createSound={handleUpload}
    createTag={createTag}
    createGenre={createGenre}
    createInstrument={createInstrument}
    createSoundpack={createSoundpack}
    setErrorMessage={(msg) => showToast(msg, "error")}
    editMode={false}
    handleCancelUpload={handleCancelUpload}
    uploadCancelRef={uploadCancelRef}
    handleUpload={handleUpload}
    setCurrentPlayingSound={setCurrentPlayingSound}
    setIsPlaying={setIsPlaying}
    currentPlayingSound={currentPlayingSound}
    isPlaying={isPlaying}
/>
                    </div>
                )}
            </div>
                        )}

        </div>
        
    );
};

export default BatchUpload;