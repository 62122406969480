import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { CustomDialog } from './CustomComponents';
import SpinnerFull from './SpinnerFull';

// Animation variants for the pricing cards
const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  },
  hover: {
    y: -8,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  }
};

const PricingCard = ({ plan, isPopular, onSelect }) => (
  <motion.div
    whileHover={{ y: -8 }}
    className={`relative p-6 rounded-2xl backdrop-blur-xl border ${
      isPopular ? 'bg-gradient-to-b from-accent-start/10 to-accent-end/10 border-accent-start/20' 
      : 'bg-bg-secondary/50 border-white/10'
    }`}
  >
    {isPopular && (
      <div className="absolute -top-4 left-1/2 -translate-x-1/2 px-4 py-1 rounded-full bg-gradient-to-r from-accent-start to-accent-end text-white text-sm">
        Most Popular
      </div>
    )}
    <div className="text-xl font-bold mb-2">{plan.name}</div>
    <div className="flex items-baseline mb-4">
      <span className="text-3xl font-bold">${plan.price}</span>
      <span className="text-text-secondary">/month</span>
    </div>
    <div className="text-text-secondary mb-6">
      {plan.tokens} tokens monthly
    </div>
    <ul className="space-y-3 mb-6">
      {plan.features.map((feature, index) => (
        <li key={index} className="flex items-center text-sm">
          <span className="mr-2 text-accent-start">✓</span>
          {feature}
        </li>
      ))}
    </ul>
    <motion.button
      whileHover={{ scale: 1.02 }}
      onClick={() => onSelect(plan)}
      whileTap={{ scale: 0.98 }}
      className={`w-full py-3 rounded-xl font-medium ${
        isPopular 
        ? 'bg-gradient-to-r from-accent-start to-accent-end text-white' 
        : 'bg-white/10 text-white hover:bg-white/20'
      }`}
    >
      Choose {plan.name}
    </motion.button>
  </motion.div>
);

const pricingPlans = [
  {
    name: "Starter",
    price: "4.99",
    tokens: 25,
    url: 'https://store.soundware.io/?rest_route=/simple-jwt-login/v1/autologin&JWT=' + localStorage.getItem('wordpress_token') + '&redirectUrl=https://store.soundware.io?add-to-cart=166941',
    features: [
      "25 monthly tokens",
      "1GB sound upload storage",
      "Standalone/VST/AU Plugin"
    ]
  },
  {
    name: "Pro",
    price: "19.99",
    tokens: 150,
    url: 'https://store.soundware.io/?rest_route=/simple-jwt-login/v1/autologin&JWT=' + localStorage.getItem('wordpress_token') + '&redirectUrl=https://store.soundware.io?add-to-cart=166976',
    features: [
      "150 monthly tokens",
      "4GB sound upload storage",
      "Standalone/VST/AU Plugin"
    ]
  },
  {
    name: "Ultimate",
    price: "49.99",
    tokens: 500,
    url: 'https://store.soundware.io/?rest_route=/simple-jwt-login/v1/autologin&JWT=' + localStorage.getItem('wordpress_token') + '&redirectUrl=https://store.soundware.io?add-to-cart=166978',
    features: [
      "500 monthly tokens",
      "10GB sound upload storage",
      "Standalone/VST/AU Plugin"
    ]
  }
];

// Add this new component for the landing page
export const PricingSection = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePlanSelect = (plan) => {
    // Show the spinner before navigation
    setIsLoading(true);
    setTimeout(() => {
      window.location.href = plan.url;
    }, 100); // Small timeout to ensure spinner renders
  };

  return (
    <>
      {isLoading && <SpinnerFull message="Loading your cart..." />}
      {pricingPlans.map((plan, index) => (
        <motion.div
          key={index}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={cardVariants}
          transition={{ delay: index * 0.2 }}
        >
          <PricingCard 
            plan={plan} 
            isPopular={index === 1} 
            onSelect={handlePlanSelect}
          />
        </motion.div>
      ))}
    </>
  );
};

// Keep the existing PricingModal component
const PricingModal = ({ isOpen, setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePlanSelect = (plan) => {
    // Show the spinner before navigation
    setIsLoading(true);
    setTimeout(() => {
      window.location.href = plan.url;
    }, 100); // Small timeout to ensure spinner renders
  };

  return (
    <>
      {isLoading && <SpinnerFull message="Loading your cart..." />}
      <CustomDialog 
        isOpen={isOpen} 
        onClose={() => setIsOpen(false)}
        title="Choose Your Plan"
        maxWidth="md:max-w-7xl"
      >
      <div className="grid md:grid-cols-3 gap-8">
        {pricingPlans.map((plan, index) => (
          <motion.div
            key={index}
            initial="hidden"
            animate="visible"
            variants={cardVariants}
            transition={{ delay: index * 0.2 }}
          >
            <PricingCard 
              plan={plan} 
              isPopular={index === 1} 
              onSelect={handlePlanSelect}
            />
          </motion.div>
        ))}
      </div>
    </CustomDialog>
    </>
  );
};

export { PricingCard, pricingPlans };
export default PricingModal;