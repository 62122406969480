import React, { useState, useEffect, useRef } from 'react';
import { JUCESend, isJUCE } from '../context/JUCE';

export const WaveformStartPointFinder = ({ audioUrl, onLoopPointChange }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);
  
  const CANVAS_HEIGHT = 150;
  const PIXELS_PER_SECOND = 200;
  
  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);
  
  useEffect(() => {
    const fetchAudio = async () => {
      try {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const buffer = await audioContext.decodeAudioData(arrayBuffer);
        setAudioBuffer(buffer);
        
        const canvas = canvasRef.current;
        canvas.width = Math.ceil(buffer.duration * PIXELS_PER_SECOND);
        canvas.height = CANVAS_HEIGHT;
        
        drawWaveform(buffer);
      } catch (error) {
        console.error('Error loading audio:', error);
      }
    };
    
    fetchAudio();
  }, [audioUrl]);
  
  const drawWaveform = (buffer) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const data = buffer.getChannelData(0);
    const step = Math.ceil(data.length / canvas.width);
    const amp = CANVAS_HEIGHT / 2;
    
    ctx.fillStyle = '#232323';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    ctx.beginPath();
    ctx.moveTo(0, amp);
    
    ctx.strokeStyle = '#ffffff';
    ctx.lineWidth = 1;
    
    for (let i = 0; i < canvas.width; i++) {
      let min = 1.0;
      let max = -1.0;
      
      for (let j = 0; j < step; j++) {
        const datum = data[(i * step) + j];
        if (datum < min) min = datum;
        if (datum > max) max = datum;
      }
      
      ctx.lineTo(i, (1 + min) * amp);
      ctx.lineTo(i, (1 + max) * amp);
    }
    
    ctx.stroke();
  };
  
  const handleScroll = (e) => {
    if (!audioBuffer) return;
    
    const scrollPosition = e.target.scrollLeft;
    const timePosition = scrollPosition / PIXELS_PER_SECOND;
    const samplePosition = Math.floor(timePosition * audioBuffer.sampleRate);
    
    // Send directly to JUCE
    if (isJUCE()) {
      JUCESend('audioStartPointChanged', { value: samplePosition });
    }
    
    // Also update parent component state if callback provided
    if (onLoopPointChange) {
      onLoopPointChange(samplePosition);
    }
  };
  
  return (
    <div className="relative">
      <div 
        ref={containerRef}
        className="w-full h-40 overflow-x-auto bg-bg-secondary rounded-md"
        onScroll={handleScroll}
      >
        <div className="relative" style={{ 
          paddingLeft: `${Math.floor(containerWidth / 2)}px`, 
          paddingRight: `${Math.floor(containerWidth / 2)}px`,
          transform: 'translateX(8px)'
        }}>
          <canvas
            ref={canvasRef}
            height={CANVAS_HEIGHT}
          />
        </div>
      </div>
      <div 
        className="absolute top-0 left-1/2 w-0.5 h-40 bg-accent-end pointer-events-none" 
        style={{ transform: 'translateX(-50%)' }}
      />
    </div>
  );
};