import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { AuthSection } from './AuthSection';

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

const dialogVariants = {
  hidden: { 
    opacity: 0,
    scale: 0.95,
    y: 10
  },
  visible: { 
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 35,
      stiffness: 450
    }
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    y: 10,
    transition: {
      duration: 0.15
    }
  }
};

export const AuthDialog = ({ 
  isOpen, 
  onClose,
  isLogin,
  setIsLogin,
  ...authProps 
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={onClose}
            className="fixed inset-0 bg-black/60 backdrop-blur-md z-50 flex items-center justify-center"
            style={{ backdropFilter: 'blur(20px)' }}
          >
            {/* Dialog */}
            <motion.div
              variants={dialogVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              onClick={(e) => e.stopPropagation()}
              className="w-full max-w-md mx-4 relative"
            >
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute -top-12 right-0 p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
              >
                <X className="w-5 h-5" />
              </button>

              <AuthSection
                isLogin={isLogin}
                setIsLogin={setIsLogin}
                {...authProps}
              />
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}; 