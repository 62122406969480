// PrivateRoute.jsx
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import SpinnerFull from './SpinnerFull';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading, hasAcceptedTerms } = useContext(AuthContext);

  if (isLoading) {
    return <SpinnerFull />;
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/landing" replace />;
  }

  if (!hasAcceptedTerms) {
    return null; // Don't render anything while terms are being shown
  }

  return children;
}

export default PrivateRoute