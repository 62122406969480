import React, { useState, useContext, useCallback } from 'react';
import { DragIcon, DownloadIcon, TokenBadge } from '../assets/Icons';
import { AuthContext } from '../context/AuthContext';
import PricingModal from './PricingSection';
import Spinner from './Spinner';
import SpinnerFull from './SpinnerFull';
import { getDownloadURL, acceptLicense } from '../api/APIManager';
import { JUCESend, JUCEReceive } from '../context/JUCE';
import { CustomDialog } from './CustomComponents';

const ActionButton = ({
  sound,
  isPurchasing = false,
  isDownloading = false,
  onPurchase,
  onDownload,
  onDragStart = null, // Made optional since we'll handle drag internally
  isJUCE = false,
  isSelectable = false,
  isSelected = false,
  onSelect,
  className = '',
  canDrag = null, // Made optional and will be calculated internally if not provided
}) => {
  const { user } = useContext(AuthContext);
  const [isPricingOpen, setIsPricingOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [isAcceptingLicense, setIsAcceptingLicense] = useState(false);
  const [canAcceptLicense, setCanAcceptLicense] = useState(false);
  const [acceptTimer, setAcceptTimer] = useState(null);
  const [continueToAction, setContinueToAction] = useState(null);
  
  // Calculate canDrag internally if not provided externally
  const canDragInternal = canDrag !== null ? canDrag : 
    isJUCE && sound && sound.is_purchased;
  
  // Base button class with consistent width and spacing
  const baseButtonClass = "w-[72px] h-[36px] px-2 font-medium text-sm border border-white/20 rounded-full flex items-center justify-center mx-1";
  
  // Add background styles for non-loading states
  const buttonClass = `${baseButtonClass} ${isPurchasing || isDownloading ? '' : 'bg-black/50 hover:bg-[#2E1F40]'} text-white transition-all duration-200`;
  
  // Helper function to render license modal
  const renderLicenseModal = () => (
    <CustomDialog 
      isOpen={isLicenseModalOpen} 
      onClose={(e) => {
        e?.stopPropagation();
        setIsLicenseModalOpen(false);
        setContinueToAction(null);
      }}
      title={`${sound.license?.name || 'License'} Agreement`}
    >
      <div className="space-y-4" onClick={(e) => e.stopPropagation()}>
        <div className="h-[calc(100vh-250px)] md:h-[600px] mb-4 bg-bg-primary rounded overflow-hidden">
          <iframe
            src={sound.license?.url}
            className="w-full h-full border-0"
            title="License Agreement"
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsLicenseModalOpen(false);
              setContinueToAction(null);
            }}
            className="px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200 text-text-primary"
          >
            Cancel
          </button>
          {!sound.has_accepted_required_license && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleAcceptLicense();
              }}
              disabled={!canAcceptLicense || isAcceptingLicense}
              className={`
                px-4 py-2 rounded bg-accent-start text-text-primary
                ${(!canAcceptLicense || isAcceptingLicense) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-accent-end'}
                transition-colors duration-200
              `}
            >
              {isAcceptingLicense ? (
                <Spinner />
              ) : !canAcceptLicense ? (
                'Please wait...'
              ) : (
                'Accept'
              )}
            </button>
          )}
        </div>
      </div>
    </CustomDialog>
  );

  // Check if license needs to be accepted before JUCE drag
  const handleDragCheck = (e) => {
    // First check for license requirements
    if (!sound.has_accepted_required_license && sound.license) {
      e.preventDefault();
      e.stopPropagation();
      setIsLicenseModalOpen(true);
      setContinueToAction('drag');
      return false;
    }
    return true;
  };
  
  // Internal drag handler - self-contained within ActionButton
  const handleDragStart = useCallback(async (e) => {
    if (!canDragInternal) return;
    
    // Check license requirements first
    if (!handleDragCheck(e)) return;
    
    e.preventDefault();
    setIsDragging(true);
    
    try {
      // If onDragStart is provided, call it first (for compatibility)
      if (onDragStart) {
        onDragStart(e, sound);
      }
      
      // Handle drag image for better UX
      const dragImage = new Image();
      dragImage.src = sound.image || sound.image2;
      e.dataTransfer.setDragImage(dragImage, 0, 0);
      e.dataTransfer.setData('text/plain', JSON.stringify(sound));
      
      // Get download URL and send to JUCE
      const downloadUrl = await getDownloadURL(sound.id);
      if (!downloadUrl) {
        throw new Error('Failed to get download URL');
      }
      
      const soundWithUrl = { ...sound, download_url: downloadUrl };
      
      // Add a safety timeout to ensure the drag state is reset even if JUCE never responds
      const safetyTimeout = setTimeout(() => {
        setIsDragging(false);
      }, 10000); // 10 second timeout
      
      JUCESend('dragExport', soundWithUrl);
      
      // Set up a specific listener for this export
      const handleExportComplete = ({ eventName }) => {
        if (eventName === 'dragExportComplete') {
          clearTimeout(safetyTimeout);
          setIsDragging(false);
          JUCEReceive.off('JUCEReceive', handleExportComplete);
        }
      };
      
      JUCEReceive.on('JUCEReceive', handleExportComplete);
    } catch (error) {
      console.error('Error during drag export:', error);
      setIsDragging(false);
    }
  }, [canDragInternal, sound, onDragStart]);
  
  const handlePurchaseClick = (e) => {
    e.stopPropagation();
    
    // Check if license needs to be accepted
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToAction('purchase');
      return;
    }
    
    // Check if user has enough credits
    const hasEnoughCredits = user.credits >= sound.cost_in_credits;
    
    if (!hasEnoughCredits && !user.active_subscriptions?.some(sub => 
      sub.toLowerCase().includes('soundbank')
    )) {
      // Show pricing modal if user doesn't have enough credits and no active subscription
      setIsPricingOpen(true);
    } else {
      // Proceed with purchase if they have enough credits
      onPurchase(e);
    }
  };
  
  const handleDownloadClick = (e) => {
    e.stopPropagation();
    
    // Check if license needs to be accepted
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToAction('download');
      return;
    }
    
    // Proceed with download
    onDownload(e);
  };
  
  // Handle license acceptance
  const handleAcceptLicense = async () => {
    if (!sound.license || !sound.license.id) return;

    setIsAcceptingLicense(true);
    try {
      await acceptLicense(sound.license.id);
      
      // Update the sound object locally
      sound.has_accepted_required_license = true;
      
      // Close the modal
      setIsLicenseModalOpen(false);
      
      // Continue with the original action
      if (continueToAction === 'purchase') {
        onPurchase();
      } else if (continueToAction === 'download') {
        onDownload();
      } else if (continueToAction === 'drag') {
        // Trigger a simulated drag event after license is accepted
        // We don't use the original event because it's no longer valid
        // Instead, we update canDragInternal which will enable dragging
      }
      
      // Reset the continue action
      setContinueToAction(null);
    } catch (error) {
      console.error('Error accepting license:', error);
      alert('Failed to accept license. Please try again.');
    } finally {
      setIsAcceptingLicense(false);
    }
  };
  
  // License modal effect
  React.useEffect(() => {
    // Clean up timer when modal closes
    if (!isLicenseModalOpen && acceptTimer) {
      clearTimeout(acceptTimer);
      setAcceptTimer(null);
    }

    // Set new timer when modal opens
    if (isLicenseModalOpen) {
      setCanAcceptLicense(false);
      if (acceptTimer) {
        clearTimeout(acceptTimer);
      }
      const timer = setTimeout(() => {
        setCanAcceptLicense(true);
      }, 3000);
      setAcceptTimer(timer);
    }
  }, [isLicenseModalOpen]);

  // Early returns need to come after all hook declarations
  if (sound.type === undefined) {
    return null;
  }
  
  // Show full-screen spinner during drag processing
  if (isDragging) {
    return <SpinnerFull message="Processing audio, please wait..." />;
  }
  
  if (isSelectable) {
    return (
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onSelect?.(sound);
        }}
        className={`${buttonClass} ${className} ${
          isSelected 
            ? 'bg-accent-end' 
            : 'hover:bg-accent-end/60'
        }`}
      >
        {isSelected ? 'Remove' : 'Add'}
      </button>
    );
  }

  if (isPurchasing) {
    return (
      <button 
        disabled
        className={`${baseButtonClass} ${className}`}
      >
        <Spinner />
      </button>
    );
  }
  
  if (sound.is_purchased || user.whitelisted) {
    if (isJUCE) {
      return (
        <>
          <div 
            className={`${buttonClass} drag-icon ${canDragInternal ? 'cursor-grab active:cursor-grabbing' : 'cursor-not-allowed opacity-60'} ${className}`}
            draggable={canDragInternal}
            onDragStart={handleDragStart}
          >
            <DragIcon className="w-5 h-5" />
          </div>
          
          {/* License Modal */}
          {renderLicenseModal()}
        </>
      );
    } else {
      return (
        <>
          <button 
            onClick={handleDownloadClick}
            className={`${isDownloading ? baseButtonClass : buttonClass} ${className}`}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <Spinner />
            ) : (
              <DownloadIcon className="w-5 h-5" />
            )}
          </button>
          
          {/* License Modal */}
          {renderLicenseModal()}
        </>
      );
    }
  }



  return (
    <>
      <button 
        onClick={handlePurchaseClick}
        className={`${buttonClass} ${className}`}
      >
        {sound.cost_in_credits ? (
          <div className="flex items-center justify-center gap-1">
            <span>{sound.cost_in_credits}</span>
            <TokenBadge className="w-4 h-4" />
          </div>
        ) : (
          'Get'
        )}
      </button>
      
      <PricingModal 
        isOpen={isPricingOpen}
        setIsOpen={setIsPricingOpen}
      />
      
      {/* License Modal */}
      {renderLicenseModal()}
    </>
  );
};

export default ActionButton;