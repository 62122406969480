import React from 'react';

const Spinner = ({ color = "white" }) => (
  <div className="flex justify-center items-center w-full h-full p-2 opacity-50" role="status" aria-live="polite">
    <svg 
      viewBox="0 0 100 100" 
      className="w-full h-full max-w-[32px] max-h-[32px]" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="trebleGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#21D4FD" />
          <stop offset="100%" stopColor="#B721FF" />
        </linearGradient>
      </defs>
      {/* Outer spinning circle with a nearly complete stroke */}
      <circle 
        className="spinner-circle"
        cx="50" 
        cy="50" 
        r="40" 
        stroke="currentColor" 
        strokeWidth="6" 
        fill="none" 
        strokeLinecap="round"
      />

      <style jsx>{`
        .spinner-circle {
          transform-origin: 50% 50%;
          stroke-dasharray: 251;
          stroke-dashoffset: 251;
          animation: spinner-rotate 1s cubic-bezier(0.4, 0, 0.2, 1) infinite, 
                     spinner-dash 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        }
        @keyframes spinner-dash {
          0% {
            stroke-dashoffset: 251;
          }
          50% {
            stroke-dashoffset: 63;
          }
          100% {
            stroke-dashoffset: 251;
          }
        }
        @keyframes spinner-rotate {
          100% {
            transform: rotate(360deg);
          }
        }
        .animate-pulse-treble {
          transform-origin: 50% 50%;
          animation: pulseTreble 2s ease-in-out infinite;
        }
        @keyframes pulseTreble {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.05); }
        }
      `}</style>
    </svg>
  </div>
);

export default Spinner;