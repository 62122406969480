import React, {
  useState,
  useEffect
} from 'react';
import ReactDOM from 'react-dom';
import Lottie from 'lottie-react';
// Import all animations

import pulseAnimation from '../assets/animations/Animation - 1739832143644.json';
import pulseAnimation2 from '../assets/animations/Animation - 1739831880543.json';
import pulseAnimation3 from '../assets/animations/Animation - drag_sound.json';
import pulseAnimation4 from '../assets/animations/Animation - 1739831721055.json';
import pulseAnimation5 from '../assets/animations/Animation - 1739831658482.json';
import pulseAnimation6 from '../assets/animations/Animation - 1739831023227.json';
import pulseAnimation7 from '../assets/animations/Animation - therealpulse.json';
import pulseAnimation8 from '../assets/animations/Animation - 1739827206737.json';
import pulseAnimation9 from '../assets/animations/Animation - therealpixar.json';
import bankAnimation from '../assets/animations/Animation - Bank.json';

/**
 * SpinnerFull shows a full-screen overlay spinner.
 * 
 * When the `useBankAnimation` prop is true, it uses the "Animation - Bank.json"
 * for the animation,
 * otherwise, it falls back to a randomly selected animation.
 *
 * The default loading message is always "Loading...".
 */
const SpinnerFull = ({
  message = "Loading...",
  useBankAnimation = false
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // Array of desired animations for the default spinner
  const animations = [
    //pulseAnimation, //purplemusiccircle
    //pulseAnimation2, // bad circle back
    //pulseAnimation3, //drag sound
    //pulseAnimation4, // good spaceman
    pulseAnimation5, // music note
    //pulseAnimation6, //floating circle dots
    // pulseAnimation7, //pulse
    //pulseAnimation8, //starbit floater
    //pulseAnimation9,//pixar looking one
  ];

  // Select one random animation when the component mounts
  const [selectedAnimation] = useState(() => {
    if (!animations.length) {
      console.warn("No animations available for SpinnerFull.");
      return null;
    }
    const randomIndex = Math.floor(Math.random() * animations.length);
    return animations[randomIndex];
  });

  const spinnerContent = (
    <div className="fixed inset-0 bg-bg-primary flex flex-col justify-center items-center z-[99999]">
      {/*
        We wrap the animation in a container with explicit width/height.
        This ensures that any transform (like scaling) does not disrupt the layout.
      */}
      <div className="mb-4 w-64 h-64">
        {useBankAnimation ? (
          <div className="w-full h-full scale-[0.5]">
            <Lottie animationData={bankAnimation} loop={true} />
          </div>
        ) : (
          <div className="w-full h-full">
            <Lottie animationData={selectedAnimation} loop={true} />
          </div>
        )}
      </div>
      {/*
        Added mt-4 and a relative z-index so that the text appears below the spinner,
        and it isn't affected by any overlapping elements from the animation.
      */}
      {message && (
        <p className="mt-4 relative z-10 text-text-primary text-2xl">
          {message}
        </p>
      )}
    </div>
  );

  if (!mounted) {
    return null;
  }

  return ReactDOM.createPortal(
    spinnerContent,
    document.body
  );
};

export default SpinnerFull;