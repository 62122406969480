//Landing2.js
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Logo } from '../components/Logo';
import SpinnerFull from '../components/SpinnerFull';
import { motion, AnimatePresence } from 'framer-motion';
import PricingSection from '../components/PricingSection';
import axios from 'axios';
import { isJUCE } from '../context/JUCE';

const PartnerCarousel = () => {
  const partners = [
    {
      name: "Beatclub",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/1.png",
      alt: "Beatclub Logo",
      isDark: false,//
    },
    {
      name: "Soundtrack Loops",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/STL-logo-white.png",
      alt: "Soundtrack Loops Logo",
      isDark: false
    },
    {
      name: "AudioTech Hub",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/Audio-tech-hub-logo.png",
      alt: "AudioTech Hub Logo",
      isDark: false
    },
    {
      name: "Infinit Audio",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/Infinitaudio-white.png",
      alt: "Infinit Audio Logo",
      isDark: false
    },
    {
      name: "Flosstradamus",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/3.png",
      alt: "Flosstradamus Logo",
      isDark: false,
    }
  ];

  return (
    <section className="relative py-24 md:py-32 overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-bg-primary" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/10 to-transparent" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-6 sm:px-8 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
            Trusted By{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
              Industry Leaders
            </span>
          </h2>
          <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto">
            Partnered with the world's leading music technology companies and artists
          </p>
        </motion.div>

        <div className="relative">
          <motion.div 
            className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 xs:gap-4 sm:gap-6"
          >
            {partners.map((partner, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="flex flex-col items-center mb-8 sm:mb-0"
              >
                <motion.div 
                  className={`group relative h-40 xs:h-32 sm:h-32 w-full rounded-3xl p-8 xs:p-6 sm:p-6 ${
                    partner.isDark 
                      ? "bg-white/20" 
                      : "bg-bg-secondary/30"
                  } backdrop-blur-md border border-white/10 transition-all duration-300`}
                  whileHover={{
                    scale: 1.02,
                    borderColor: "rgba(255, 255, 255, 0.2)",
                  }}
                >
                  {/* Subtle gradient overlay */}
                  <div className="absolute inset-0 rounded-3xl bg-gradient-to-b from-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  
                  {/* Partner logo */}
                  <div className="relative h-full flex items-center justify-center px-6 xs:px-4">
                    <img
                      src={partner.logo}
                      alt={partner.alt}
                      className={`max-w-full max-h-full object-contain ${
                        partner.isDark 
                          ? "filter brightness-0 invert" 
                          : "filter brightness-90 group-hover:brightness-100"
                      } ${partner.name !== "AudioTech Hub" ? "scale-100 md:scale-150" : ""} transition-all duration-300 ${partner.className || ""}`}
                    />
                  </div>
                </motion.div>
                
                {/* Partner name with enhanced mobile typography */}
                <motion.p 
                  className="mt-5 xs:mt-4 sm:mt-3 text-lg sm:text-xl font-light text-text-secondary/80 group-hover:text-text-secondary transition-colors duration-300"
                >
                  {partner.name}
                </motion.p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

// Animation variants
const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  },
  hover: {
    y: -8,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  }
};

const GradientButton = ({ children, onClick, className = "" }) => {
  const buttonRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    // Check if device is mobile (iOS or Android)
    const checkMobile = () => {
      const ua = navigator.userAgent;
      return /android|iphone|ipad|ipod/i.test(ua);
    };
    setIsMobileDevice(checkMobile());
  }, []);

  const handleMouseMove = (e) => {
    if (!buttonRef.current || isMobileDevice) return;
    
    const rect = buttonRef.current.getBoundingClientRect();
    setPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  // Simple button for mobile devices
  if (isMobileDevice) {
    return (
      <motion.button
        whileTap={{ scale: 0.98 }}
        onClick={onClick}
        className={`relative overflow-hidden ${className}`}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-accent-start to-accent-end" />
        <div className="relative px-8 py-3 font-medium">
          {children}
        </div>
      </motion.button>
    );
  }

  return (
    <motion.button
      ref={buttonRef}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      className={`relative overflow-hidden ${className}`}
    >
      {isHovered && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0"
          style={{
            background: `radial-gradient(circle at ${position.x}px ${position.y}px, rgba(255,255,255,0.2) 0%, transparent 70%)`,
          }}
        />
      )}
      <div className="absolute inset-0 bg-gradient-to-r from-accent-start to-accent-end" />
      <div className="absolute inset-0 opacity-0 hover:opacity-100 bg-gradient-to-r from-accent-start/90 to-accent-end/90 transition-opacity duration-300" />
      <div className="relative px-8 py-3 font-medium">
        {children}
      </div>
    </motion.button>
  );
};

const PricingCard = ({ plan, isPopular, onSelect }) => {
  return (
    <motion.div
      variants={cardVariants}
      whileHover="hover"
      className={`pricing-card relative p-px rounded-2xl overflow-hidden ${
        isPopular 
          ? "bg-gradient-to-b from-accent-start to-accent-end" 
          : "bg-gray-700/30"
      }`}
    >
      {isPopular && (
        <div className="absolute top-0 left-0 right-0 bg-gradient-to-r from-accent-start to-accent-end py-1 px-3 text-center z-10">
          <p className="text-white text-xs font-semibold uppercase tracking-wider">Most Popular</p>
        </div>
      )}
      
      <div className="bg-bg-secondary/60 backdrop-blur-sm rounded-2xl p-6 h-full relative z-1">
        <h3 className="text-2xl font-bold text-text-primary">{plan.name}</h3>
        <div className="mt-4 flex items-baseline">
          <span className="text-5xl font-extrabold text-text-primary">${plan.price}</span>
          <span className="ml-1 text-xl text-text-secondary">/month</span>
        </div>
        <p className="mt-2 text-lg text-purple-300">{plan.tokens} tokens monthly</p>
        
        <ul className="mt-6 space-y-4">
          {plan.features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <div className="rounded-full bg-green-500/20 p-1 mr-3">
                <svg className="h-4 w-4 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </div>
              <span className="text-text-secondary">{feature}</span>
            </li>
          ))}
        </ul>
        
        <button 
          onClick={() => onSelect(plan)}
          className={`mt-8 w-full py-3 px-4 rounded-full text-white font-medium transition-all hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-end ${
            isPopular 
              ? "bg-gradient-to-r from-accent-start to-accent-end hover:from-accent-start/90 hover:to-accent-end/90" 
              : "bg-gray-700 hover:bg-gray-600"
          }`}
        >
          Get Started
        </button>
      </div>
    </motion.div>
  );
};

const Landing2Page = () => {
  // State management
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [showStickyButton, setShowStickyButton] = useState(false);
  const navigate = useNavigate();
  const { isLoading } = useContext(AuthContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Refs for scroll targets
  const soundsSectionRef = useRef(null);
  const pricingSectionRef = useRef(null);
  const creatorSectionRef = useRef(null);
  const heroSectionRef = useRef(null);
  const downloadSectionRef = useRef(null);

  // Smooth scroll function
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      setIsMobileMenuOpen(false);
      setTimeout(() => {
        const yOffset = -30; // Less negative value to scroll higher
        const element = ref.current;
        
        // Check if device is mobile (using window width as a simple check)
        const isMobile = window.innerWidth < 768; // Common breakpoint for mobile
        
        // For pricing section on mobile only, apply custom scroll position
        if (ref === pricingSectionRef && isMobile) {
          // Get the pricing section
          const pricingGrid = element.querySelector('.pricing-grid');
          if (pricingGrid) {
            // Scroll to position the pricing grid closer to the top
            const rect = pricingGrid.getBoundingClientRect();
            const scrollY = window.scrollY + rect.top + 250; // Position grid from top of viewport
            
            window.scrollTo({
              top: scrollY,
              behavior: 'smooth'
            });
            return;
          }
          
          // Fallback if can't find pricing grid
          const rect = element.getBoundingClientRect();
          const scrollY = window.scrollY + rect.top + 250; // Scroll deeper into the section
          
          window.scrollTo({
            top: scrollY,
            behavior: 'smooth'
          });
          return;
        }
        
        // Default behavior for desktop or non-pricing sections
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  const handleMenuClick = (sectionRef) => {
    scrollToSection(sectionRef);
  };

  // Control sticky button visibility on scroll
  useEffect(() => {
    const handleScroll = () => {
      // Show button after scrolling 300px
      setShowStickyButton(window.scrollY > 300);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    setIsMobileMenuOpen(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const features = [
    {
      title: "Unlimited Inspiration",
      description: "A community filled with inspiration and sounds from professional and independent creators around the world.",
      icon: "💡"
    },
    {
      title: "Cross-Platform",
      description: "Available on Mac, Windows, iOS, Web, with Android coming soon. Plus VST/VST3/AU plugin and standalone support.",
      icon: "🔄"
    },
    {
      title: "Access your sounds from anywhere",
      description: "Whether you've uploaded your own private collection or purchased in the marketplace, you'll always have access to your sounds.",
      icon: "📤"
    },
    {
      title: "Pro Audio Tools",
      description: "Time-stretch and pitch-shift samples to match your project's BPM and key. Perfect sync, every time.",
      icon: "🎵"
    }
  ];

  const plans = [
    {
      name: "Starter",
      price: "4.99",
      tokens: 25,
      url: "https://store.soundware.io?add-to-cart=166941",
      features: [
        "25 monthly tokens",
        "1GB sound upload storage",
        "SoundBank Standalone/VST/AU Plugin",
        "OCTANE VST/AU Plugin (2,000+ Instruments)"
      ]
    },
    {
      name: "Pro",
      price: "19.99",
      tokens: 150,
      url: "https://store.soundware.io?add-to-cart=166976",
      features: [
        "150 monthly tokens",
        "4GB sound upload storage",
        "SoundBank Standalone/VST/AU Plugin",
        "OCTANE VST/AU Plugin (2,000+ Instruments)"
      ]
    },
    {
      name: "Ultimate",
      price: "49.99",
      tokens: 500,
      url: "https://store.soundware.io?add-to-cart=166978",
      features: [
        "500 monthly tokens",
        "10GB sound upload storage",
        "SoundBank Standalone/VST/AU Plugin",
        "OCTANE VST/AU Plugin (2,000+ Instruments)"
      ]
    }
  ];

  if (isLoading) {
    return <SpinnerFull />;
  }

  const handlePlanSelect = (plan) => {
    // Show the spinner before navigation
    setIsCartLoading(true);
    setTimeout(() => {
      window.location.href = plan.url;
    }, 100); // Small timeout to ensure spinner renders
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-bg-primary via-bg-primary/95 to-bg-primary relative overflow-hidden">
      {isCartLoading && <SpinnerFull message="Loading your cart..." />}
      {/* Add a persistent background gradient effect */}
      <div className="fixed inset-0 pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-accent-start/5 to-transparent opacity-30" />
        <div className="absolute w-full h-full">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[1200px] h-[1200px]">
            <div className="w-full h-full bg-gradient-to-r from-accent-start/10 to-accent-end/10 rounded-full blur-[150px] animate-pulse duration-[5000ms]" />
          </div>
        </div>
      </div>

      {/* Navigation - With added animations */}
      <nav className="fixed w-full z-50 px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
            className="bg-bg-secondary/30 backdrop-blur-xl border border-white/10 rounded-full px-6 py-3"
          >
            <div className="flex items-center justify-between">
              {/* Logo - Left aligned with animation */}
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="flex items-center"
                onClick={scrollToTop}
                aria-label="Scroll to top"
              >
                <Logo className="h-6 w-auto" />
              </motion.button>

              {/* Navigation Links - Centered with staggered animation */}
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="hidden md:flex items-center justify-between flex-1 mx-16"
              >
                {[
                  { label: "", ref: pricingSectionRef },
                  { label: "Sounds", ref: soundsSectionRef },
                  { label: "For Creators", ref: creatorSectionRef },
                  { label: "Pricing", ref: pricingSectionRef },
                ].map((item, index) => (
                  <motion.button 
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.3 + (index * 0.1) }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleMenuClick(item.ref)}
                    className="text-text-secondary hover:text-text-primary transition-colors text-base font-medium"
                  >
                    {item.label}
                  </motion.button>
                ))}
              </motion.div>

              {/* Buy Now Button - Right aligned with animation */}
              <div className="hidden md:flex items-center">
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.7 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => scrollToSection(pricingSectionRef)}
                  className="bg-gradient-to-r from-accent-start to-accent-end text-white px-6 py-2 rounded-full text-base font-medium"
                >
                  Buy Now
                </motion.button>
              </div>

              {/* Mobile Menu Button with animation */}
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4, delay: 0.5 }}
                className="flex md:hidden"
              >
                <button 
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="text-text-secondary hover:text-text-primary p-2 rounded-full"
                  aria-label="Toggle menu"
                >
                  {!isMobileMenuOpen ? (
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  ) : (
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  )}
                </button>
              </motion.div>
            </div>
          </motion.div>

          {/* Mobile Menu with enhanced animations */}
          <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.div
                initial={{ opacity: 0, y: -20, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: -20, scale: 0.95 }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                className="md:hidden absolute left-4 right-4 mt-2 p-4 bg-bg-secondary/95 backdrop-blur-xl rounded-2xl border border-white/10"
              >
                <div className="px-4 py-4 space-y-4">
                  {[
                    { label: "For Creators", ref: creatorSectionRef },
                    { label: "Sounds", ref: soundsSectionRef },
                    { label: "Pricing", ref: pricingSectionRef },
                  ].map((item, index) => (
                    <motion.button
                      key={index}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.05, duration: 0.2 }}
                      onClick={() => handleMenuClick(item.ref)}
                      className="block w-full text-left px-4 py-2 text-text-secondary hover:text-text-primary"
                    >
                      {item.label}
                    </motion.button>
                  ))}
                  <motion.button
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.25, duration: 0.2 }}
                    onClick={() => scrollToSection(pricingSectionRef)}
                    className="block w-full mt-2 bg-gradient-to-r from-accent-start to-accent-end text-center text-white px-4 py-2 rounded-full"
                  >
                    Buy Now
                  </motion.button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>

      {/* Hero Section */}
      <div ref={heroSectionRef} className="relative pt-32 pb-24 sm:pt-40 sm:pb-32">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-8"
        >
          <div className="text-center">
            {/* Main Header with gradient text */}
            <h1 className="text-5xl xs:text-6xl sm:text-7xl md:text-8xl font-bold tracking-tight text-text-primary mx-auto max-w-4xl">
              The Ultimate{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                VST Plugin
              </span>{' '}
              For Producers
            </h1>
            
            {/* Subheader with slightly reduced opacity */}
            <p className="mt-6 text-lg sm:text-xl md:text-2xl font-light max-w-2xl mx-auto text-text-secondary/80">
              Seamlessly discover, manage and share sounds directly in your DAW with the world's first social media VST plugin.
            </p>
            
            {/* CTA buttons with enhanced styling and animations */}
            <div className="mt-10 flex flex-col sm:flex-row justify-center gap-4 sm:gap-6">
              <GradientButton 
                onClick={() => scrollToSection(pricingSectionRef)} 
                className="w-full sm:w-auto text-white rounded-full shadow-lg shadow-accent-start/20 hover:shadow-accent-start/30"
              >
                Get Started Now
              </GradientButton>
              
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => handleMenuClick(soundsSectionRef)}
                className="w-full sm:w-auto px-8 py-3 bg-white/10 hover:bg-white/15 border border-white/20 hover:border-white/30 rounded-full text-text-primary font-medium backdrop-blur-sm transition-all duration-300 mt-3 sm:mt-0"
              >
                Learn More
              </motion.button>
            </div>
          </div>

          {/* Hero Image Section */}
          <motion.div 
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="mt-16 sm:mt-24 relative mx-auto max-w-5xl"
          >
            {/* Background glow removed */}
            <div className="relative">
              <div
                className="relative z-10 rounded-2xl overflow-hidden shadow-2xl shadow-black/50 border border-white/10"
              >
                <img 
                  src="https://store.soundware.io/wp-content/uploads/2025/02/Screenshot-2025-02-19-at-10.36.34 PM-1.png" 
                  alt="Soundbank Interface" 
                  className="w-full h-auto"
                />
                {/* Gradient overlay to match the design */}
                <div className="absolute inset-0 bg-gradient-to-t from-bg-primary/40 to-transparent pointer-events-none" />
              </div>
              {/* Decorative elements removed */}
            </div>
          </motion.div>
        </motion.div>
      </div>

      {/* Video Showcase Section */}
      <section className="relative py-24 sm:py-32 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-bg-primary" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-accent-start/5 to-transparent" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-6 sm:px-8 lg:px-8">
          <motion.div
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl sm:text-5xl font-bold text-text-primary">
              See{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                SoundBank In Action
              </span>
            </h2>
            <p className="mt-6 text-lg text-text-secondary/80 max-w-2xl mx-auto">
              Watch how our powerful VST plugin transforms your music production workflow
            </p>
          </motion.div>
          
          <motion.div
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="relative z-10 max-w-4xl mx-auto"
          >
            <div className="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden border border-white/10 shadow-xl">
              <iframe 
                src="https://www.youtube.com/embed/A2LBDlXm4To" 
                title="SoundBank VST Plugin Demo"
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
                className="w-full h-full"
                style={{ minHeight: '550px' }}
              ></iframe>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Sound Browser section */}
      <section ref={soundsSectionRef} className="relative py-24 sm:py-32 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-bg-primary" />
          <div className="absolute inset-0 bg-gradient-to-b from-accent-start/5 to-transparent" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-6 sm:px-8 lg:px-8">
          
          <motion.div
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="md:flex items-center justify-between"
          >
            <div className="md:w-1/2 mb-12 md:mb-0 md:pr-12">
              <h2 className="text-4xl sm:text-5xl font-bold text-text-primary">
                Browse Thousands of{' '}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                  Professional Sounds
                </span>
              </h2>
              <p className="mt-6 text-lg text-text-secondary/80">
                From drum kits to vocal samples, find the perfect sound for your next project.
                Easily search, preview, and download high-quality audio from our vast library.
              </p>
              
              <ul className="mt-8 space-y-4">
                {[
                  "Royalty-free commercial usage",
                  "Curated collections by top producers",
                  "New content added daily",
                  "Advanced search filters"
                ].map((item, index) => (
                  <motion.li 
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-center"
                  >
                    <div className="flex-shrink-0 h-6 w-6 rounded-full bg-gradient-to-r from-accent-start to-accent-end flex items-center justify-center">
                      <svg className="h-4 w-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <span className="ml-3 text-text-secondary">{item}</span>
                  </motion.li>
                ))}
              </ul>
              
              <div className="mt-10">
                <GradientButton 
                  onClick={() => scrollToSection(pricingSectionRef)}
                  className="text-white rounded-full shadow-lg shadow-accent-start/20"
                >
                  Explore Sound Library
                </GradientButton>
              </div>
            </div>
            
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="md:w-1/2 relative"
            >
              <div className="relative">
                {/* Background glow removed */}
                
                <div className="relative z-10 bg-bg-secondary/40 backdrop-blur-md rounded-2xl overflow-hidden border border-white/10 shadow-xl">
                  <img 
                    src="https://store.soundware.io/wp-content/uploads/2025/01/Untitled-design-7-min.png" 
                    alt="Sound Browser Interface" 
                    className="w-full h-auto"
                  />
                  {/* Gradient overlay for better readability */}
                  <div className="absolute inset-0 bg-gradient-to-t from-bg-primary/30 to-transparent" />
                </div>
              </div>
              
              {/* Decorative circles removed */}
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Features section */}
      <section ref={creatorSectionRef} className="py-24 sm:py-32">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-8">
          <motion.div
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl sm:text-5xl font-bold text-text-primary">
              Built for{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                Music Creators
              </span>
            </h2>
            <p className="mt-6 text-lg text-text-secondary/80 max-w-2xl mx-auto">
              Advanced tools designed by musicians, for musicians. Streamline your workflow
              and unleash your creativity with our intuitive platform.
            </p>
          </motion.div>

          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                whileHover="hover"
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-bg-secondary/30 backdrop-blur-md border border-white/10 rounded-2xl p-6 flex flex-col"
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold text-text-primary mb-2">{feature.title}</h3>
                <p className="text-text-secondary/80 flex-grow">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      {/* Partner carousel */}
      <PartnerCarousel />

      {/* Social Media VST Section */}
      <section className="relative py-24 sm:py-32 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/5 to-transparent" />
        </div>
        <div className="relative max-w-7xl mx-auto px-6 sm:px-8 lg:px-8">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={sectionVariants}
            className="text-center mb-16 md:mb-32"
          >
            <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
              The World's First
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                Social Media VST
              </span>
            </h2>
            <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto">
              Your global music community at the click of a plugin
            </p>
          </motion.div>

          <div className="grid lg:grid-cols-2 gap-12 sm:gap-16 items-center mb-20 sm:mb-24">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="relative group"
            >
              <div className="absolute inset-0" />
              <img
                src="https://store.soundware.io/wp-content/uploads/2025/02/Screenshot-2025-02-19-at-11.10.16 PM-1.png"
                alt="SoundBank Plugin Interface"
                className="relative rounded-xl w-full shadow-lg"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="space-y-8"
            >
              {[
                {
                  icon: "🎧",
                  title: "Work and Connect",
                  description: "Connect with other producers and artists while you work in your DAW. No more switching between apps."
                },
                {
                  icon: "🔍",
                  title: "Find Sounds Instantly",
                  description: "Search through millions of sounds from inside your production environment and drag them directly to your project."
                },
                {
                  icon: "💰",
                  title: "Monetize Your Sounds",
                  description: "Sell your sounds directly to other producers who can purchase and download them without leaving their DAW."
                },
                {
                  icon: "🌐",
                  title: "Global Community",
                  description: "Join a worldwide network of producers, sound designers, and artists sharing and collaborating in real-time."
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  whileInView="visible"
                  whileHover="hover"
                  viewport={{ once: true }}
                  variants={cardVariants}
                  transition={{ delay: index * 0.2 }}
                  className="p-6 rounded-xl backdrop-blur-sm bg-bg-secondary/5 border border-white/10 hover:border-accent-start/30 transition-colors"
                >
                  <div className="flex items-start space-x-4">
                    <div className="text-3xl">{feature.icon}</div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                        {feature.title}
                      </h3>
                      <p className="text-text-secondary text-lg sm:text-xl font-light">{feature.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section ref={pricingSectionRef} className="py-24 sm:py-32 px-2 sm:px-4">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-8">
          <motion.div
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl sm:text-5xl font-bold text-text-primary">
              Simple,{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                Transparent
              </span>{' '}
              Pricing
            </h2>
            <p className="mt-6 text-base sm:text-lg text-text-secondary/80 max-w-2xl mx-auto px-2">
              Choose the plan that works best for you. All plans include access to our VST plugin for all major DAWs,
              with no hidden fees.
            </p>
          </motion.div>

          <div className="pricing-grid mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 px-0 sm:px-4">
            {plans.map((plan, index) => (
              <PricingCard 
                key={index}
                plan={plan}
                isPopular={index === 1}
                onSelect={handlePlanSelect}
              />
            ))}
          </div>
          
        </div>
      </section>

      {/* System Requirements - Enhanced styling */}
      <section className="relative py-24 sm:py-32">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/10 to-transparent" />
        </div>
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={sectionVariants}
            className="text-center mb-16"
          >
            <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
              System{' '}
                Requirements
            </h2>
            <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto">
              Everything you need to get started
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {/* Windows Requirements */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={cardVariants}
              className="p-6"
            >
              <div className="flex items-center space-x-3 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-text-primary" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801"/>
                </svg>
                <h3 className="text-2xl font-bold text-text-primary">Windows</h3>
              </div>
              <ul className="space-y-3">
                {[
                  'VST or VST3 host software',
                  'Pentium class 2.0 GHz processor with SSE3 support',
                  '8GB of RAM (16GB or more highly recommended)',
                  'Display with 1024-by-768 or higher resolution',
                  'Windows 8, Windows 10, and Windows 11',
                  'Internet connection',
                  '64-bit only'
                ].map((req, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-start space-x-3"
                  >
                    <span className="text-accent-start mt-1">•</span>
                    <span className="text-text-secondary text-lg sm:text-xl font-light">{req}</span>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            {/* macOS Requirements */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={cardVariants}
              className="p-6"
            >
              <div className="flex items-center space-x-3 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-text-primary" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
                </svg>
                <h3 className="text-2xl font-bold text-text-primary">macOS</h3>
              </div>
              <ul className="space-y-3">
                {[
                  'AudioUnit, VST or VST3 host software',
                  'Apple Silicon M1 (native) or Intel 2.0 GHz processor',
                  '8GB of RAM (16GB or more highly recommended)',
                  'Display with 1024-by-768 or higher resolution',
                  'macOS 10.13 and later including macOS Ventura',
                  'Internet connection',
                  '64-bit only'
                ].map((req, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-start space-x-3"
                  >
                    <span className="text-accent-start mt-1">•</span>
                    <span className="text-text-secondary text-lg sm:text-xl font-light">{req}</span>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          </div>
          <div className='h-12'/>
          {/* DAW Compatibility Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h3 className="text-3xl font-bold mb-8 text-text-primary">Compatible with all major DAWs</h3>
            <div className="p-6">
              <img
                src="https://octane.soundware.io/wp-content/uploads/sites/3/2023/08/Daw-Icons-with-bitwig.png.webp"
                alt="DAW Compatibility"
                className="max-w-full h-auto mx-auto rounded-xl shadow-lg"
              />
            </div>
          </motion.div>
        </div>
      </section>
      
      {/* Footer Section */}
      <footer className="py-16 sm:py-24 border-t border-white/10 relative">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-8">
          <div className="flex justify-center">
            <div className="text-center">
              <Logo className="h-8 w-auto mb-4 mx-auto" />
              <p className="text-sm text-text-secondary/60 max-w-md">
                © {new Date().getFullYear()} Soundbank. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
      
      {/* Mobile Sticky Buy Now Button */}
      <motion.div 
        className="fixed bottom-0 left-0 right-0 z-50 p-2 bg-bg-primary bg-opacity-80 backdrop-blur-md border-t border-white/10 md:hidden"
        initial={{ y: 100, opacity: 0 }}
        animate={{ 
          y: showStickyButton ? 0 : 100,
          opacity: showStickyButton ? 1 : 0
        }}
        transition={{ duration: 0.3 }}
      >
        <GradientButton 
          onClick={() => scrollToSection(pricingSectionRef)}
          className="w-full py-2 text-white rounded-full shadow-lg shadow-accent-start/20 text-base font-semibold"
        >
          Buy Now
        </GradientButton>
      </motion.div>
    </div>
  );
};

export default Landing2Page;
