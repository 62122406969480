import React, { useState, useEffect, useMemo, useRef } from 'react';
import { CustomMultiSelect } from '../../components/CustomMultiSelect';
import { fetchGenres, fetchInstruments, fetchSoundTypes } from '../../api/APIManager';
import { ChevronDown, ChevronUp, X } from 'lucide-react';
import { Analytics } from '../../api/Analytics/AnalyticsService';
import Button from '../../components/Button';

const VALID_KEYS = [
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
].map((key, index) => ({ id: index.toString(), name: key, type: 'key' }));

const SORT_OPTIONS = [
  { id: 'recent', name: 'Most Recent' },
  { id: 'popular', name: 'Most Popular' }
];

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
);

const PillRow = ({ items, selectedItems, onToggle, type, isSingleSelect = false }) => {
  const scrollRef = useRef(null);

  const isSelected = (item) => {
    return selectedItems?.some(selected => selected.id === item.id);
  };

  const isAllSelected = !selectedItems || selectedItems.length === 0;

  const getAllPillName = (type) => {
    if (type === 'key') return 'All Keys';
    if (type === 'soundType') return 'All Sound Types';
    return `All ${type.charAt(0).toUpperCase() + type.slice(1)}`;
  };

  const allPill = {
    id: 'all',
    name: getAllPillName(type),
    type
  };

  const sortedItems = useMemo(() => {
    if (type === 'soundType') {
      return items;
    }
    return [...items].sort((a, b) => {
      const aSelected = isSelected(a);
      const bSelected = isSelected(b);
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return (b.sound_count || 0) - (a.sound_count || 0);
    });
  }, [items, selectedItems, type]);

  const handlePillClick = (item) => {
    if (isSingleSelect) {
      if (isSelected(item)) {
        onToggle(allPill);
      } else {
        onToggle(item);
      }
    } else {
      onToggle(item);
    }
  };

  return (
    <div className="relative w-full">
      <div 
        ref={scrollRef}
        className="overflow-x-auto scrollable-content"
        style={{ 
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none'
        }}
      >
        <div className="inline-flex gap-2 min-w-full py-1 pr-48">
          <button
            onClick={() => onToggle(allPill)}
            className={`shrink-0 px-4 py-[6px] rounded-full text-xs transition-colors duration-200 whitespace-nowrap select-none ${
              isAllSelected
                ? 'bg-accent-end/10 border border-accent-end/50'
                : 'border border-text-primary/15 text-text-primary hover:bg-accent-end/10 hover:border-accent-end/50'
            }`}
          >
            {allPill.name}
          </button>

          {sortedItems.map(item => (
            <button
              key={item.id}
              onClick={() => handlePillClick(item)}
              className={`shrink-0 px-4 py-[6px] rounded-full text-xs transition-colors duration-200 whitespace-nowrap select-none flex items-center gap-2 ${
                isSelected(item)
                  ? 'bg-accent-end/10 border border-accent-end/50'
                  : 'border border-text-primary/15 text-text-primary hover:bg-accent-end/10 hover:border-accent-end/50'
              }`}
            >
              {item.name}
              {isSelected(item) && (
                <div className="w-4 h-4 rounded-full bg-accent-end/50 flex items-center justify-center">
                  <X className="w-3 h-3 text-white" />
                </div>
              )}
            </button>
          ))}
        </div>
      </div>
      {/* Fade-out gradient removed as requested */}
    </div>
  );
};

export const SearchRow = ({ onSearch, initialFilters, title }) => {
  const [filters, setFilters] = useState(initialFilters || {
    key: [],
    instruments: [],
    genres: [],
    soundType: [],
    searchText: '',
    sortBy: [{ id: 'recent', name: 'Most Recent' }]
  });

  const [availableGenres, setAvailableGenres] = useState([]);
  const [availableInstruments, setAvailableInstruments] = useState([]);
  const [availableSoundTypes, setAvailableSoundTypes] = useState([]);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  const getActiveFilterCount = () => {
    return (
      (filters?.instruments?.length || 0) +
      (filters?.genres?.length || 0) +
      (filters?.key?.length ? 1 : 0) +
      (filters?.soundType?.length ? 1 : 0)
    );
  };

  useEffect(() => {
    setFilters(prev => ({
      ...initialFilters,
      sortBy: [initialFilters?.sort_by_likes ? 
        { id: 'popular', name: 'Most Popular' } : 
        { id: 'recent', name: 'Most Recent' }
      ]
    }));

    const loadData = async () => {
      const [fetchedGenres, fetchedInstruments, fetchedSoundTypes] = await Promise.all([
        fetchGenres(true),
        fetchInstruments(true),
        fetchSoundTypes()
      ]);
      setAvailableGenres(fetchedGenres.map(g => ({ ...g, type: 'genres' })));
      setAvailableInstruments(fetchedInstruments.map(i => ({ ...i, type: 'instruments' })));
      setAvailableSoundTypes(fetchedSoundTypes.map(st => ({ ...st, type: 'soundType' })));
    };
    loadData();
  }, [initialFilters]);

  const handleToggleItem = (item) => {
    const type = item.type;
    setFilters(prevFilters => {
      if (item.id === 'all') {
        const newFilters = {
          ...prevFilters,
          [type]: []
        };
        Analytics.trackSearch(newFilters);
        onSearch(newFilters);
        return newFilters;
      }

      const currentSelection = prevFilters[type] || [];
      const isSelected = currentSelection.some(selected => selected.id === item.id);
      
      let newSelection;
      if (['key', 'soundType'].includes(type)) {
        // Single select for key and soundType
        newSelection = isSelected ? [] : [item];
      } else {
        // Multi select for others
        newSelection = isSelected 
          ? currentSelection.filter(selected => selected.id !== item.id)
          : [...currentSelection, item];
      }

      const newFilters = {
        ...prevFilters,
        [type]: newSelection
      };
      Analytics.trackSearch(newFilters);
      onSearch(newFilters);
      return newFilters;
    });
  };

  const handleSearch = () => {
    Analytics.trackSearch(filters);
    onSearch(filters);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClearAll = () => {
    const clearedFilters = {
      key: [],
      instruments: [],
      genres: [],
      soundType: [],
      searchText: '',
      sortBy: [{ id: 'recent', name: 'Most Recent' }]
    };
    setFilters(clearedFilters);
    onSearch(clearedFilters);
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .scrollable-content::-webkit-scrollbar {
        display: none;
      }
      .scrollable-content {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      @keyframes fadeIn {
        from {
          opacity: 0;
          transform: translateY(10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .fade-in {
        animation: fadeIn 0.3s ease-out forwards;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  return (
    <div className="w-full bg-transparent rounded-lg space-y-4 fade-in">
      <div className="hidden sm:block space-y-4">
        <PillRow 
          items={availableGenres}
          selectedItems={filters.genres}
          onToggle={handleToggleItem}
          type="genres"
          isSingleSelect={false}
        />

        <PillRow 
          items={availableInstruments}
          selectedItems={filters.instruments}
          onToggle={handleToggleItem}
          type="instruments"
          isSingleSelect={false}
        />

        <PillRow 
          items={VALID_KEYS}
          selectedItems={filters.key}
          onToggle={handleToggleItem}
          type="key"
          isSingleSelect={true}
        />

        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div className="w-40">
              <CustomMultiSelect
                options={availableSoundTypes}
                value={filters.soundType}
                onChange={(selected) => {
                  setFilters(prev => ({ ...prev, soundType: selected }));
                  onSearch({ ...filters, soundType: selected });
                }}
                placeholder="All Types"
                isMulti={false}
                hideSearch={true}
                canClear={true}
                showBorder={false}
              />
            </div>
            <div className="w-40">
              <CustomMultiSelect
                options={SORT_OPTIONS}
                value={filters.sortBy}
                onChange={(selected) => {
                  setFilters(prev => ({ ...prev, sortBy: selected }));
                  onSearch({ ...filters, sortBy: selected });
                }}
                placeholder="Sort By"
                isMulti={false}
                hideSearch={true}
                canClear={false}
                showBorder={false}
              />
            </div>
          </div>

          <div className="flex items-center gap-3">
            <div className="relative">
              <input
                type="text"
                placeholder={`Filter${title ? ` ${title.toLowerCase()}` : ''}...`}
                className="bg-transparent border border-text-primary/15 rounded-full h-7 pl-8 pr-3 text-xs text-text-primary placeholder-text-primary/70 focus:outline-none focus:ring-1 focus:ring-accent-end/50 hover:bg-accent-end/10 hover:border-accent-end/50 transition-colors duration-200"
                onChange={(e) => {
                  const newValue = e.target.value;
                  setFilters(prev => ({ ...prev, searchText: newValue }));
                }}
                onKeyPress={handleKeyPress}
                value={filters.searchText}
              />
              <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2">
                <SearchIcon />
              </div>
            </div>

            <Button
              variant="secondary"
              onClick={handleClearAll}
              className="px-4 py-[6px] rounded-full text-xs hover:bg-accent-end/10 hover:border-accent-end/50 transition-colors duration-200"
            >
              Clear All
            </Button>
          </div>
        </div>
      </div>

      <div className="sm:hidden space-y-3">
        <div className="flex gap-2">
          <Button
            variant="secondary"
            onClick={() => setIsFilterExpanded(!isFilterExpanded)}
            className="w-1/2 flex items-center justify-between px-4 py-2 bg-transparent border-none"
          >
            <span className="text-xs font-medium">
              Filters {getActiveFilterCount() > 0 && `(${getActiveFilterCount()})`}
            </span>
            {isFilterExpanded ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
          </Button>

          <div className="w-1/2">
            <CustomMultiSelect
              options={SORT_OPTIONS}
              value={filters.sortBy}
              onChange={(selected) => {
                setFilters(prev => ({ ...prev, sortBy: selected }));
                onSearch({ ...filters, sortBy: selected });
              }}
              placeholder="Sort By"
              isMulti={false}
              hideSearch={true}
              canClear={false}
              showBorder={false}
            />
          </div>
        </div>

        {isFilterExpanded && (
          <div className="space-y-4">
            <PillRow 
              items={availableGenres}
              selectedItems={filters.genres}
              onToggle={handleToggleItem}
              type="genres"
              isSingleSelect={false}
            />

            <PillRow 
              items={availableInstruments}
              selectedItems={filters.instruments}
              onToggle={handleToggleItem}
              type="instruments"
              isSingleSelect={false}
            />

            <PillRow 
              items={VALID_KEYS}
              selectedItems={filters.key}
              onToggle={handleToggleItem}
              type="key"
              isSingleSelect={true}
            />

            <div className="flex gap-2">
              <div className="w-full">
                <CustomMultiSelect
                  options={availableSoundTypes}
                  value={filters.soundType}
                  onChange={(selected) => {
                    setFilters(prev => ({ ...prev, soundType: selected }));
                    onSearch({ ...filters, soundType: selected });
                  }}
                  placeholder="Sound Type"
                  isMulti={false}
                  hideSearch={true}
                  canClear={true}
                  showBorder={false}
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex gap-2">
          <Button
            variant="secondary"
            onClick={handleClearAll}
            className="flex-1 px-4 py-[6px] rounded-full text-xs hover:bg-accent-end/10 hover:border-accent-end/50 transition-colors duration-200"
          >
            Clear All
          </Button>
          <Button
            variant="primary"
            onClick={handleSearch}
            className="flex-1"
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchRow;