import React, { useState, useEffect } from 'react';
import { 
  fetchTags, 
  fetchInstruments, 
  fetchGenres,
  createTag,
  createInstrument,
  createGenre,
  updateTag,
  updateInstrument,
  updateGenre,
  deleteTag,
  deleteInstrument,
  deleteGenre
} from '../../api/APIManager';
import { Plus, X, Edit2, Save, Trash2 } from 'lucide-react';
import Button from '../../components/Button';

const AlertDialog = ({ isOpen, onClose, onConfirm, title, description }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className=" border border-white/10 rounded-lg p-6 max-w-md w-full mx-4">
          <h3 className="text-lg font-medium text-white mb-2">{title}</h3>
          <p className="text-white/70 mb-6">{description}</p>
          <div className="flex justify-end space-x-2">
            <button
              onClick={onClose}
              className="px-4 py-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-white transition-colors"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

const MetadataManager = ({ 
  title,
  items,
  onCreateItem,
  onUpdateItem,
  onDeleteItem,
  itemDisplayKey = 'name',
  createLabel = 'Create New'
}) => {
  const [editingId, setEditingId] = useState(null);
  const [newItemName, setNewItemName] = useState('');
  const [editingName, setEditingName] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  const handleCreate = async (e) => {
    e.preventDefault();
    if (!newItemName.trim()) return;
    
    await onCreateItem({ name: newItemName.trim() });
    setNewItemName('');
    setShowCreateForm(false);
  };

  const handleUpdate = async (id) => {
    if (!editingName.trim()) return;
    await onUpdateItem(id, { name: editingName.trim() });
    setEditingId(null);
    setEditingName('');
  };

  const startEdit = (item) => {
    setEditingId(item.id);
    setEditingName(item[itemDisplayKey]);
  };

  const handleDelete = async () => {
    if (deleteItem) {
      await onDeleteItem(deleteItem.id);
      setDeleteItem(null);
    }
  };

  return (
    <div className="p-6 rounded-lg space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-text-primary">{title}</h2>
        <Button
          onClick={() => setShowCreateForm(true)}
          className="flex items-center space-x-2 px-4 py-2"
          variant="secondary"
        >
          <Plus size={16} />
          <span>{createLabel}</span>
        </Button>
      </div>

      {showCreateForm && (
        <form onSubmit={handleCreate} className="flex items-center space-x-2 p-4 bg-white/5 rounded-lg">
          <input
            type="text"
            value={newItemName}
            onChange={(e) => setNewItemName(e.target.value)}
            placeholder="Enter name..."
            className="flex-1 bg-white/10 text-white border-0 rounded-lg px-4 py-2 focus:ring-2 focus:ring-accent-end"
          />
          <button
            type="submit"
            className="p-2 bg-accent-end hover:opacity-90 text-white rounded-lg"
          >
            <Save size={16} />
          </button>
          <button
            type="button"
            onClick={() => setShowCreateForm(false)}
            className="p-2 bg-white/10 hover:bg-white/20 text-white rounded-lg"
          >
            <X size={16} />
          </button>
        </form>
      )}

      <div className="flex flex-wrap gap-2">
        {items.map(item => (
          <div
            key={item.id}
            className="flex items-center space-x-2 bg-white/5 rounded-lg p-2"
          >
            {editingId === item.id ? (
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={editingName}
                  onChange={(e) => setEditingName(e.target.value)}
                  className="bg-white/10 text-white border-0 rounded px-2 py-1 text-sm focus:ring-2 focus:ring-accent-end"
                />
                <button
                  onClick={() => handleUpdate(item.id)}
                  className="p-1 hover:text-accent-end"
                >
                  <Save size={14} />
                </button>
                <button
                  onClick={() => setEditingId(null)}
                  className="p-1 hover:text-white/60"
                >
                  <X size={14} />
                </button>
              </div>
            ) : (
              <>
                <span className="text-sm text-white">{item[itemDisplayKey]}</span>
                <div className="flex items-center space-x-1">
                  <button
                    onClick={() => startEdit(item)}
                    className="p-1 hover:text-accent-end"
                  >
                    <Edit2 size={14} />
                  </button>
                  <button
                    onClick={() => setDeleteItem(item)}
                    className="p-1 hover:text-red-500"
                  >
                    <Trash2 size={14} />
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      <AlertDialog
        isOpen={!!deleteItem}
        onClose={() => setDeleteItem(null)}
        onConfirm={handleDelete}
        title="Are you sure?"
        description={`This will permanently delete "${deleteItem?.name}". This action cannot be undone.`}
      />
    </div>
  );
};

const MetadataPage = ({ 
  title,
  fetchItems,
  createItem,
  updateItem,
  deleteItem
}) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    setIsLoading(true);
    try {
      const data = await fetchItems();
      setItems(data);
    } catch (error) {
      console.error('Error loading items:', error);
    }
    setIsLoading(false);
  };

  const handleCreate = async (itemData) => {
    try {
      const newItem = await createItem(itemData);
      if (newItem) {
        setItems([...items, newItem]);
      }
    } catch (error) {
      console.error('Error creating item:', error);
    }
  };

  const handleUpdate = async (id, itemData) => {
    try {
      const updatedItem = await updateItem(id, { id, ...itemData });
      if (updatedItem) {
        const updatedItems = items.map(item => 
          item.id === id ? { ...item, ...updatedItem } : item
        );
        setItems(updatedItems);
      }
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await deleteItem(id);
      if (result !== null) {
        setItems(items.filter(item => item.id !== id));
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-accent-end"></div>
      </div>
    );
  }

  return (
    <div className="p-4 space-y-4">
      <MetadataManager
        title={title}
        items={items}
        onCreateItem={handleCreate}
        onUpdateItem={handleUpdate}
        onDeleteItem={handleDelete}
      />
    </div>
  );
};

export const TagsManager = () => (
  <MetadataPage
    title="Manage Tags"
    fetchItems={fetchTags}
    createItem={createTag}
    updateItem={updateTag}
    deleteItem={deleteTag}
  />
);

export const InstrumentsManager = () => (
  <MetadataPage
    title="Manage Instruments"
    fetchItems={fetchInstruments}
    createItem={createInstrument}
    updateItem={updateInstrument}
    deleteItem={deleteInstrument}
  />
);

export const GenresManager = () => (
  <MetadataPage
    title="Manage Genres"
    fetchItems={fetchGenres}
    createItem={createGenre}
    updateItem={updateGenre}
    deleteItem={deleteGenre}
  />
);

