import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteSoundpackById, updateSoundpack } from '../../api/APIManager';
import { DEFAULT_SOUND_IMAGE } from '../../api/APIConfig';
import { AuthContext } from '../../context/AuthContext';
import { PlayIcon, PauseIcon } from 'lucide-react';
import { useAudioNavigation } from '../../context/AudioNavigationContext';
import { useOutletContext } from 'react-router-dom';
import { MoreVertical, Pencil, Trash2, Star } from 'lucide-react';

const SoundPackCard = ({ soundpack, onUpdate }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const { user } = useContext(AuthContext);
    const menuRef = useRef(null);
    const { 
      currentPlayingSound, 
      setCurrentPlayingSound,
      isPlaying,
      setIsPlaying 
    } = useAudioNavigation();
    const { setCurrentPlayingSound: setGlobalCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
    const navigate = useNavigate();
  
    // Close menu when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setShowMenu(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    const handlePlay = (e) => {
      e.stopPropagation(); // Prevent navigation when clicking play
      if (currentPlayingSound && currentPlayingSound.id === soundpack.id) {
        setIsPlaying(!isPlaying);
        setGlobalIsPlaying(!isPlaying);
      } else {
        setGlobalCurrentPlayingSound({
          id: soundpack.id,
          audio_preview: soundpack.audio_preview,
          name: soundpack.name,
          image: soundpack.avatar
        });
        setCurrentPlayingSound({
          id: soundpack.id,
          audio_preview: soundpack.audio_preview,
          name: soundpack.name,
          image: soundpack.avatar
        });
        setIsPlaying(true);
        setGlobalIsPlaying(true);
      }
    };
  
    const handleMenuClick = (e) => {
      e.stopPropagation();
      setShowMenu(!showMenu);
    };
  
    const handleEdit = (e) => {
      e.stopPropagation();
      navigate(`/soundpacks/${soundpack.id}/edit`);
      setShowMenu(false);
    };
  
    const handleDelete = async (e) => {
      e.stopPropagation();
      if (window.confirm('Are you sure you want to delete this sound pack?')) {
        try {
          await deleteSoundpackById(soundpack.id);
          // Trigger refresh of the soundpacks list
          if (onUpdate) onUpdate();
        } catch (error) {
          console.error('Error deleting sound pack:', error);
        }
      }
      setShowMenu(false);
    };
  
    const handleFeatureToggle = async (e) => {
      e.stopPropagation();
      setIsUpdating(true);
      try {
        const updatedData = {
          ...soundpack,
          featured: !soundpack.featured
        };
        await updateSoundpack(soundpack.id, updatedData);
        if (onUpdate) onUpdate();
      } catch (error) {
        console.error('Error updating sound pack featured status:', error);
      } finally {
        setIsUpdating(false);
        setShowMenu(false);
      }
    };
  
    const isCurrentlyPlaying = currentPlayingSound?.id === soundpack.id && isPlaying;
    const isOwner = user?.id === soundpack?.owner_id;
    const isAdmin = user?.admin;
  
    if (!soundpack) return null;
  
    return (
      <div
        onClick={() => navigate(`/soundpacks/${soundpack.id}`)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="bg-transparent rounded-xl transition-all duration-200 cursor-pointer overflow-hidden relative transform hover:-translate-y-1 hover:shadow-xl
          w-full sm:max-w-[280px] md:max-w-[300px] lg:max-w-[320px]"
      >
        <div className="p-3 sm:p-4">
          <div className="relative aspect-square mb-3 sm:mb-4 overflow-hidden rounded-lg group">
            <img
              src={soundpack.avatar || DEFAULT_SOUND_IMAGE}
              alt={soundpack.name}
              className={`w-full h-full object-cover transition-transform duration-300 ${
                isHovered ? 'scale-110' : 'scale-100'
              }`}
              onError={(e) => {
                e.target.src = DEFAULT_SOUND_IMAGE;
              }}
            />
            {soundpack.audio_preview && (
              <div
                className={`absolute inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm transition-opacity duration-300 ${
                  isHovered ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <button
                  onClick={handlePlay}
                  className="p-3 rounded-full bg-white/10 hover:bg-white/30 transition-colors duration-200 
                    transform hover:scale-105 active:scale-95"
                >
                  {isCurrentlyPlaying ? (
                    <PauseIcon className="w-6 h-6 sm:w-8 sm:h-8 text-white" />
                  ) : (
                    <PlayIcon className="w-6 h-6 sm:w-8 sm:h-8 text-white" />
                  )}
                </button>
              </div>
            )}
            {/* Featured Badge */}
            {soundpack.featured && (
              <div className="absolute top-2 left-2 bg-gradient-to-r from-accent-start to-accent-end 
                px-2 py-1 rounded-full text-xs font-medium text-white shadow-md">
                Featured
              </div>
            )}
          </div>
          <div className="space-y-2">
            <h3 className="font-semibold text-sm sm:text-base truncate text-text-primary">
              {soundpack.name}
            </h3>
            <div className="flex justify-between items-center">
              <div className="text-sm sm:text-base text-text-secondary font-medium
                bg-white/5 px-3 py-1.5 rounded-full flex items-center gap-1.5">
                <span className="font-semibold">{soundpack.sound_count || 0}</span>
                <span className="opacity-75">sounds</span>
              </div>
              {/* Menu Button - Show for owned soundpacks or admin */}
              {(isOwner || isAdmin) && (
                <div className="relative" ref={menuRef}>
                  <button
                    onClick={handleMenuClick}
                    className="p-1.5 rounded-full hover:bg-white/10 active:bg-white/20 
                      transition-colors duration-200"
                  >
                    <MoreVertical className="w-4 h-4 text-text-secondary" />
                  </button>
                  {/* Dropdown Menu */}
                  {showMenu && (
                    <div className="absolute bottom-full right-0 mb-1 py-1 w-36 bg-bg-primary
                      rounded-md shadow-lg z-50 border border-white/10 ">
                      {(isOwner || isAdmin) && (
                        <button
                          onClick={handleEdit}
                          className="w-full px-4 py-2 text-left text-sm hover:bg-white/10 
                            text-text-primary flex items-center gap-2 transition-colors duration-150"
                        >
                          <Pencil className="w-4 h-4" />
                          Edit
                        </button>
                      )}
                      {isAdmin && (
                        <button
                          onClick={handleFeatureToggle}
                          disabled={isUpdating}
                          className="w-full px-4 py-2 text-left text-sm hover:bg-white/10 
                            text-text-primary flex items-center gap-2 transition-colors duration-150"
                        >
                          <Star className="w-4 h-4" />
                          {soundpack.featured ? 'Unfeature' : 'Feature'}
                        </button>
                      )}
                      {(isOwner || isAdmin) && (
                        <button
                          onClick={handleDelete}
                          className="w-full px-4 py-2 text-left text-sm hover:bg-white/10 
                            text-red-500 flex items-center gap-2 transition-colors duration-150"
                        >
                          <Trash2 className="w-4 h-4" />
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default SoundPackCard;