import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { fetchUserMe, updateUser, deleteUser } from '../../api/APIManager';
import { CashBadge, TokenBadge } from '../../assets/Icons';
import RevenueChart from './RevenueChart';
import Spinner from '../../components/Spinner';
import PricingModal from '../../components/PricingSection';
import Button from '../../components/Button';
import SpinnerFull from '../../components/SpinnerFull';
import { isJUCE } from '../../context/JUCE';


const TabButton = ({ isActive, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-6 py-3 font-medium transition-all duration-200 border-b-2 ${
      isActive 
        ? 'text-accent-end border-accent-end' 
        : 'text-text-secondary border-transparent hover:text-text-primary hover:border-white/20'
    }`}
  >
    {children}
  </button>
);

const formatBytes = (bytes) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const StorageProgressBar = ({ usedBytes, totalBytes }) => {
  const percentage = (usedBytes / totalBytes) * 100;
  const isNearLimit = percentage > 80;
  const isAtLimit = percentage >= 95;

  return (
    <div className="w-full h-4 rounded-full overflow-hidden">
      <div 
        className={`h-full transition-all duration-300 ${
          isAtLimit ? 'bg-red-500' : isNearLimit ? 'bg-yellow-500' : 'bg-accent-end'
        }`}
        style={{ width: `${Math.min(100, percentage)}%` }}
      />
    </div>
  );
};

const SecurityTab = ({ user }) => {
  const [email, setEmail] = useState(user?.email || '');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteStage, setDeleteStage] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsUpdating(true);
  
    try {
      var user = await fetchUserMe();
      user.email = email;
      await updateUser(user.id, user);
      setSuccess('Email updated successfully');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update email');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleResetPassword = async () => {
    setIsResetting(true);
    try {
      window.location.href = 'https://store.soundware.io/account/lost-password/';
    } finally {
      setIsResetting(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (deleteStage === 0) {
      setDeleteStage(1);
      return;
    }
    
    if (deleteStage === 1) {
      setDeleteStage(0);
      const confirmed = window.confirm('Are you absolutely sure you want to delete your account? This action cannot be undone.');
      if (!confirmed) return;
    }
    
    try {
      await deleteUser(user.id);
      setSuccess('User deleted successfully');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to delete user');
    } finally {
      setDeleteStage(0);
    }
  };

  const getDeleteButtonText = () => {
    switch (deleteStage) {
      case 1:
        return 'Click again to confirm';
      case 1:
        return 'Click to confirm deletion';
      default:
        return 'Delete Account';
    }
  };

  const getDeleteButtonStyle = () => {
    return ` px-4 transition-colors duration-200 
            ${deleteStage > 0
              ? 'bg-red-600 hover:bg-red-700'
              : 'bg-red-500/10 hover:bg-red-500/20'
            } text-red-500`;
  };

  return (
    <div className="rounded-lg p-6">
      <h2 className="text-xl font-bold mb-6">Security Settings</h2>

      {/* Email Section */}
      <div className="rounded-lg p-6 mb-6">
        <h3 className="text-lg font-medium mb-4">Email Address</h3>
        
        {error && (
          <div className="mb-4 p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-500">
            {error}
          </div>
        )}
        
        {success && (
          <div className="mb-4 p-4 bg-green-500/10 border border-green-500/20 rounded-lg text-green-500">
            {success}
          </div>
        )}

        <form onSubmit={handleEmailSubmit} className="space-y-4">
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder={user?.email}
            defaultValue={user?.email}
            className="w-full px-4 h-12 bg-bg-primary rounded-md border-2 border-white/20 
                     text-text-primary focus:border-accent-end focus:ring focus:ring-accent-end 
                     focus:ring-opacity-50 transition-all duration-300"
          />

<Button variant="secondary" isLoading={isUpdating} type="submit">
  Update Email
</Button>
        </form>
      </div>

      {/* Password Reset Section */}
      <div className="rounded-lg p-6 mb-6">
        <h3 className="text-lg font-medium mb-4">Password</h3>
        <Button variant="secondary" isLoading={isResetting} onClick={handleResetPassword}>
  Update Password
</Button>
      </div>

     {/* Delete Account Section */}
      <div className="rounded-lg p-6">
        <h3 className="text-lg font-medium mb-4">Delete Account</h3>
        <div className="space-y-4">
          <p className="text-text-secondary">
            Warning: This action cannot be undone. All your data will be permanently deleted.
          </p>
          <Button
            variant="secondary"
            onClick={handleDeleteAccount}
            className={getDeleteButtonStyle()}
          >
            {getDeleteButtonText()}
          </Button>
        </div>
      </div>
    </div>
  );
};

const AccountPage = () => {
  const [activeTab, setActiveTab] = useState('balances');
  const { user } = useContext(AuthContext);
  const [accountData, setAccountData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState('all');
  const [revenueData, setRevenueData] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [isPricingOpen, setIsPricingOpen] = useState(false);

  useEffect(() => {
    const loadAccountData = async () => {
      setIsLoading(true);
      try {
        const userData = await fetchUserMe();
        const cashBalance = (Number(String(userData.usd_balance || 0).replace(/,/g, '')) / 100)
          .toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        
        setAccountData({
          credits: userData.credits,
          cashBalance,
          activeSubscriptions: userData.active_subscriptions || [],
          storage: userData.storage || {
            used_storage_bytes: 0,
            total_storage_bytes: 0,
            remaining_storage_bytes: 0
          }
        });

        const fakeRevenueData = Array.from({ length: 7 }, (_, i) => ({
          month: new Date(2024, i, 1).toLocaleString('default', { month: 'short' }),
          revenue: Math.floor(Math.random() * 5000)
        }));
        setRevenueData(fakeRevenueData);
        setTotalRevenue(fakeRevenueData.reduce((sum, item) => sum + item.revenue, 0));
      } catch (error) {
        console.error('Error fetching account data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadAccountData();
  }, []);

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
  };

  const handleManageSubscription = () => {
    let wordpressToken = localStorage.getItem('wordpress_token');
    let subscriptionLink = 'https://store.soundware.io/?rest_route=/simple-jwt-login/v1/autologin&JWT=' + 
                          wordpressToken + '&redirectUrl=https://store.soundware.io/account/subscriptions/';
    window.location.href = subscriptionLink;
  };

  if (isLoading) {
    return <div className="flex justify-center items-center min-h-[calc(100vh-64px)]"><Spinner /></div>;
  }

  const BalancesTab = () => {
    const [isAgeModalOpen, setIsAgeModalOpen] = useState(false);
    const [isNavigating, setIsNavigating] = useState(false);
  
    const handleAgeConfirm = () => {
      setIsAgeModalOpen(false);
      setIsNavigating(true);
      let wordpressToken = localStorage.getItem('wordpress_token');
      let cashOutLink = 'https://store.soundware.io/?rest_route=/simple-jwt-login/v1/autologin&JWT=' + 
                        wordpressToken + '&redirectUrl=https://store.soundware.io/payout/';
      window.location.href = cashOutLink;
    };
  
    return (
      <>
        <div className="rounded-lg p-6">
          <h2 className="text-xl font-bold mb-6">Account Balances</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
            <div className="rounded-lg p-4">
              <p className="text-sm text-text-secondary">Tokens</p>
              <p className="text-2xl font-bold flex items-center mt-2">
                {accountData.credits}
                <TokenBadge className="ml-2" />
              </p>
            </div>
            <div className="rounded-lg p-4">
              <p className="text-sm text-text-secondary">Cash</p>
              <p className="text-2xl font-bold flex items-center mt-2">
                {accountData.cashBalance}
                <CashBadge className="ml-2" />
              </p>
              <p className="text-sm text-text-secondary mt-2">
                Note: You must be at least 18 years of age to withdraw your funds.
              </p>
            </div>
          </div>
          <Button variant="secondary" onClick={() => setIsAgeModalOpen(true)}>
            Cash Out
          </Button>
  
          {/* Age Verification Modal */}
          {isAgeModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="rounded-lg p-6 max-w-md w-full mx-4">
                <h3 className="text-xl font-bold mb-4">Age Verification Required</h3>
                <p className="text-text-secondary mb-6">
                By selecting "I am 18 or older," you certify that you are at least 18 years of age at the time of cash out.
                </p>
                <div className="flex space-x-4">
                  <Button variant="primary" onClick={handleAgeConfirm}>
                    I'm 18 or older
                  </Button>
                  <Button variant="secondary" onClick={() => setIsAgeModalOpen(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        
        {/* Add spinner overlay */}
        {isNavigating && (
          <SpinnerFull 
            message="Redirecting to cashout..." 
            useBankAnimation={true} 
          />
        )}
      </>
    );
  };

  const StorageTab = () => {
    const { used_storage_bytes, total_storage_bytes, remaining_storage_bytes } = accountData.storage;
    const usedPercentage = ((used_storage_bytes / total_storage_bytes) * 100).toFixed(1);
    
    return (
      <div className="rounded-lg p-6">
        <h2 className="text-xl font-bold mb-6">Storage Usage</h2>
        
        <div className="rounded-lg p-6 mb-6">
          <div className="mb-4">
            <div className="flex justify-between mb-2">
              <span className="text-text-secondary">Used Space</span>
              <span className="font-medium">{formatBytes(used_storage_bytes)}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="text-text-secondary">Free Space</span>
              <span className="font-medium">{formatBytes(remaining_storage_bytes)}</span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="text-text-secondary">Total Space</span>
              <span className="font-medium">{formatBytes(total_storage_bytes)}</span>
            </div>
            
            <StorageProgressBar 
              usedBytes={used_storage_bytes}
              totalBytes={total_storage_bytes}
            />
            
            <div className="text-sm text-text-secondary mt-2 text-center">
              {usedPercentage}% used
            </div>
          </div>
        </div>

        {usedPercentage > 80 && (
  <Button
    variant="premium"
    onClick={() => setIsPricingOpen(true)}
  >
    Upgrade Storage
  </Button>
)}
      </div>
    );
  };

  const AnalyticsTab = () => (
    <div className="rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-4">
          {/* <select
            value={dateFilter}
            onChange={handleDateFilterChange}
            className="text-text-primary rounded px-3 py-2"
          >
            <option value="all">All time</option>
            <option value="year">This year</option>
            <option value="month">This month</option>
            <option value="week">This week</option>
          </select> */}
        </div>
        {/* <div className="text-right">
          <p className="text-sm text-text-secondary">Total Revenue</p>
          <p className="text-xl font-bold">${totalRevenue.toFixed(2)}</p>
        </div> */}
      </div>
      <div className="rounded-lg p-4">
        <RevenueChart data={revenueData} />
      </div>
    </div>
  );

  const SubscriptionsTab = () => {
    const [isNavigating, setIsNavigating] = useState(false);
  
    const handleManageSubscription = () => {
      setIsNavigating(true);
      let wordpressToken = localStorage.getItem('wordpress_token');
      let subscriptionLink = 'https://store.soundware.io/?rest_route=/simple-jwt-login/v1/autologin&JWT=' + 
                            wordpressToken + '&redirectUrl=https://store.soundware.io/account/subscriptions/';
      window.location.href = subscriptionLink;
    };

    return (
      <>
        <div className="rounded-lg p-6">
          <h2 className="text-xl font-bold mb-6">Subscription Management</h2>
          <div className="rounded-lg p-4 mb-6">
            <p className="text-sm text-text-secondary mb-2">Active Subscriptions</p>
            {accountData.activeSubscriptions && accountData.activeSubscriptions.length > 0 ? (
              <ul className="space-y-2">
                {accountData.activeSubscriptions.map((subscription, index) => (
                  <li key={index} className="text-lg font-medium">{subscription}</li>
                ))}
              </ul>
            ) : (
              <p className="text-lg font-medium">No active subscriptions</p>
            )}
          </div>
          <div className="flex space-x-4">
          <Button variant="secondary" onClick={handleManageSubscription}>
    Manage Subscription
  </Button>
  {(!accountData.activeSubscriptions || accountData.activeSubscriptions.length === 0) && (
    <Button variant="premium" onClick={() => setIsPricingOpen(true)}>
    Upgrade Now
  </Button>
  )}
          </div>
        </div>
        
        {/* Add spinner overlay */}
        {isNavigating && <SpinnerFull message="Redirecting to subscription management..." />}
      </>
    );
  };
  

  return (
    <>
      <div className="text-text-primary p-4 sm:p-6">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-2xl font-bold mb-6">Account</h1>
          
          <div className="rounded-lg mb-6">
            <div className="border-b border-white/10">
              <div className="flex flex-wrap">
                <TabButton 
                  isActive={activeTab === 'balances'} 
                  onClick={() => setActiveTab('balances')}
                >
                  Balances
                </TabButton>
                <TabButton 
                  isActive={activeTab === 'analytics'} 
                  onClick={() => setActiveTab('analytics')}
                >
                  Analytics
                </TabButton>
                <TabButton 
                  isActive={activeTab === 'storage'} 
                  onClick={() => setActiveTab('storage')}
                >
                  Storage
                </TabButton>
                <TabButton 
                  isActive={activeTab === 'subscriptions'} 
                  onClick={() => setActiveTab('subscriptions')}
                >
                  Subscriptions
                </TabButton>
                <TabButton 
  isActive={activeTab === 'security'} 
  onClick={() => setActiveTab('security')}
>
  Security
</TabButton>
              </div>
            </div>
            
            <div className="p-4">
              {activeTab === 'balances' && <BalancesTab />}
              {activeTab === 'analytics' && <AnalyticsTab />}
              {activeTab === 'storage' && <StorageTab />}
              {activeTab === 'subscriptions' && <SubscriptionsTab />}
              {activeTab === 'security' && <SecurityTab user={user} />}
            </div>
          </div>
        </div>
        
        {/* License Notice */}
        <div className="max-w-7xl mx-auto mt-6">
          <div className="rounded-lg p-6">
            <div className="text-text-secondary text-sm">
              {isJUCE() && 
              <div>
              This product uses the Rubber Band Library audio timestretcher from Breakfast Quay.
             </div>
              }
              
            </div>
          </div>
        </div>
      </div>
      
      <PricingModal 
        isOpen={isPricingOpen}
        setIsOpen={setIsPricingOpen}
      />
    </>
  );
};

export default AccountPage;