import React, { useState, useEffect, useContext } from 'react';
import { SoundCard } from './SoundCard';
import { isJUCE, JUCESend, JUCEReceive } from '../../context/JUCE';
import { getDownloadURL } from '../../api/APIManager';
import SpinnerFull from '../../components/SpinnerFull';
import { AuthContext } from '../../context/AuthContext';

const SoundListHeader = () => {
  const { user } = useContext(AuthContext);
  
  return (
    <div className="flex items-center h-10 px-2 border-b border-white/5">
      <div className="w-12 mr-3" />
      
      <div className="flex-grow grid grid-cols-4 md:grid-cols-6 gap-2">
        <div className="col-span-4 md:col-span-2">
          <span className="text-xs font-medium text-text-secondary">NAME</span>
        </div>
        
        <div className="hidden md:block">
          <span className="text-xs font-medium text-text-secondary">INSTRUMENTS</span>
        </div>
        
        <div className="hidden md:block">
          <span className="text-xs font-medium text-text-secondary">TYPE</span>
        </div>
        
        <div className="hidden md:block">
          <span className="text-xs font-medium text-text-secondary">KEY</span>
        </div>
        
        <div className="hidden md:block">
          <span className="text-xs font-medium text-text-secondary">BPM</span>
        </div>
      </div>

      <div className={`flex-shrink-0 w-48 ${user?.admin ? 'md:w-72' : 'md:w-64'}`} />
    </div>
  );
};

export const SoundList = ({ 
  sounds, 
  onLike, 
  onUnlike, 
  onPurchase, 
  onPlay, 
  onDownload, 
  currentPlayingSound, 
  isPlaying,
  isSelectable = false,
  selectedSounds = [],
  onSoundSelect
}) => {
  const [purchasingSoundId, setPurchasingSoundId] = useState(null);
  const [draggingSoundId, setDraggingSoundId] = useState(null);
  const [exportPhase, setExportPhase] = useState(null); // 'downloading' or 'processing'
  const { refreshUserData } = useContext(AuthContext);

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      if (eventName === 'dragExportDownloadComplete') {
        // When download is complete, change to processing phase
        setExportPhase('processing');
      } else if (eventName === 'dragExportComplete') {
        // When processing is complete, reset states completely
        setDraggingSoundId(null);
        setExportPhase(null);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    return () => {
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
    };
  }, []);

  const handlePurchase = async (soundId) => {
    setPurchasingSoundId(soundId);
    try {
      await onPurchase(soundId);
      await refreshUserData();
    } catch (error) {
      console.error('Error purchasing sound:', error);
    } finally {
      setPurchasingSoundId(null);
    }
  };
  
  const handleDragStart = async (e, sound) => {
    if (isJUCE()) {
      e.preventDefault();
      // Reset any previous state completely first
      setDraggingSoundId(null);
      setExportPhase(null);
      
      // Small delay to ensure states are fully reset before setting new values
      setTimeout(() => {
        setDraggingSoundId(sound.id);
        setExportPhase('downloading'); // Set initial phase to downloading
        
        getDownloadURL(sound.id).then(downloadUrl => {
          const soundWithUrl = { ...sound, download_url: downloadUrl };
          JUCESend('dragExport', soundWithUrl);
        }).catch(error => {
          console.error('Error getting download URL:', error);
          JUCESend('dragExport', sound);
          setDraggingSoundId(null);
          setExportPhase(null); // Reset phase on error
        });
      }, 50);
    }
  };

  return (
    <div className="relative animate-fade-in">
      {draggingSoundId && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <SpinnerFull message={exportPhase === 'processing' ? "Processing audio, please wait..." : "Downloading audio, please wait..."} />
        </div>
      )}
      
      <SoundListHeader />
      <div className="space-y-2">
        {sounds.map((sound) => (
          <SoundCard 
            key={sound.id}
            sound={sound} 
            onLike={onLike}
            onUnlike={onUnlike}
            onPurchase={() => handlePurchase(sound.id)}
            onPlay={onPlay}
            onDownload={onDownload}
            currentPlayingSound={currentPlayingSound}
            isPlaying={isPlaying}
            isPurchasing={purchasingSoundId === sound.id}
            onDragStart={handleDragStart}
            isProcessing={draggingSoundId === sound.id}
            isSelectable={isSelectable}
            isSelected={selectedSounds.some(s => s.id === sound.id)}
            onSelect={onSoundSelect}
          />
        ))}
      </div>
    </div>
  );
};

export default SoundList;